import {
  PF_FETCH_ALL,
  PF_FETCH_ALL_VALUES,
  PF_FETCH_ALL_SUCCESS,
  PF_FETCH_ALL_ERRROR,
  PF_FETCH_ALL_VALUES_SUCCESS,
  PF_FETCH_ALL_VALUES_ERRROR,
  PF_FETCH_ALL_WITH_VALUES,
  PF_FETCH_ALL_WITH_VALUES_SUCCESS,
} from '../constants/action-types'
import Api from '../helpers/Api'
import store from 'store2'

export const fetchAllProductFeaturesAction = () => ({
  type: PF_FETCH_ALL,
})

export const fetchAllProductFeaturesSuccessAction = (features) => ({
  type: PF_FETCH_ALL_SUCCESS,
  features,
})

export const fetchAllProductFeaturesErrorAction = (error) => ({
  type: PF_FETCH_ALL_ERRROR,
  error,
})

export const fetchAllProductFeatureValuesAction = () => ({
  type: PF_FETCH_ALL_VALUES,
})

export const fetchAllProductFeatureValuesSuccessAction = (values) => ({
  type: PF_FETCH_ALL_VALUES_SUCCESS,
  values,
})

export const fetchAllProductFeatureValuesErrorAction = (error) => ({
  type: PF_FETCH_ALL_VALUES_ERRROR,
  error,
})

export const fetchAllProductFeatures = () => (dispatch) => {
  dispatch(fetchAllProductFeaturesAction())

  const key = store.get('_key')

  return Api.get('/product_features', {
    params: {
      display: 'full',
      key,
    },
  })
  .then(({data}) => dispatch(fetchAllProductFeaturesSuccessAction(data.product_features)))
  .catch((error) => dispatch(fetchAllProductFeaturesErrorAction(error)))
}

export const fetchAllProductFeatureValues = (features = []) => (dispatch) => {
  dispatch(fetchAllProductFeatureValuesAction())

  const key = store.get('_key')
  const params = {
    display: 'full',
    key,
  }

  if (features.length) {
    params['filter[id]'] = ['[', features.join('|'), ']'].join('')
  }

  return Api.get('/product_feature_values', {
    params,
  })
  .then(({data}) => dispatch(fetchAllProductFeatureValuesSuccessAction(data.product_feature_values)))
  .catch((error) => dispatch(fetchAllProductFeatureValuesErrorAction(error)))
}

export const fetchAllFeaturesAndValues = (products = []) => (dispatch) => {
  dispatch({
    type: PF_FETCH_ALL_WITH_VALUES,
    products,
  })

  const features = []
  products
    .flatMap((product) => product.associations.product_features)
    .filter((f) => f)
    .flatMap(({id_feature_value}) => id_feature_value)
    .forEach((feature) => {
      if (!features.includes(feature)) {
        features.push(feature)
      }
    })


  return Promise.all([
    dispatch(fetchAllProductFeatures()),
    dispatch(fetchAllProductFeatureValues(features)),
  ])
  .then(() => dispatch({type: PF_FETCH_ALL_WITH_VALUES_SUCCESS}))
}
