import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CategoryImage from './Image'

import { setActive } from '../../actions/categories'
import { resetActiveForCategory } from '../../actions/products'


export default class CategoriesSlider extends Component {
  onClick(category) {
    this.props.dispatch(setActive(category.id))
    this.props.dispatch(resetActiveForCategory(category))
  }

  render() {
    const { category, categories: {menuCategories} } = this.props
    const current = menuCategories.findIndex(({ id }) => id === category.id)

    let nextIndex = current + 1
    let prevIndex = current - 1

    if (nextIndex >= menuCategories.length) {
      nextIndex = 0
    }

    if (prevIndex < 0) {
      prevIndex = menuCategories.length - 1
    }

    const empty = { link_rewrite: '' }
    let prev = empty
    let next = empty
    if (menuCategories[prevIndex]) {
      prev = menuCategories[prevIndex]
    }
    if (menuCategories[nextIndex]) {
      next = menuCategories[nextIndex]
    }

    return (
      <div className='variety-nav'>
        <div className='variety-nav-controls'>
          <Link
            to={`/carta/${prev.link_rewrite}`}
            className='variety-nav-left'
            onClick={this.onClick.bind(this, prev)}
          ></Link>
          <p>{category.name}</p>
          <Link
            to={`/carta/${next.link_rewrite}`}
            className='variety-nav-right'
            onClick={this.onClick.bind(this, next)}
          ></Link>
        </div>
        <div className='variety-nav-image'>
          <div className='variety-nav-fade'></div>
          <CategoryImage category={category} />
        </div>
      </div>
    )
  }
}
