import React, { Component } from 'react'
import { FEE_PRODUCT_ID } from '../../constants/constants'
import { removeFromCart } from '../../actions/cart'

export default class RemoveProduct extends Component {
  onClick(index) {
    const { dispatch } = this.props

    dispatch(removeFromCart(index))
  }

  render() {
    const { index, combination, products, combinations } = this.props

    if (!products.loaded || !combinations.loaded || !combination) {
      return null
    }

    if (combination.id_product === FEE_PRODUCT_ID) {
      return null
    }

    return (
      <button onClick={this.onClick.bind(this, index)}>
        &nbsp;
      </button>
    )
  }
}
