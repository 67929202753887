import React, { Component } from 'react'
import lstore from 'store2'
import { connect } from 'react-redux'
import { Animated } from 'react-animated-css'

import ModalLoadError from './helpers/Modal'
import { getUser } from './actions/userdetails-actions'
import { testLocalStorage, isUserLoggedIn } from './helpers/Utils'
import Routes from './Routes'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

class App extends Component {
  state = {
    modalLoadErrorVisible: false,
  }

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.props.dispatch(getUser(lstore.get('_userId'), lstore.get('_key')))
    } else {
      if (!testLocalStorage()) {
        this.setState(state => ({
          modalLoadErrorVisible: true
        }))
      }
    }
  }

  render() {
      return (
      <div>
        <Routes />

        <Animated
          animationIn='fadeIn'
          animationOut='fadeOut'
          animateOnMount={false}
          className='faster modal-wrapper'
          isVisible={this.state.modalLoadErrorVisible}>
        <ModalLoadError
          modalAlertTitle='NO SE HAN PODIDO CARGAR LOS DATOS'
          modalAlertContent='Por favor, ponte en contacto con nosotros por teléfono o email para recibir ayuda.<br /><br /><a href="tel://936776759">93 677 67 59</a><br /><a href="mailto:info@bcnwit.org">info@bcnwit.org</a>'
          modalType='browsercheck' />
        </Animated>
      </div>
      )
  }
}

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(null, mapDispatchToProps)(App)
