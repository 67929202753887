import { combineReducers } from 'redux'
import { reducer as ToastrReducer } from 'react-redux-toastr'

import UserDetailsReducer from './userdetails-reducer'
import OldCartReducer from './cart-reducer'
import AsambleaReducer from './asamblea-reducer'
import AssembliesReducer from './assemblies'

import CMSReducer from './cms'
import CartReducer from './cart'
import CategoriesReducer from './categories'
import CombinationsReducer from './combinations'
import CustomersReducer from './customers'
import GroupsReducer from './groups'
import OrdersReducer from './orders'
import ProductFeaturesReducer from './product-features'
import ProductsReducer from './products'
import StocksReducer from './stocks'
import APIReducer from './api'

const reducers = {
  api: APIReducer,
  assemblies: AssembliesReducer,
  AsambleaReducer: AsambleaReducer,
  categories: CategoriesReducer,
  cart: CartReducer,
  cms: CMSReducer,
  combinations: CombinationsReducer,
  customers: CustomersReducer,
  CartReducer: OldCartReducer,
  groups: GroupsReducer,
  orders: OrdersReducer,
  products: ProductsReducer,
  productFeatures: ProductFeaturesReducer,
  stocks: StocksReducer,
  toastr: ToastrReducer,
  UserDetailsReducer: UserDetailsReducer,
}

export default (history) => combineReducers(reducers)
