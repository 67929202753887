import React, { Component } from 'react'
import { formatPrice } from '../../constants/globals'


export default class DiscountRow extends Component {
  render() {
    const { discount, percent } = this.props

    if (!discount) {
      return null
    }

    return (
      <tr className='discounts'>
        <td>&nbsp;</td>
        <td>Descuento ({percent}%)</td>
        <td>{formatPrice(discount)}</td>
      </tr>
    )
  }
}
