import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ProperApi } from '../../helpers/Api'
import Text from '../Common/Forms/Text'
import WitPage from '../Common/WitPage'
import ModalWithButton from '../Common/ModalWithButton'

Yup.setLocale({
  mixed: {
    required: 'Este campo no puede quedar vacío',
  },
})

export default class Endorsement extends Component {
  state = {
    modalVisible: false,
  }

  fields = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  }

  getEndorserFields() {
    const { customer } = this.props
    return {
      id: customer.id,
      firstname: customer.firstname,
      lastname: customer.lastname,
      email: customer.email,
    }
  }

  toggleMap() {
    this.setState({modalVisible: !this.state.modalVisible})
  }

  render() {
    const baseSchema = {}

    Object.keys(this.fields).forEach((field) => {
      baseSchema[field] = Yup.string().required()
    })
    baseSchema.email = Yup.string().email('Debe ser un e-mail válido').required()

    return (
      <WitPage {...this.props}>
        <div className='page-title'>
          <p>AVALAR UN USUARIO</p>
        </div>

        <div className='styled-content endorsement-page'>
          <h4>REQUISITOS OBLIGATORIOS</h4>

          <ol>
            <li>Ser mayor de 21 años y tener plena capacidad de obrar y decidir.</li>
            <li>Ser previa consumidora de cannabis o necesitar el cannabis por motivos terapéuticos.</li>
            <li>Que una persona miembro de WIT te avale.</li>
            <li>Entender el modelo de acceso al cannabis a través de WIT como una alternativa a los mercados informales y así mejorar tu salud y calidad de vida.</li>
            <li>Conocer el contenido de los Estatutos y del Régimen Interno, compartir sus objetivos, y entender las particularidades de la asociación.</li>
            <li>Entender que pasas a formar parte de la Asamblea General y como tal eres co-responsable de las decisiones que se tomen desde el seno de la misma.</li>
            <li>Comprometerse a respetar la legalidad y jurisprudencias vigentes.</li>
            <li>Cumplimentar el curso formativo.</li>
            <li>Tener el domicilio dentro del <a href='#toggle-map' onClick={this.toggleMap.bind(this)}>área de retirada</a>.</li>
          </ol>
          <Formik
            initialValues={this.fields}
            validationSchema={Yup.object().shape(baseSchema)}
            onSubmit={(values, actions) => {

              values.endorser = this.getEndorserFields()

              ProperApi.post('/presignup', values).then((data) => {
                toastr.success('Gracias por avalar un nuevo usuario y confiar en WIT')
                this.props.history.push('/carta')
              }).catch((error) => {
                console.error(error.response)
                toastr.error('Ha habido un error procesando la petición. Revisa los datos y si sigue fallando vuelve a intentarlo más tarde.')
                if (error.response.data && error.response.data.errors && error.response.data.errors.length) {
                  error.response.data.errors.forEach((err) => toastr.error(err.message))
                }
                actions.setSubmitting(false)
              })
            }}
          >
            {({handleChange, handleSubmit, handleBlur, values, errors, isSubmitting}) =>
              <form onSubmit={handleSubmit}>
                <Text
                  name='firstname'
                  label='Nombre'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                />
                <Text
                  name='lastname'
                  label='Apellidos'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                />
                <Text
                  name='email'
                  label='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                />
                <Text
                  name='phone'
                  label='Teléfono'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                />
                <button className='btn' type='submit' disabled={isSubmitting}>
                  Avalar usuario
                </button>
              </form>
            }
          </Formik>
        </div>
        <ModalWithButton visible={this.state.modalVisible} onClick={this.toggleMap.bind(this)}>
          <iframe data-hj-allow-iframe title='map' src='https://www.google.com/maps/d/embed?mid=1v0KIvUYBs8xqZCCb7Kj95j6xKswji9WE' width='100%' height='290'></iframe>
        </ModalWithButton>
      </WitPage>
    )
  }
}
