import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import SimpleStorage, { resetParentState } from "react-simple-storage"
import { Animated } from "react-animated-css"
import store from "store2"

import { fetchUserCart } from "../../actions/cart-actions"

import "./Header.css"

import iconUser from "../../images/icon-user-s1.png"
import iconCart from "../../images/icon-cart-s1.png"
import iconMenu from "../../images/icon-menu_gold-s1.png"
import iconClose from "../../images/icon-close_gold-s1.png"
import logo from "../../images/logo-s1.svg"
import { isUserLoggedIn } from "../../helpers/Utils"

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
	     	drawerLeftVisible: false,
	     	drawerRightVisible: false,
	     	userHasLoggedOut: false,
	     	userLogged: false,
	     	userId: 0
	    }

	    this.initialState = this.state

	    this.toggleDrawerLeft = this.toggleDrawerLeft.bind(this)
	    this.toggleDrawerRight = this.toggleDrawerRight.bind(this)
	    this.logUserOut = this.logUserOut.bind(this)
	}

	componentDidMount() {
		if (!this.props.currentCart || !this.props.currentCart.id) {
			this.props.dispatch(fetchUserCart(store.get('_userId')))
		}
	}

	componentDidUpdate() {
		if (this.state.userHasLoggedOut) {
			this.props.history.push('/')
		}

		if (!isUserLoggedIn()) {
      this.logUserOut()
		}
	}

	logUserOut() {
		resetParentState(this, this.initialState, ['userHasLoggedOut'])
		this.setState(state => ({
			userHasLoggedOut: true
		}))
	}

	toggleDrawerLeft() {
		this.setState(state => ({
			drawerLeftVisible: !state.drawerLeftVisible
		}))
	}

	toggleDrawerRight() {
		this.setState(state => ({
			drawerRightVisible: !state.drawerRightVisible
		}))
	}

	renderTotalCartProducts() {
		if (this.props.cart
			&& this.props.cart.associations
			&& this.props.cart.associations.cart_rows
			&& this.props.cart.associations.cart_rows.length > 0) {
			return (
				<span>{this.props.cart.associations.cart_rows.length}</span>
			)
		} else {
			return (<div></div>)
		}
	}

	render() {
		return (
			<header>
				<SimpleStorage parent={this} blacklist={['drawerLeftVisible', 'drawerRightVisible', 'userHasLoggedOut']} />
				<nav>
					<div className="nav-wrapper">
						<div className="navlink-icons">
							<a href="#navlink-user" className="navlink-user" onClick={this.toggleDrawerLeft}>
								<img alt="user icon" src={iconUser} />
							</a>
							<Link to="/cesta" className="navlink-cart">
								<img alt="cart icon" src={iconCart} />
								{this.renderTotalCartProducts()}
							</Link>
						</div>
						<Link to="/carta" className="navlink-logo">
							<img alt="logo" src={logo} />
						</Link>
						<a href="#navlink-menu" className="navlink-menu" onClick={this.toggleDrawerRight}>
							<span>VER TODO</span>
							<div className="fold-icon-wrapper">
								<img alt="menu icon" src={iconMenu} />
							</div>
						</a>
					</div>
				</nav>

				<Animated
					animationIn="fadeInLeft"
					animationOut="fadeOutLeft"
					animateOnMount={false}
					className="faster nav-drawer"
					isVisible={this.state.drawerLeftVisible}>
					<div className="nav-user-drawer-wrapper" onClick={this.toggleDrawerLeft}>
						<div className="nav-user-drawer-bg"></div>
						<div className="nav-user-drawer">
							<a href="#drawer-usericon" className="drawer-usericon">
								<img alt="user icon" src={iconUser} />
							</a>
							<p className="drawer-username">{this.props.userDetails.firstname} {this.props.userDetails.lastname}</p>
							{/*<p className="drawer-usernumber">nº {this.props.userDetails.id}</p>*/}
							<Link to="/usuario" className="drawer-useredit">Editar</Link>
							<ul className="drawer-usermenu">
								<li><Link to="/historial">HISTORIAL</Link></li>
								<li><Link to="/avalar">AVALAR</Link></li>
								<li><Link to="/prevision">PREVISIÓN</Link></li>
								<li><a href="#logout" className="logout" onClick={this.logUserOut}>LOGOUT</a></li>
							</ul>
						</div>
					</div>
				</Animated>

				<Animated
					animationIn="fadeInRight"
					animationOut="fadeOutRight"
					animateOnMount={false}
					className="faster nav-drawer"
					isVisible={this.state.drawerRightVisible}>
					<div className="nav-app-drawer-wrapper" onClick={this.toggleDrawerRight}>
						<div className="nav-app-drawer-bg"></div>
						<div className="nav-app-drawer">
							<a href="#drawer-appicon" className="drawer-appicon">
								<span>VER MENOS</span>
								<div>
									<img alt="close icon" src={iconClose} />
								</div>
							</a>
							<ul className="drawer-appmenu">
								<li><Link to="/carta">CARTA</Link></li>
								<li><Link to="/asamblea">ASAMBLEA</Link></li>
								<li><Link to="/sobre-wit">WIT</Link></li>
								<li><Link to="/faqs">FAQs</Link></li>
								<li><Link to="/contacto">CONTACTO</Link></li>
							</ul>
						</div>
					</div>
				</Animated>
			</header>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: state.UserDetailsReducer.userDetails,
    cart: state.cart.data,
    currentCart: state.CartReducer.currentCart,
		sessionCheck: state.UserDetailsReducer.sessionCheck
	}
}

export default connect(mapStateToProps)(Header)
