import React, { Component } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core'

const GreenRadio = withStyles({
  root: {
    color: '#27beb7',
    '&$checked': {
      color: '#27be7e',
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

export default class RadioButtons extends Component {
  render() {
    const {
      name,
      children,
      errors,
      onChange,
    } = this.props

    return (
      <div className='form-check-wrapper'>
        <RadioGroup aria-label={name} name={name} onChange={onChange}>
          {
            children.map((option, index) =>
              <FormControlLabel key={index} value={option.value} control={<GreenRadio />} label={option.label} />
            )
          }
        </RadioGroup>
        <span className='invalid-feedback'>
          {errors && errors[name] && errors[name]}
        </span>
      </div>
    )
  }
}
