import React, { Component } from 'react'
import { NIF_IMAGE_ALLOWED_MIME_TYPES } from '../../../constants/constants'

export default class ImageInput extends Component {
  constructor(props) {
    super(props)
    this.handleImageChange = this.handleImageChange.bind(this)
  }

  state = {
    file: undefined,
    imagePreviewUrl: undefined
  }

  handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        })
      }
      reader.readAsDataURL(file)
      this.props.setFieldValue(this.props.field.name, file)
    }
  }

  showPreloadImage() {
    const { file, imagePreviewUrl } = this.state

    let comp = null

    if (file && NIF_IMAGE_ALLOWED_MIME_TYPES.includes(file.type)) {
      comp = (
        <img src={imagePreviewUrl} alt='loading...' className='img-fluid' />
      )
    }

    return comp
  }

  render() {
    const { errors } = this.props
    const { name } = this.props.field

    return (
      <div className='form-group'>
        <input
          id={name}
          name={name}
          type='file'
          onChange={this.handleImageChange}
          className='input-group'
        />
        {this.showPreloadImage()}
        <span className='invalid-feedback'>{errors && errors[name] ? errors[name] : ''}</span>
      </div>
    )
  }
}

