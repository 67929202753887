import React, { Component, Fragment } from 'react'
import Calendar from 'react-calendar'
import classNames from 'classnames'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'

import { DATE_FORMAT } from '../../constants/constants'
import { convertToValidDate } from '../../constants/globals'
import {
  setDeliveryDate,
  fetchSchedule,
  resetDeliveryDate,
  resetDeliverySchedule,
} from '../../actions/cart'
import ScheduleModal from '../../containers/Cart/ScheduleModal'


import 'react-calendar/dist/Calendar.css'

export default class DeliveryCalendar extends Component {
  state = {
    active: false,
    schedules: [],
    selected: null,
  }

  onClick() {
    this.setState({active: !this.state.active})
  }

  onDateSelect(date) {
    const { dispatch, user } = this.props
    const selected = moment(date)
    const store = selected.format(DATE_FORMAT)

    dispatch(setDeliveryDate(store))

    fetchSchedule(store, user.addresses.postcode).then(({data}) => {
      if (!data.length) {
        toastr.error("No hay horarios de entrega para la fecha seleccionada")
        dispatch(resetDeliveryDate())
        dispatch(resetDeliverySchedule())
      }

      this.setState({
        schedules: data,
        active: false,
        selected: selected.toDate(),
      })
    })
  }

  componentDidUpdate() {
    const { cart: {data: { fecha_entrega }}} = this.props
    if (!this.state.selected && fecha_entrega) {
      this.setState({
        selected: moment(convertToValidDate(fecha_entrega)).toDate(),
      })
    }
  }

  emptySchedules() {
    this.setState({schedules: []})
  }

  render() {
    const classes = classNames({
      'active': this.state.active,
    })

    return (
      <Fragment>
        <div className="btn-dropdown" onClick={this.onClick.bind(this)}>
          <span>Calendario</span>
        </div>
        <Calendar
          className={classes}
          onChange={this.onDateSelect.bind(this)}
          locale='es-ES'
          showNavigation={true}
          minDate={new Date()}
          value={this.state.selected}
        />
        <ScheduleModal
          {...this.props}
          schedules={this.state.schedules}
          emptySchedules={this.emptySchedules.bind(this)}
        />
      </Fragment>
    )
  }
}
