import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import store from 'store2'
import {
  Accordion as RAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { formatPrice } from '../../../helpers/Utils'
import { fetch as fetchCustomer } from '../../../actions/customers'
import { fetchAllMonthForDate, fetchOrderedMonths } from '../../../actions/orders'
import ChangeDelivery from './ChangeDelivery'
import CancelDelivery from './CancelDelivery'
import {
  CANCELED_ORDER_STATE,
  CARDPENDING_ORDER_STATE,
  DELIVERED_ORDER_STATE,
  ONTHEWAY_ORDER_STATE,
  PENDING_ORDER_STATE,
} from '../../../constants/constants'

const OrderTitle = ({order}) => {
  return (
    <div className='order'>
      <span className='date'>
        {order.fecha_entrega}
      </span>
      <span className='schedule'>
        {order.hora_entrega}
      </span>
      <span className='price'>
        {formatPrice(order.total_paid)}
      </span>
    </div>
  )
}

const OrderAmount = ({product}) => {
  const qty = product.product_quantity
  let units = 'unidad'
  if (qty > 1) {
    units = 'unidades'
  }

  return `(${qty} ${units})`
}

const DeliveryActions = (props) => {
  const { order } = props

  if (Number(order.current_state) === CANCELED_ORDER_STATE) {
    return <p>Aportación cancelada</p>
  }

  if (Number(order.current_state) === ONTHEWAY_ORDER_STATE) {
    return <p>La aportación está en camino</p>
  }

  if (Number(order.current_state) === DELIVERED_ORDER_STATE) {
    return <p>La aportación ya ha sido entregada</p>
  }

  if (![CARDPENDING_ORDER_STATE, PENDING_ORDER_STATE].includes(Number(order.current_state))) {
    return <p>Aportación errónea o reembolsada</p>
  }

  return (
    <div className='actions'>
      <ChangeDelivery {...props} order={order} />
      <CancelDelivery {...props} order={order} />
    </div>
  )
}

export default class Accordion extends Component {
  componentDidMount() {
    const { customer, dispatch } = this.props
    if (!customer?.id) {
      dispatch(fetchCustomer(store.get('_userId'))).then(() => {
        dispatch(fetchOrderedMonths(this.props.customer))
          .then(this.loadLastMonthData.bind(this))
      })
      return
    }

    dispatch(fetchOrderedMonths(customer))
      .then(this.loadLastMonthData.bind(this))
  }

  loadLastMonthData() {
    const { dispatch } = this.props
    const dates = this.props.orders.dates.data

    if (dates.length) {
      dispatch(fetchAllMonthForDate(this.props.customer, [...dates].shift()))
    }
  }

  onAccordionChange([month]) {
    const { dispatch, customer, orders: {dates: {loaders}} } = this.props

    if (loaders[month] === false) {
      return
    }

    dispatch(fetchAllMonthForDate(customer, month))
  }

  filterOrders(date) {
    const [year, month] = date.split('-')
    const lastDay = new Date(Number(year), Number(month), 0).getDate()
    const from = moment(`${date}-01`)
    const to = moment(`${date}-${lastDay}`)
    const ids = [...this.props.orders.data.ids]
    const orders = this.props.orders.data.all

    return ids.filter((id) => {
      const order = orders[id]
      const oDate = moment(order.date_add)
      return oDate.isBetween(from, to)
    })
  }

  render() {
    const { dates, loading, data } = this.props.orders
    const { all } = data

    if (loading) {
      return <p>Cargando retiradas...</p>
    }

    if (!dates.data.length) {
      return (
        <p>
          No consta que hayas retirado todavía. Anímate y haz tu primer
          pedido desde la <Link to='/carta'>carta</Link>.
        </p>
      )
    }

    const attrs = {
      preExpanded: [[...dates.data].shift()],
    }

    return (
      <RAccordion onChange={this.onAccordionChange.bind(this)} {...attrs}>
        {
          dates.data.map((date) => (
            <AccordionItem key={date} uuid={date}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {date}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {
                  this.filterOrders(date).map((id) => (
                    <div key={id} className='order-wrapper'>
                      <OrderTitle order={all[id]} />
                      <ul className='details'>
                      {
                        all[id].associations.order_rows.map((product, index) => {
                          return (
                            <li key={`${id}-${index}`}>
                              <span className='name'>
                                {product.product_name}
                              </span>
                              <span className='price'>
                                {formatPrice(product.product_price)}
                              </span>
                              <span className='quantity'>
                                <OrderAmount product={product} />
                              </span>
                            </li>
                          )
                        })
                      }
                      </ul>
                      <DeliveryActions {...this.props} order={all[id]} />
                    </div>
                  ))
                }
              </AccordionItemPanel>
            </AccordionItem>
          ))
        }
      </RAccordion>
    )
  }
}
