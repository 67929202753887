import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'formik'

export default class Text extends Component {
  state = {
    active: false,
  }

  active() {
    this.setState({active: true})
  }

  inactive() {
    const { name, values } = this.props

    if (!values[name]) {
      this.setState({active: false})
    }
  }

  componentDidMount() {
    const { name, values } = this.props

    if (values[name] && values[name].length) {
      this.active();
    }
  }

  render() {
    const { name, label, errors, onChange } = this.props
    let { type } = this.props
    const classes = {
      'form-group': true,
      active: this.state.active,
    }

    if (!type) {
      type = 'text'
    }

    const props = {...this.props}

    delete props.setFieldValue

    return (
      <div className={classNames(classes)}>
        <div className='input-group'>
          <label htmlFor={`field-${name}`}>{label}</label>
          <Field
            {...props}
            type={type}
            id={`field-${name}`}
            name={name}
            onFocus={this.active.bind(this)}
            onBlur={this.inactive.bind(this)}
            onInput={(e) => {
              // Force active status when receiving input event (autocomplete)
              this.active()
            }}
            onChange={(e) => {
              if (type !== 'file') {
                return onChange(e)
              }

              const file = [...e.currentTarget.files].pop()
              this.props.setFieldValue(`${name}_data`,  file)

              return onChange(e)
            }}
            className='form-control'
          />
        </div>
        <span className='invalid-feedback'>
          {errors && errors[name] && errors[name]}
        </span>
      </div>
    )
  }
}
