import React, { Component } from 'react'
import moment from 'moment'
import { Accordion } from 'react-accessible-accordion'

import { DATE_FORMAT_SQL } from '../../constants/constants'
import CertRow from './CertRow'


export default class PendingCerts extends Component {
  render() {
    const  { assemblies } = this.props
    const pending = []

    assemblies.forEach((assembly) => {
      const begin = moment(assembly.date_start, DATE_FORMAT_SQL)
      const finish = moment(assembly.date_end, DATE_FORMAT_SQL)

      if (begin.isSameOrBefore(moment()) && finish.isSameOrAfter(moment())) {
        pending.push({
          ...assembly,
          name: `Borrador ${assembly.name}`,
        })
      }
    })

    if (!pending.length) {
      return <p>Ahora mismo no hay ningún acta pendiente de aprobación.</p>
    }

    return (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {pending.map((cert) => <CertRow cert={cert} key={cert.id_witactas} vote={true} {...this.props} />)}
      </Accordion>
    )
  }
}
