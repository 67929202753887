import React, { Component, Fragment } from 'react'
import { Animated } from 'react-animated-css'
import classNames from 'classnames'
import { formatPrice, formatWeight } from '../../helpers/Utils'
import { ENDORSE_USERS_GROUP } from '../../constants/constants'
import { addToCart } from '../../actions/cart'
import { toInteger } from '../../constants/globals'
import { fetchByProductId } from '../../actions/combinations'

export default class Contribute extends Component {
  state = {
    active: false,
  }

  onClick() {
    this.setState({active: !this.state.active})
  }

  onContributeClick(combination, stock) {
    this.props.dispatch(addToCart(combination, stock))
  }

  componentDidUpdate() {
    const { combinations, product } = this.props
    if (!combinations.byProductId[product.id] && !combinations.loading) {
      this.props.dispatch(fetchByProductId(this.props.product.id))
    }
  }

  render() {
    const { product } = this.props
    const btnDropdownClass = classNames({
      'active': this.state.active,
      'btn-dropdown': true,
      'btn-dropdown--disabled': this.props.disabled,
    })
    const disabledCartClasses = ['add-to-cart-message']
    if (this.props.user.id) {
      const group = this.props.user.associations.groups.find(({id}) =>
        toInteger(id) === ENDORSE_USERS_GROUP
      )

      if (group) {
        disabledCartClasses.push('active')
      }
    }

    let combinations = []
    if (this.props.combinations.byProductId[product.id]) {
      combinations = this.props.combinations.byProductId[product.id]
    }

    combinations = combinations.map((combination, index) => {
      let text = formatPrice(combination.price)
      if (toInteger(combination.weight)) {
        text += ' / ' + formatWeight(combination.weight)
      }

      const stock = this.props.stocks[combination.id]
      const hasStock = typeof stock !== 'undefined'
        && toInteger(stock.quantity)

      if (!hasStock) {
        return null
      }

      return (
        <button
          key={`combination-${combination.id}`}
          onClick={this.onContributeClick.bind(this, combination, stock)}
        >
          {text}
        </button>
      )
    }).filter((val) => val !== null)

    if (!combinations.length) {
      combinations.push(
        <button disabled key='no-combination'>Sin stock</button>
      )
    }

    return (
      <Fragment>
        <div className={btnDropdownClass} onClick={this.onClick.bind(this)}>
          <span>APORTAR</span>
          <Animated
            animationIn='fadeIn'
            animationOut='fadeOut'
            animateOnMount={false}
            className='faster'
            isVisible={this.state.active}>
            <div className='btn-dropdown-content'>
              {combinations}
            </div>
          </Animated>
        </div>
        <div className={disabledCartClasses}>
          La opción de Aportar está desactivada para tu usuario, si deseas
          hacer una aportación ponte en contacto con nosotros y recuerda
          actualizar tu previsión de consumo mensual desde la página Previsión.
        </div>
      </Fragment>
    )
  }
}
