import React, { Component } from 'react'

import WitPage from '../../Common/WitPage'
import Accordion from './Accordion'
import Chart from './Chart'

export default class History extends Component {
  render() {
    return (
      <WitPage {...this.props}>
        <div className="page-title">
          <p>Historial</p>
        </div>
        <div className='styled-content'>
          <p>
            El historial es una herramienta de reducción de riesgos, siendo así
            conscientes de nuestro consumo mensual.
          </p>
          <p>
            Desde aquí también podrás CAMBIAR la fecha de un reparto programado o CANCELAR
            una aportación siempre y cuando no esté en camino.
          </p>
        </div>
        <div className='orders-chart'>
          <h2>Consumo durante el último año</h2>
          <Chart {...this.props} />
        </div>
        <div className='orders-history'>
          <h2>Historial de retiradas</h2>
          <Accordion {...this.props} />
        </div>
      </WitPage>
    )
  }
}
