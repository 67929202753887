import axios from 'axios'
import https from 'https'

import { API_FORMAT, API_WS_KEY, API_PROPER, API_PROXY, API_URL_FORMS } from './api-config'

let agent = new https.Agent()

if (process.env.NODE_ENV === 'development') {
  agent = new https.Agent({
    rejectUnauthorized: false,
  })
}

const params = {
  output_format: API_FORMAT,
  ws_key: API_WS_KEY,
}

export const ProperApi = axios.create({
  baseURL: API_PROPER,
  httpsAgent: agent,
  params,
})

export const FormsApi = axios.create({
  baseURL: API_URL_FORMS,
  httpsAgent: agent,
})

const Api = axios.create({
  baseURL: API_PROXY,
  httpsAgent: agent,
  params,
})

export default Api
