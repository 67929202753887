import {
    GET_ASAMBLEA_ACTAS,
    GET_ACTIVE_ASAMBLEA_ACTAS
} from "../constants/action-types";

const initialState = {
    asambleaActas: [],
    activeAsambleaActas: []
};

const AsambleaReducer = function(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ASAMBLEA_ACTAS:
            return Object.assign({}, state, {
                asambleaActas: action.asambleaActas
            });
        case GET_ACTIVE_ASAMBLEA_ACTAS:
            return Object.assign({}, state, {
                activeAsambleaActas: action.activeAsambleaActas
            });
        default:
            return state;
    }
};

export default AsambleaReducer;
