import { API_SET_AUTH_KEY } from '../constants/action-types'
import store from 'store2'

export const setAuthKey = (key) => () => ({
  type: API_SET_AUTH_KEY,
  key,
})

export const checkSessionData = (error) => {
  const code = error.response ? error.response.status : error.status

  if (code !== 401) {
    throw error
  }

  store.remove('_userId')
  store.remove('_key')
  store.remove('_userLastLogin')
  store.set('_userLogged', false)

  document.location.href = process.env.PUBLIC_URL
}
