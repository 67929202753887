import {
  GROUPS_FETCH_ALL,
  GROUPS_FETCH_ALL_SUCCESS,
} from '../constants/action-types'
import { loading, ids } from '../constants/mappers'

const defaultState = {
  data: {
    all: {},
    ids: [],
  },
  loading: false,
  loaded: false,
  error: false,
}

const onFetchAllSuccess = (state, { groups }) => ({
  loading: false,
  loaded: true,
  data: ids(groups),
})

const GroupsReducer  = (state = defaultState, action = {}) => {
  switch (action.type) {
    case GROUPS_FETCH_ALL:
      return loading(state)
    case GROUPS_FETCH_ALL_SUCCESS:
      return onFetchAllSuccess(state, action)
    default:
      return state
  }
}

export default GroupsReducer
