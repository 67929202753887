import React, { Component } from 'react'
import { Accordion } from 'react-accessible-accordion'
import moment from 'moment'

import { DATE_FORMAT_SQL } from '../../constants/constants'
import CertRow from './CertRow'

export default class Announcement extends Component {
  render() {
    const  { assemblies } = this.props
    const announcements = []

    assemblies.forEach((assembly) => {
      const date = moment(assembly.date_start, DATE_FORMAT_SQL)

      if (date.isSameOrAfter((moment()))) {
        announcements.push({
          ...assembly,
          name: `Convocatoria ${assembly.name}`,
        })
      }
    })

    if (!announcements.length) {
      return <p>Ahora mismo no hay ninguna asamblea convocada.</p>
    }

    return (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {announcements.map((cert) => <CertRow cert={cert} key={cert.id_witactas} />)}
      </Accordion>
    )
  }
}
