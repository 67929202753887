import {
  ASSEMBLY_FETCH,
  ASSEMBLY_FETCH_SUCCESS,
  ASSEMBLY_FETCH_ERROR,
  ASSEMBLY_VOTE,
  ASSEMBLY_VOTE_SUCCESS,
  ASSEMBLY_VOTE_ERROR,
} from '../constants/action-types'
import { FormsApi } from '../helpers/Api'

const onFetchStart = () => ({
  type: ASSEMBLY_FETCH,
})

const onFetchSuccess = (assemblies) => ({
  type: ASSEMBLY_FETCH_SUCCESS,
  assemblies,
})

export const fetch = (id_customer) => (dispatch) => {
  dispatch(onFetchStart())

  return FormsApi.get('', {params: {
      action: 'actas',
      id_customer,
    }})
    .then((response) => dispatch(onFetchSuccess(response.data)))
    .catch((error) => dispatch({type: ASSEMBLY_FETCH_ERROR, error}))
}


export const vote = (user, cert, value) => (dispatch) => {
  dispatch({
    type: ASSEMBLY_VOTE,
  })

  return FormsApi.post('', {}, {
    params: {
      action: 'votacion',
      id_customer: user,
      id_acta: cert,
      votacion: value,
    }
  })
  .then((response) => dispatch({type: ASSEMBLY_VOTE_SUCCESS, cert, value}))
  .catch((error) => dispatch({type: ASSEMBLY_VOTE_ERROR, cert, value}))
}
