import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'


export default class Links extends Component {
	render() {
		return (
			<Fragment>
				<h4>Documentación de bienvenida</h4>
				<div className="section">
					<ul>
            <li>
							<a href='#open-toc' onClick={this.props.modalOpen}>
								Releer términos y condiciones
							</a>
						</li>
						<li>
							<a rel="noopener noreferrer" href="https://bcnwit.org/dist/documents/Estatutos-WIT.pdf" target="_blank">
								Estatutos
							</a>
						</li>
						<li>
							<a rel="noopener noreferrer" href="https://bcnwit.org/dist/documents/Reglamento-Regimen-Interno.pdf" target="_blank">
								Reglamento de Régimen Interno
							</a>
						</li>
						<li><Link to="/faqs">FAQ’s</Link></li>
					</ul>
				</div>
			</Fragment>
		)
	}
}
