import moment from 'moment'

import {
  ORDERS_CANCEL_SUCCESS,
  ORDERS_CHANGE_DELIVERY_ERROR,
  ORDERS_CHANGE_DELIVERY_SUCCESS,
  ORDERS_CREATE,
  ORDERS_CREATE_ERROR,
  ORDERS_CREATE_SUCCESS,
  ORDERS_FETCH_ALL,
  ORDERS_FETCH_ALL_ERROR,
  ORDERS_FETCH_ALL_SUCCESS,
  ORDERS_FETCH_LATEST,
  ORDERS_FETCH_LATEST_ERROR,
  ORDERS_FETCH_LATEST_SUCCESS,
  ORDERS_FETCH_MONTH,
  ORDERS_FETCH_MONTH_SUCCESS,
  ORDERS_FETCH_ORDERED_DATES,
  ORDERS_FETCH_ORDERED_DATES_SUCCESS,
} from '../constants/action-types'

import { ids } from '../constants/mappers'

const defaultState = {
  data: {
    all: {},
    ids: [],
  },
  dates: {
    loaders: {},
    data: [],
  },
  latest: {},
  loading: false,
  loaded: false,
  error: false,
}

const loading = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  error: false,
})

const onFetchLatestSuccess = (state, {latest}) => ({
  ...state,
  loading: false,
  loaded: true,
  latest,
})

const onFetchAllSuccess = (state, {orders}) => {
  const data = ids(orders)

  let latest = {...state.latest}
  if (!Object.keys(latest).length) {
    latest = [Object.values(data.all)].pop()
  }

  return {
    ...state,
    loading: false,
    loaded: true,
    latest,
    data,
  }
}

const onFetchDatesSuccess = (state, {orders}) => {
  const dates = []

  orders.forEach((order) => {
    const year = moment(order.date_add).format('YYYY')
    const month = moment(order.date_add).format('MM')

    const date = `${year}-${month}`

    if (!dates.includes(date)) {
      dates.push(date)
    }
  })

  return {
    ...state,
    dates: {
      ...state.dates,
      data: dates,
    },
    loading: false,
    loaded: true,
  }
}

const onFetchMonth = (state, {date}) => ({
  ...state,
  dates: {
    ...state.dates,
    loaders: {
      ...state.dates.loaders,
      [date]: true,
    },
  },
})

const onFetchMonthSuccess = (state, {orders, date}) => ({
  ...state,
  data: ids([
    ...Object.values(state.data.all),
    ...orders,
  ]),
  dates: {
    ...state.dates,
    loaders: {
      ...state.dates.loaders,
      [date]: false,
    },
  },
})

const onFetchError = (state, {error}) => ({
  ...state,
  loading: false,
  error,
})

const onChangeDeliverySuccess = (state, {order}) => {
  const orders = {
    ...state.data.all,
    [order.id]: order,
  }

  return {
    ...state,
    data: {
      ...state.data,
      all: orders,
    },
  }
}

const onCancelSuccess = (state, {order}) => {
  const orders = {
    ...state.data.all,
    [order.id]: order,
  }

  return {
    ...state,
    data: {
      ...state.data,
      all: orders,
    }
  }
}

const OrdersReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ORDERS_FETCH_ORDERED_DATES:
    case ORDERS_FETCH_LATEST:
      return loading(state)
    case ORDERS_CREATE_SUCCESS:
    case ORDERS_FETCH_LATEST_SUCCESS:
      return onFetchLatestSuccess(state, action)

    case ORDERS_CREATE:
    case ORDERS_FETCH_ALL:
      return loading(state)
    case ORDERS_FETCH_ALL_SUCCESS:
      return onFetchAllSuccess(state, action)

    case ORDERS_FETCH_LATEST_ERROR:
    case ORDERS_CREATE_ERROR:
    case ORDERS_FETCH_ALL_ERROR:
    case ORDERS_CHANGE_DELIVERY_ERROR:
      return onFetchError(state, action)

    case ORDERS_FETCH_ORDERED_DATES_SUCCESS:
      return onFetchDatesSuccess(state, action)

    case ORDERS_CHANGE_DELIVERY_SUCCESS:
      return onChangeDeliverySuccess(state, action)

    case ORDERS_FETCH_MONTH:
      return onFetchMonth(state, action)

    case ORDERS_FETCH_MONTH_SUCCESS:
      return onFetchMonthSuccess(state, action)

    case ORDERS_CANCEL_SUCCESS:
      return onCancelSuccess(state, action)

    default:
      return state
  }
}

export default OrdersReducer
