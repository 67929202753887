import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Calendar from "react-calendar";
import { toastr } from "react-redux-toastr";

import { API_URL_FORMS } from "./api-config";

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputEmail: '',
			errorInputEmail: ''
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSelectDeliveryTime = this.handleSelectDeliveryTime.bind(this);
		this.setCalendarDeliveryDate = this.setCalendarDeliveryDate.bind(this);
	}

	handleEmailChange(event) {
		const inputValue = event.target.value;
		this.setState(state => ({
			inputEmail: inputValue
		}));
	}

	handleClick() {
		if (this.props.modalType === 'recovery') {
			if (this.state.inputEmail !== '') {
				this.setState(state => ({
					errorInputEmail: ''
				}));

				let formAction = 'pwd';
				axios.post(API_URL_FORMS + '?action=' + formAction + "&email=" + this.state.inputEmail)
					.then(res => {
						if (res.data === 'ok') {
							toastr.success('Hemos procesado tu solicitud. Revisa tu email y sigue las instrucciones para finalizar el proceso');
							this.props.toggleModal();
						} else {
							toastr.error('No hemos podido procesar la solicitud. Por favor, ponte en contacto con el equipo WIT');
						}
					});
			} else {
				this.setState(state => ({
					errorInputEmail: 'El campo dirección de correo es obligatorio'
				}));
			}
		} else if (this.props.modalType === 'success') {
			this.props.toggleModal();
		} else if (this.props.modalType === 'gmaps') {
			this.props.toggleModal();
		} else if (this.props.modalType === 'alert') {
			this.props.toggleModal();
		} else if (this.props.modalType === 'asamblea') {
			this.props.toggleModal();
		}
	}

	handleSelectDeliveryTime(deliveryTime) { // used in Cart, History
		this.props.setCalendarTime(deliveryTime);
		this.props.toggleModal();
	}

	setCalendarDeliveryDate(date) { // used in History
		this.props.toggleModal(date);
	}

	renderModalTemplate() {
		if (this.props.modalType === 'recovery') {
			return (
				<div className="modal-template">
					<p>
						Si has olvidado tu contraseña, puedes recuperarla usando tu correo electrónico.<br />
						Introduce tu dirección y pulsa el botón 'Recuperar'
					</p>
					<input type="text" placeholder="Dirección de correo" value={this.state.inputEmail} onChange={this.handleEmailChange} />
					<span className="error-msg">{this.state.errorInputEmail}</span>
					<button className="modal-button" onClick={this.handleClick}>RECUPERAR</button>
				</div>
			);
		} else if (this.props.modalType === 'success') {
			return (
				<div className="modal-template">
					<p>
						Muchas gracias por completar el curso, pronto recibirás una llamada para comprobar tus datos y contarte los próximos pasos para hacerte socio.<br /><br />
						Una vez tu solicitud haya sido aprobada por la Asamblea General recibirás un correo electrónico con los próximos pasos para poder acceder a la web y hacer tu primera retirada.
					</p>
					<button className="modal-button" onClick={this.handleClick}>ENTENDIDO</button>
				</div>
			);
		} else if (this.props.modalType === 'gmaps') {
			return (
				<div className="modal-template">
					<iframe title="map" src="https://www.google.com/maps/d/embed?mid=1v0KIvUYBs8xqZCCb7Kj95j6xKswji9WE" width="100%" height="290"></iframe>
					<button className="modal-button" onClick={this.handleClick}>ENTENDIDO</button>
				</div>
			);
		} else if (this.props.modalType === 'deliverytime' && this.props.modalTimes) { // used in Cart, History
			if (this.props.modalTimes.length > 0) {
				return (
					<div className="modal-template">
						<p>Selecciona la hora de reparto:</p>
						{this.props.modalTimes.map((deliveryTime, index) => {
							return( <button className="modal-button" key={index} onClick={() => { this.handleSelectDeliveryTime(deliveryTime) }}>{deliveryTime}</button> );
						})}
					</div>
				);
			} else {
				return (
					<div className="modal-template">
						<p>Sin horarios disponibles</p>
						<button className="modal-button" onClick={() => { this.handleSelectDeliveryTime(null) }}>ENTENDIDO</button>
					</div>
				);
			}
		} else if (this.props.modalType === 'deliverydate') { // used in History
			return (
				<div className="modal-template">
					<p>Selecciona una fecha de reparto:</p>
					<Calendar
						className='active'
						onChange={this.setCalendarDeliveryDate}
						locale="es-ES"
						showNavigation={true} />
				</div>
			);
		} else if (this.props.modalType === 'alert') {
			return (
				<div className="modal-template">
					<p className="alert-title">{this.props.modalAlertTitle}</p>
					<div className="alert-content" dangerouslySetInnerHTML={{__html: this.props.modalAlertContent}} />
					<button className="modal-button" onClick={this.handleClick}>ENTENDIDO</button>
				</div>
			);
		} else if (this.props.modalType === 'asamblea') {
			return (
				<div className="modal-template">
					<p className="alert-title">Necesitamos tu voto</p>
					<div className="alert-content">Como socio, tu participación de voto es muy importante para apobar el acta de la asamblea que realizamos mensualmente.<br /><br />Tranquilo, es muy rápido, aunque si lo prefieres la puedes realizar en otro momento desde el menú <Link to="/asamblea">QUIERO VOTAR</Link></div>
					<button className="modal-button" onClick={this.handleClick}>ENTENDIDO</button>
				</div>
			);
		} else if (this.props.modalType === 'browsercheck') {
			return (
				<div className="modal-template">
					<p className="alert-title">{this.props.modalAlertTitle}</p>
					<div className="alert-content" dangerouslySetInnerHTML={{__html: this.props.modalAlertContent}} />
				</div>
			);
		} else {
			return (<div className="modal-template"></div>);
		}
	}

	render() {
		return (
			<div className="modal">{this.renderModalTemplate()}</div>
		);
	}
}

export default Modal;
