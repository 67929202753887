import { CMS_FETCH_ALL_SUCCESS, CMS_FETCH_ALL, CMS_MARK_AS_READ } from '../constants/action-types'
import { ids } from '../constants/mappers'
import { arrayRemove } from '../constants/globals'

const defaultState = {
  data: {
    all: {},
    ids: [],
  },
  display: [],
}

const onFetchAll = (state) => ({
  ...state,
  loading: true,
  loaded: false,
})

const onFetchAllSuccess = (state, { cms }) => {
  const mapped = ids(cms)
  const display = mapped.ids

  return {
    ...state,
    loading: false,
    loaded: true,
    data: {
      ...mapped,
    },
    display,
  }
}

const onMarkAsRead = (state, { id }) => {
  const display = [...state.display]
  arrayRemove(display, id)

  return {
    ...state,
    display,
  }
}

const CMSReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case CMS_MARK_AS_READ:
      return onMarkAsRead(state, action)
    case CMS_FETCH_ALL:
      return onFetchAll(state)
    case CMS_FETCH_ALL_SUCCESS:
      return onFetchAllSuccess(state, action)
    default:
      return state
  }
}

export default CMSReducer
