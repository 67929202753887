export const API_PROTO = window.location.protocol

let BASE = `${API_PROTO}//bcnwit.org`
if (process.env.NODE_ENV === 'development') {
  BASE = 'https://bcnwit.caprica.loc'
}

export const BASE_URL = BASE
export const API_WS_KEY = 'thisaintnothingyoucanusegainstus'
export const API_URL = `${BASE}/api`
export const API_PROPER = `${API_URL}/proper-api`
export const API_LOGIN = `${API_PROPER}/login`
export const API_PROXY = `${API_PROPER}/proxy`
export const API_URL_FORMS = `${BASE}/scripts/forms.php`
export const API_URL_PAYMENT = `${BASE}/scripts/pagoTPV.php`
export const API_FORMAT = 'JSON'
