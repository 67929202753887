import { connect } from 'react-redux'

import History from '../../components/User/History/index'

const mapStateToProps = (state) => ({
  cms: state.cms,
  cart: state.cart,
  currentCart: state.CartReducer.currentCart,
  customers: state.customers,
  customer: state.customers.customer,
  user: state.UserDetailsReducer.userDetails || {},
  orders: state.orders,
})

const ProductsContainer = connect(mapStateToProps)(History)


export default ProductsContainer
