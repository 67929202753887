import React, { Component } from "react";
import qs from "query-string";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import { API_URL_FORMS } from "../../helpers/api-config";

import Footer from "../Footer/Footer";

import "./PasswordReset.css";

class PasswordReset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputPassword: '',
			inputPasswordConfirm: '',
			errorInputPassword: '',
			errorInputPasswordConfirm: '',
			passwordChangedSuccess: false
		};

		this.initialState = this.state;

		this.onChangeInputPassword = this.onChangeInputPassword.bind(this);
		this.onChangeInputPasswordConfirm = this.onChangeInputPasswordConfirm.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.renderFormState = this.renderFormState.bind(this);
	}

	onChangeInputPassword(event) {
		const eventValue = event.target.value;
		this.setState(state => ({
			inputPassword: eventValue
		}));
	}

	onChangeInputPasswordConfirm(event) {
		const eventValue = event.target.value;
		this.setState(state => ({
			inputPasswordConfirm: eventValue
		}));
	}

	handlePasswordChange() {
		let isValid = 1;
		const queryString = qs.parse(this.props.location.search);

		if (!queryString.id_customer || !queryString.reset_token || !queryString.token) {
			isValid = 0;
			return toastr.error('Las claves para recuperar el acceso no son válidas. Vuelve a realizar todo el proceso e inténtalo de nuevo');
		}
		if (this.state.inputPassword === '') {
			isValid = 0;
			this.setState(state => ({
				errorInputPassword: 'El campo nueva contraseña no puede estar vacío'
			}));
		}
		if (this.state.inputPasswordConfirm === '') {
			isValid = 0;
			this.setState(state => ({
				errorInputPasswordConfirm: 'El campo confirmar nueva contraseña no puede estar vacío'
			}));
		}
		if (this.state.inputPassword !== this.state.inputPasswordConfirm) {
			isValid = 0;
			toastr.error('Los campos de contraseña no coinciden. Ambas contraseñas deben ser iguales');
		}

		if (isValid) {
			this.setState(state => ({
				errorInputPassword: '',
				errorInputPasswordConfirm: ''
			}));

			let formAction = 'recovery';
			axios.post(API_URL_FORMS + '?action=' + formAction + '&token=' + queryString.token + '&id_customer=' + queryString.id_customer + '&reset_token=' + queryString.reset_token + '&passwd=' + this.state.inputPassword)
				.then(res => {
					if (res.data === 'ok') {
						this.setState(state => ({
							passwordChangedSuccess: true
						}), () => {
							toastr.success('Tu acceso a WIT ha sido reestablecido. Ya puedes iniciar sesión con tus nuevos credenciales');
						});
					} else {
						toastr.error('No hemos podido procesar la solicitud. Por favor, ponte en contacto con el equipo WIT');
					}
				});
		}
	}

	goToLoginPage() {
		window.location.href = 'https://bcnwit.org';
	}

	renderFormState() {
		if (this.state.passwordChangedSuccess) {
			return (
				<div className="reset-form-wrapper">
					¡Felicidades!<br />
					Ya puedes acceder con tus nuevos credenciales en la aplicación WIT
					<button onClick={this.goToLoginPage}>INICIAR SESIÓN</button>
				</div>
			);
		} else {
			return (
				<div className="reset-form-wrapper">
					<input type="password" name="password" placeholder="Nueva contraseña" value={this.state.inputPassword} onChange={this.onChangeInputPassword} />
					<span className="error-msg">{this.state.errorInputPassword}</span>

					<input type="password" name="password-confirm" placeholder="Repite la nueva contraseña" value={this.state.inputPasswordConfirm} onChange={this.onChangeInputPasswordConfirm} />
					<span className="error-msg">{this.state.errorInputPasswordConfirm}</span>

					<button onClick={this.handlePasswordChange}>CAMBIAR CONTRASEÑA</button>
				</div>
			);
		}
	}

	render() {
		return (
			<div className="wit-page">
				<div className="page-title standalone">
					<p>RECUPERAR ACCESO</p>
				</div>

				{this.renderFormState()}

				<Footer />
			</div>
		);
	}
}

export default PasswordReset;