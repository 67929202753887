import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import CategoryImage from './Image'

export default class Category extends Component {
  render() {
    const { category } = this.props
    const link = `/carta/${category.link_rewrite}`

    return (
      <Link to={link}>
        <div className="variety-title">
          <span>{category.name}</span>
        </div>
        <div className="variety-image">
          <CategoryImage category={category} />
        </div>
      </Link>
    )
  }
}
