import React, { Component } from "react";

class NotFoundPage extends Component {
	render() {
		return (
			<div className="notfound">
				<div className="notfound-container">
					<h1>NotFound <span>Error 404</span></h1>
					<h2>Vaya, parece que la página que buscas no existe</h2>
				</div>
			</div>
		);
	}
}

export default NotFoundPage;