import {
  CATEGORIES_FETCH,
  CATEGORIES_FETCH_ERROR,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_SET_ACTIVE,
  CATEGORIES_SET_ACTIVE_BY_REF,
} from '../constants/action-types'
import { ids, byRef } from '../constants/mappers'

const initialState = {
  categories: {
    all: {},
    ids: [],
  },
  byRef: {},
  root: false,
  menuCategories: [],
  loading: false,
  loaded: false,
  errors: false,
  active: {
    id: 0,
    name: '',
    associations: {
      products: [],
    },
  },
}


const onCategoriesFetchSuccess = (state, { categories }) => ({
  ...state,
  loaded: true,
  loading: false,
  root: categories.find((category) => category.is_root_category === "1"),
  categories: {
    ...state.categories,
    ...ids(categories),
  },
  byRef: byRef(categories, 'link_rewrite'),
})

const retrieveMenuCategories = (state) => {
  let menuCategories = []
  state.root.associations.categories.forEach(({ id }) => {
    menuCategories.push(state.categories.all[id])
  })

  return {
    ...state,
    menuCategories,
  }
}

const onCategoriesFetchError = (state, { error }) => ({
  loading: false,
  loaded: false,
  error,
})

const onSetActive = (state, { id }) => ({
  ...state,
  active: {
    ...state.categories.all[id]
  },
})

const onSetActiveByRef = (state, { ref }) => ({
  ...state,
  active: {
    ...state.byRef[ref],
  },
})

const loading = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  error: false,
})

const CategoriesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CATEGORIES_FETCH:
      return loading(state)
    case CATEGORIES_FETCH_SUCCESS:
      state = onCategoriesFetchSuccess(state, action)
      state = retrieveMenuCategories(state)
      return state
    case CATEGORIES_FETCH_ERROR:
      return onCategoriesFetchError(state, action)

    case CATEGORIES_SET_ACTIVE:
      return onSetActive(state, action)
    case CATEGORIES_SET_ACTIVE_BY_REF:
      return onSetActiveByRef(state, action)

    default:
      return state
  }
}

export default CategoriesReducer
