import {
  CATEGORIES_FETCH,
  CATEGORIES_FETCH_ERROR,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_SET_ACTIVE,
  CATEGORIES_SET_ACTIVE_BY_REF,
} from '../constants/action-types'
import Api from '../helpers/Api'
import store from 'store2'

export const setActive = (id) => ({
  type: CATEGORIES_SET_ACTIVE,
  id,
})

export const setActiveByRef = (ref) => ({
  type: CATEGORIES_SET_ACTIVE_BY_REF,
  ref,
})

export const categoriesFetch = () => (dispatch) => {
  dispatch({
    type: CATEGORIES_FETCH,
  })

  const key = store.get('_key')

  return Api.get('/categories', {params: {display: 'full', key}}).then((data) =>
    dispatch({
      type: CATEGORIES_FETCH_SUCCESS,
      categories: data.data.categories,
    })
  ).catch((error) =>
    dispatch({
      type: CATEGORIES_FETCH_ERROR,
      error,
    })
  )
}
