import React, { Component } from 'react'
import store from 'store2'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { BASE_URL } from '../../helpers/api-config'
import { vote } from '../../actions/assemblies'
import If from '../../helpers/If'


export default class CertRow extends Component {
  vote(value) {
    this.props.dispatch(vote(store.get('_userId'), this.props.cert.id_witactas, value))
  }

  render() {
    const  { cert } = this.props

    let canVote = false
    if (typeof this.props.vote !== 'undefined') {
      canVote = this.props.vote
    }

    return (
      <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
              {cert.name} - <span className="asamblea-date">{cert.date}</span>
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <div className="asamblea-description">
              <div dangerouslySetInnerHTML={{__html: cert.description}} />
              <If condition={cert.file !== null && cert.file.length > 0}>
                <a href={`${BASE_URL}/img/actas/${cert.file}`}>
                  Acta de la asamblea
                </a>
              </If>
            </div>
            <If condition={cert.respuesta !== null}>
              <div className="asamblea-vote">
                {cert.respuesta ? 'Votaste SÍ para este acta' : 'Votaste NO para este acta'}
              </div>
            </If>
            <If condition={canVote}>
              <div className="asamblea-votes">
                <button onClick={this.vote.bind(this, 1)}>VOTAR SÍ</button>
                <button onClick={this.vote.bind(this, 0)}>VOTAR NO</button>
              </div>
            </If>
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
}
