import store from 'store2'

import {
  CUSTOMER_FETCH,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_HISTORY,
  CUSTOMER_FETCH_HISTORY_ERROR,
  CUSTOMER_FETCH_HISTORY_SUCCESS,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_UPDATE_SUCCESS,
} from '../constants/action-types'
import Api, { ProperApi } from '../helpers/Api'
import { json2Xml } from '../constants/globals'

export const customerFetchSuccess = (customer) => ({
  type: CUSTOMER_FETCH_SUCCESS,
  customer,
})

export const update = (client) => (dispatch) => {
  dispatch({
    type: CUSTOMER_UPDATE,
  })

  const customer = {...client}
  // Avoid destroying relations
  delete customer.associations

  const key = store.get('_key')

  return Api.put(`/customers/${customer.id}`, json2Xml({customer}), {params: {key}})
    .then(({data}) => dispatch({
      type: CUSTOMER_UPDATE_SUCCESS,
      customer: data.customer,
    }))
    .catch((error) => dispatch({
      type: CUSTOMER_UPDATE_ERROR,
      error,
    }))
}

export const fetch = (id) => (dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH,
  })

  const key = store.get('_key')

  return Api.get(`/customers/${id}`, {params: {key}})
    .then(({data}) => dispatch(customerFetchSuccess(data.customer)))
    .catch((error) => dispatch({
      type: CUSTOMER_FETCH_ERROR,
      error,
    }))
}

export const fetchCourseData = (id) => (dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH,
  })

  return ProperApi.get(`/signup/${id}`)
    .then(({data}) => dispatch({
      type: CUSTOMER_FETCH_SUCCESS,
      customer: data,
    }))
    .catch((error) => dispatch({
      type: CUSTOMER_FETCH_ERROR,
      error,
    }))
}


export const fetchHistory = ({id}) => (dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_HISTORY,
  })

  const key = store.get('_key')

  return ProperApi.get(`/history/${id}`, {params: {key}})
    .then(({data}) => dispatch({
      type: CUSTOMER_FETCH_HISTORY_SUCCESS,
      historic: data,
    }))
    .catch((error) => dispatch({
      type: CUSTOMER_FETCH_HISTORY_ERROR,
      error,
    }))
}
