import React, { Component } from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import CMSModal from './CMSModals'
import Whatsapp from './Whatsapp'

export default class WitPage extends Component {
  render() {
    return (
      <div className='wit-page'>
        <CMSModal {...this.props} />
        <Header {...this.props} />

        {this.props.children}
        <Footer />
        <Whatsapp {...this.props} />
      </div>
    )
  }
}
