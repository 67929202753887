import React, { Component } from 'react'

export default class Link extends Component {
  render() {
    const attributes = {
      href: this.props.href,
      ...this.props,
    }

    if (this.props.external) {
      delete attributes.external

      attributes.target = '_blank'
      attributes.rel = 'noopener noreferrer'
    }

    return (
      <a {...attributes}>{ this.props.children }</a>
    )
  }
}
