import { ASSEMBLY_FETCH_SUCCESS, ASSEMBLY_VOTE_SUCCESS } from '../constants/action-types'
import { ids } from '../constants/mappers'

const defaultState = {
  data: {
    all: {},
    ids: [],
  },
  display: [],
}

const onFetchSuccess = (state, {assemblies}) => ({
  ...state,
  data: {
    ...ids(assemblies, 'id_witactas'),
  }
})

const onVoteSuccess = (state, {cert, value}) => {
  const certificate = {...state.data.all[cert]}

  certificate.respuesta = value

  return {
    ...state,
    data: {
      ...state.data,
      all: {
        ...state.data.all,
        [cert]: certificate,
      },
    },
  }
}

const AssembliesReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ASSEMBLY_FETCH_SUCCESS:
      return onFetchSuccess(state, action)

    case ASSEMBLY_VOTE_SUCCESS:
      return onVoteSuccess(state, action)
    default:
      return state
  }
}

export default AssembliesReducer
