import React, { Component } from 'react'
import ProgressiveImage from 'react-progressive-image'

import { API_WS_KEY, API_URL } from '../../helpers/api-config'


export default class ProductImage extends Component {
  render() {
    const { product } = this.props
    if (!product || !product.id) {
      return null
    }

    let image = {id: null}
    if (product.associations && product.associations.images.length > 0) {
      // Right now, we only care about the very first image, the other ones (if
      // any) ain't considered in the design.
      image = product.associations.images[0]
    }

    let path = [
      API_URL,
      'images/products',
      product.id,
      image.id,
    ]
    path = `${path.join('/')}?ws_key=${API_WS_KEY}`
    const placeholder = `${process.env.PUBLIC_URL}/images/weed_unit.jpg`

    return (
      <ProgressiveImage src={path} placeholder={placeholder}>
        {(src, loading) => (
          <img
            alt={`${product.name}`}
            src={src}
            style={{
              filter: loading ? 'blur(5px)' : 'none',
            }}
          />
        )}
      </ProgressiveImage>
    )
  }
}
