import React, { Component } from 'react'
import classNames from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'

import CartRow from './Row'
import TotalRow from './TotalRow'

export default class CartSummary extends Component {
  render() {
    const { loading } = this.props
    const cart = this.props.cart.data

    if (!cart.associations) {
      return null
    }

    const rows = cart.associations.cart_rows.cart_row || cart.associations.cart_rows
    if (!rows.length) {
      return (
        <div className="cart-summary">
          <p>Lo sentimos, la cesta está vacía.</p>
        </div>
      )
    }

    const classes = ['contents loader-wrapper']
    if (loading) {
      classes.push('loading')
    }

    return (
      <div className='cart-summary'>
        <p>RESUMEN DE RESERVA</p>
        <div className={classNames(classes)}>
          <div className='loader'>
            <ImpulseSpinner loading={loading} frontColor='#27beb7' />
          </div>
          <table>
            <thead>
              <tr>
                <th>Eliminar</th>
                <th>Varieadad</th>
                <th>Aportación</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((row, index) => (
                  <CartRow
                    {...this.props}
                    row={row}
                    index={index}
                    key={`cart-row-${index}`}
                  />
                ))
              }
            </tbody>
            <TotalRow {...this.props} />
          </table>
        </div>
      </div>
    )
  }
}
