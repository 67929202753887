import { connect } from 'react-redux'

import { setDeliverySchedule } from '../../actions/cart'
import ScheduleModalComponent from '../../components/Cart/ScheduleModal'

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setDeliverySchedule: (schedule) => dispatch(setDeliverySchedule(schedule)),
})

const ScheduleModal = connect(null, mapDispatchToProps)(ScheduleModalComponent)


export default ScheduleModal
