import React, { Component } from 'react'

import WitPage from '../Common/WitPage'
import DeliveryDate from './DeliveryDate'
import CartSummary from './CartSummary'
import Payment from './Payment'

import './index.css'

export default class Cart extends Component {
  render() {
    return (
      <WitPage {...this.props}>
        <div className="page-title">
          <p>CESTA</p>
        </div>

        <CartSummary {...this.props} />
        <DeliveryDate {...this.props} />
        <Payment {...this.props} />
      </WitPage>
    )
  }
}
