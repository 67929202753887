/* Generic API actions */

export const API_SET_AUTH_KEY = 'api/set:authKey'

/* Varieties */
export const GET_VARIETIES_LIST = 'GET_VARIETIES_LIST'

/* Products */
export const PRODUCTS_FETCH_ALL = 'products/fetch:all'
export const PRODUCTS_FETCH_ALL_SUCCESS = 'products/fetch:all::success'
export const PRODUCTS_FETCH_ALL_ERROR = 'products/fetch:all::error'
export const PRODUCTS_SET_ACTIVE = 'products/set:active'
export const PRODUCTS_RESET_ACTIVE = 'products/reset:active'
export const PRODUCTS_RESET_ACTIVE_BY_CAT = 'products/resetByCategory:active'
export const PRODUCTS_SET_ACTIVE_BY_REF = 'products/set:activeByRef'

/* Product features */
export const PF_FETCH_ALL = 'pf/fetch:all'
export const PF_FETCH_ALL_SUCCESS = 'pf/fetch:all::success'
export const PF_FETCH_ALL_ERRROR = 'pf/fetch:all::error'
export const PF_FETCH_ALL_VALUES = 'pf/fetchValues:all'
export const PF_FETCH_ALL_VALUES_SUCCESS = 'pf/fetchValues:all::success'
export const PF_FETCH_ALL_VALUES_ERRROR = 'pf/fetchValues:all::error'

export const PF_FETCH_ALL_WITH_VALUES = 'pf/fetch:allWithValues'
export const PF_FETCH_ALL_WITH_VALUES_SUCCESS = 'pf/fetch:allWithValues::success'

export const COMBINATIONS_FETCH = 'combinations/fetch'
export const COMBINATIONS_FETCH_SUCCESS = 'combinations/fetch::success'
export const COMBINATIONS_FETCH_ERROR = 'combinations/fetch::error'
export const COMBINATIONS_FETCH_ALL = 'combinations/fetch:all'
export const COMBINATIONS_FETCH_ALL_SUCCESS = 'combinations/fetch:all::success'
export const COMBINATIONS_FETCH_ALL_ERROR = 'combinations/fetch:all::error'

/* User */
export const USER_GET_LIMITS = 'user/get:limits'
export const USER_GET_LIMITS_SUCCESS = 'user/get:limits::success'
export const USER_GET_LIMITS_ERROR = 'user/get:limits::error'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const EDIT_USER_DETAILS = 'EDIT_USER_DETAILS'
export const GET_USER_HISTORY = 'GET_USER_HISTORY'
export const SET_USER_PREVISION = 'SET_USER_PREVISION'
export const SET_USER_HISTORY_ORDER_DELIVERY = 'SET_USER_HISTORY_ORDER_DELIVERY'
export const DELETE_USER_ORDER = 'DELETE_USER_ORDER'

/* User groups */
export const GROUPS_FETCH_ALL = 'groups/fetch:all'
export const GROUPS_FETCH_ALL_SUCCESS = 'groups/fetch:all::success'
export const GROUPS_FETCH_ALL_ERROR = 'groups/fetch:all::error'

/* Cart */
export const CART_FETCH = 'cart/fetch'
export const CART_FETCH_SUCCESS = 'cart/fetch::success'
export const CART_FETCH_ERROR = 'cart/fetch::error'
export const CART_CREATE = 'cart/create'
export const CART_CREATE_SUCCESS = 'cart/create::success'
export const CART_CREATE_ERROR = 'cart/create::error'
export const CART_PRODUCT_ADD = 'cart/product:add'
export const CART_PRODUCT_ADD_SUCCESS = 'cart/product:add::success'
export const CART_PRODUCT_ADD_ERROR = 'cart/product:add::error'
export const CART_CALCULATE = 'cart/calculate'
export const CART_CALCULATE_SUCCESS = 'cart/calculate::success'
export const CART_CALCULATE_ERROR = 'cart/calculate::error'
export const CART_PRODUCT_REMOVE = 'cart/product:remove'
export const CART_PRODUCT_REMOVE_SUCCESS = 'cart/product:remove::success'
export const CART_PRODUCT_REMOVE_ERROR = 'cart/product:remove::error'

export const CART_FIELD_SET = 'cart/field:set'
export const CART_DELIVERY_DATE_SET = 'cart/deliveryDate:set'
export const CART_DELIVERY_DATE_RESET = 'cart/deliveryDate:reset'
export const CART_DELIVERY_SCHEDULE_SET = 'cart/deliverySchedule:set'
export const CART_DELIVERY_SCHEDULE_RESET = 'cart/deliverySchedule:reset'
export const CART_RESET = 'cart/reset'
export const CART_UPDATE = 'cart/update'
export const CART_UPDATE_SUCCESS = 'cart/update::success'
export const CART_UPDATE_ERROR = 'cart/update::error'

/* Customers */
export const CUSTOMER_UPDATE = 'customers/update'
export const CUSTOMER_UPDATE_SUCCESS = 'customers/update::success'
export const CUSTOMER_UPDATE_ERROR = 'customers/update::error'
export const CUSTOMER_FETCH = 'customers/fetch'
export const CUSTOMER_FETCH_SUCCESS = 'customers/fetch::success'
export const CUSTOMER_FETCH_ERROR = 'customers/fetch::error'

export const CUSTOMER_FETCH_HISTORY = 'customers/fetch:history'
export const CUSTOMER_FETCH_HISTORY_SUCCESS = 'customers/fetch:history::success'
export const CUSTOMER_FETCH_HISTORY_ERROR = 'customers/fetch:history::error'

/* Schedules */
export const SCHEDULE_FETCH_TODAY = 'schedule/fetch:today'
export const SCHEDULE_FETCH_TODAY_SUCCESS = 'schedule/fetch:today::success'
export const SCHEDULE_FETCH_TODAY_ERROR = 'schedule/fetch:today::error'
export const SCHEDULE_FETCH_TOMORROW = 'schedule/fetch:tomorrow'
export const SCHEDULE_FETCH_TOMORROW_SUCCESS = 'schedule/fetch:tomorrow::success'
export const SCHEDULE_FETCH_TOMORROW_ERROR = 'schedule/fetch:tomorrow::error'


/* Orders */
export const ORDERS_FETCH_LATEST = 'orders/fetch:latest'
export const ORDERS_FETCH_LATEST_SUCCESS = 'orders/fetch:latest::success'
export const ORDERS_FETCH_LATEST_ERROR = 'orders/fetch:latest::error'
export const ORDERS_FETCH_ALL = 'orders/fetch:all'
export const ORDERS_FETCH_ALL_SUCCESS = 'orders/fetch:all::success'
export const ORDERS_FETCH_ALL_ERROR = 'orders/fetch:all::error'
export const ORDERS_FETCH_MONTH = 'orders/fetch:month'
export const ORDERS_FETCH_MONTH_SUCCESS = 'orders/fetch:month::success'
export const ORDERS_FETCH_MONTH_ERROR = 'orders/fetch:month::error'
export const ORDERS_FETCH_ORDERED_DATES = 'orders/fetch:dates'
export const ORDERS_FETCH_ORDERED_DATES_SUCCESS = 'orders/fetch:dates::success'
export const ORDERS_FETCH_ORDERED_DATES_ERROR = 'orders/fetch:dates::error'
export const ORDERS_CREATE = 'orders/create'
export const ORDERS_CREATE_SUCCESS = 'orders/create::success'
export const ORDERS_CREATE_ERROR = 'orders/create::error'
export const ORDERS_CHANGE_DELIVERY = 'orders/update:delivery'
export const ORDERS_CHANGE_DELIVERY_SUCCESS = 'orders/update:delivery::success'
export const ORDERS_CHANGE_DELIVERY_ERROR = 'orders/update:delivery::error'
export const ORDERS_CANCEL = 'orders/cancel'
export const ORDERS_CANCEL_SUCCESS = 'orders/cancel::success'
export const ORDERS_CANCEL_ERROR = 'orders/cancel::error'

export const GET_CART_BY_USER = 'GET_CART_BY_USER'
export const ADD_CART_PRODUCT = 'ADD_CART_PRODUCT'
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT'
export const CREATE_NEW_CART = 'CREATE_NEW_CART'
export const DELETE_CURRENT_CART = 'DELETE_CURRENT_CART'
export const RESET_CURRENT_CART = 'RESET_CURRENT_CART'
export const SET_CART_DELIVERY_DATE = 'SET_CART_DELIVERY_DATE'

/* Product features */
export const GET_PRODUCT_FEATURE_TASTE = 'GET_PRODUCT_FEATURE_TASTE'
export const GET_PRODUCT_FEATURE_PREDOMINANCE = 'GET_PRODUCT_FEATURE_PREDOMINANCE'

export const STOCKS_FETCH = 'stocks/fetch'
export const STOCKS_FETCH_SUCCESS = 'stocks/fetch::success'
export const STOCKS_FETCH_ERROR = 'stocks/fetch::error'

/* CMS */
export const CMS_FETCH_ALL = 'cms/fetch:all'
export const CMS_FETCH_ALL_ERROR = 'cms/fetch:all::error'
export const CMS_FETCH_ALL_SUCCESS = 'cms/fetch:all::success'
export const CMS_MARK_AS_READ = 'cms/markAsRead'

/* Assembly */

export const ASSEMBLY_FETCH = 'assembly/fetch'
export const ASSEMBLY_FETCH_ERROR = 'assembly/fetch::error'
export const ASSEMBLY_FETCH_SUCCESS = 'assembly/fetch::success'

export const ASSEMBLY_VOTE = 'assembly/vote'
export const ASSEMBLY_VOTE_ERROR = 'assembly/vote::error'
export const ASSEMBLY_VOTE_SUCCESS = 'assembly/vote::success'

export const GET_ASAMBLEA_ACTAS = 'GET_ASAMBLEA_ACTAS'
export const GET_ACTIVE_ASAMBLEA_ACTAS = 'GET_ACTIVE_ASAMBLEA_ACTAS'


export const CATEGORIES_FETCH = 'categories/fetch'
export const CATEGORIES_FETCH_ERROR = 'categories/fetch::error'
export const CATEGORIES_FETCH_SUCCESS = 'categories/fetch::success'
export const CATEGORIES_SET_ACTIVE = 'categories/set:active'
export const CATEGORIES_SET_ACTIVE_BY_REF = 'categories/set:activeByRef'
