import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import ReactGA from 'react-ga'
import ReduxToastr from 'react-redux-toastr'
import store2 from 'store2'
import { hotjar } from 'react-hotjar'

import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION, GA_TRACK_ID } from './constants/constants'
import * as serviceWorker from './serviceWorker'
import App from './App.js'
import createStore, { history } from './store'

import 'pace-js/themes/green/pace-theme-flash.css'

const store = createStore()

ReactGA.initialize(GA_TRACK_ID)

hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION)

history.listen((location) => {
  let gaData = {
    page: location.pathname + location.search,
  }
  // GA user details
  if (store2.has('_userId') && store2.get('_userId') !== 0) {
    gaData = {
      ...gaData,
      userId: store2.get('_userId'),
      dimension1: store2.get('_userId'),
    }
  }

  ReactGA.set(gaData)
  // GA page details
  ReactGA.pageview(location.pathname + location.search)
})

ReactDOM.render(
  <Provider store={store}>
    <div>
      <Router history={history}>
        <App />
      </Router>
      <ReduxToastr
        timeOut={5000}
        removeOnHoverTimeout={5000}
        position='top-right'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar
        closeOnToastrClick />
    </div>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
