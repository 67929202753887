import Categories from '../components/Categories'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
  categories: state.categories.menuCategories,
  loading: state.categories.loading,
  loaded: state.categories.loading,
  errors: state.categories.loading,
  customer: state.customers.customer,
  cms: state.cms,
})

const CategoriesContainer = connect(mapStateToProps)(Categories)


export default CategoriesContainer
