import {
  GET_USER_DETAILS,
  EDIT_USER_DETAILS,
  GET_USER_HISTORY,
  SET_USER_PREVISION,
  SET_USER_HISTORY_ORDER_DELIVERY,
  USER_GET_LIMITS_SUCCESS
} from "../constants/action-types";

const initialState = {
  userDetails: {},
  userHistory: [],
  sessionCheck: false,
  limits: {},
};

const UserDetailsReducer = function (state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return Object.assign({}, state, {
        userDetails: action.userDetails
      });
    case EDIT_USER_DETAILS:
      return Object.assign({}, state, {
        userDetails: action.userDetails
      });
    case GET_USER_HISTORY:
      return Object.assign({}, state, {
        userHistory: action.userHistory
      });
    case SET_USER_PREVISION:
      return Object.assign({}, state, {
        userDetails: action.userDetails
      });
    case SET_USER_HISTORY_ORDER_DELIVERY:
      return Object.assign({}, state, {
        userHistory: action.userHistory
      });

    case USER_GET_LIMITS_SUCCESS:
      return {
        ...state,
        limits: action.limits,
      }

    default:
      return state;
  }
};

export default UserDetailsReducer;
