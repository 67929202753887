import xml2js from 'xml2js'
import { DATE_FORMAT } from './constants';
import moment from 'moment';

export const arrayRemove = (arr, ...args) => {
  var what, a = args, L = a.length, ax;
  while (L > 0 && arr.length) {
      what = a[--L];
      while ((ax= arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
      }
  }
  return arr;
}

export const getNextAndPrevProducts = (order, products, current) => {
  return {
    prev: getPrevProduct(order, products, current),
    next: getNextProduct(order, products, current),
  }
}

export const getPrevProduct = (order, products, current) => {
  for (let i = current - 1; i >= 0; i--) {
    const product = products[order[i].id]
    if (product) {
      return product
    }
  }
  // Arrived to zero, get prevProduct from latest index
  return getPrevProduct(order, products, order.length)
}

export const getNextProduct = (order, products, current) => {
  for (let i = current + 1; i < order.length; i++) {
    const product = products[order[i].id]
    if (product) {
      return product
    }
  }
  return getNextProduct(order, products, -1)
}


export const getNextAndPrevIndexes = (items, currentIndex) => {
  let next = currentIndex + 1
  let prev = currentIndex - 1

  if (next >= items.length) {
    next = 0
  }

  if (prev < 0) {
    prev = items.length - 1
  }

  return {
    next,
    prev,
  }
}

export const stripHtmlFromText = (html) => {
   var tmp = document.createElement('div');
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || '';
}

export const json2Xml = (json) => {
  const builder = new xml2js.Builder()

  return builder.buildObject({
    prestashop: json,
  })
}

export const groupBy = (items, groupBy = 'id_feature') => {
  const grouped = {}

  items.forEach((item) => {
    if (typeof grouped[item[groupBy]] === 'undefined') {
      grouped[item[groupBy]] = []
    }

    grouped[item[groupBy]].push(item)
  })

  return grouped
}

export const getBestDiscount = (ids, groups) => {
  let discount = 0
  // Ensure all passed ids are numeric values
  ids = ids.map((id) => toInteger(id))

  groups.forEach(({id, reduction}) => {
    // Ensure current group id is numeric too
    if (ids.indexOf(toInteger(id)) !== -1) {
      const reduct = parseFloat(reduction)
      if (reduct > discount) {
        discount = reduct
      }
    }
  })

  return discount
}

export const toInteger = (number) => parseInt(number, 10)

export const number = (number, decimals = 2) => Number(number).toFixed(decimals)
export const formatWeight = (weight) => number(weight) + 'gr'
export const formatPrice = (weight) => number(weight) + '€'


export const today = () => moment(new Date()).format(DATE_FORMAT)
export const tomorrow = () => moment(new Date()).add(1, 'days').format(DATE_FORMAT)

export const getFirstTimeInSchedule = (schedule) => {
  const [first] = schedule.split("-")
  return first
}

export const getLastTimeInSchedule = (schedule) => {
  const [,last] = schedule.split("-")
  return last
}

export const convertToValidDate = (date) => {
  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

export const convertToValidDateTime = (date, time) => {
  return `${convertToValidDate(date)} ${time}`
}

export const isDateBeforeRightNow = (date) => {
  const tod = moment(new Date())
  return moment(date).isBefore(tod)
}


export const isInactiveAccount = (customer) => {
  const signup = new Date(customer.soci_data)
  const inactive = new Date('1999-01-01')

  return signup.getTime() - inactive.getTime() === 0
}

