import API from '../helpers/Api'
import Axios from 'axios'
import xml2js from 'xml2js'
import store from 'store2'

import {
	GET_USER_DETAILS,
	EDIT_USER_DETAILS,
	GET_USER_HISTORY,
	SET_USER_PREVISION,
	SET_USER_HISTORY_ORDER_DELIVERY,
	DELETE_USER_ORDER,
  USER_GET_LIMITS,
  USER_GET_LIMITS_SUCCESS,
  USER_GET_LIMITS_ERROR,
  CUSTOMER_FETCH_SUCCESS
} from '../constants/action-types'
import { API_URL_FORMS } from '../helpers/api-config'

import { fetchAll as fetchAllGroups } from './groups'
import { fetchLatest } from './orders'
import { setAuthKey } from './api'
import { fetch } from './cart'
import { customerFetchSuccess } from './customers'

// Action creators
export function getUserDetails(userDetails) {
	return {
		type: GET_USER_DETAILS,
		userDetails
	}
}

export function editUserDetails(userDetails) {
	return {
		type: EDIT_USER_DETAILS,
		userDetails
	}
}

export function getUserHistoryDetails(userHistory) {
	return {
		type: GET_USER_HISTORY,
		userHistory
	}
}

export function setUserPrevisionDetails(userDetails) {
	return {
		type: SET_USER_PREVISION,
		userDetails
	}
}

export function setOrderDeliveryDate(userHistory) {
	return {
		type: SET_USER_HISTORY_ORDER_DELIVERY,
		userHistory
	}
}

export function cancelOrderData(userHistory) {
	return {
		type: DELETE_USER_ORDER,
		userHistory
	}
}

export const getLimits = (customer) => (dispatch) => {
  dispatch({
    type: USER_GET_LIMITS,
  })

  return Axios.get(`${API_URL_FORMS}`, {
      params: {
        action: 'limites_consumo',
        id_customer: customer,
      }
    })
    .then(({data}) => dispatch({
      type: USER_GET_LIMITS_SUCCESS,
      limits: data,
    }))
    .catch((error) => dispatch({
      type: USER_GET_LIMITS_ERROR,
      error,
    }))
}

// Async action creators
export const getUser = (userId) => (dispatch) => {
  const emptyResponse = {}

  const key = store.get('_key')

  dispatch(setAuthKey(key))
  dispatch(getLimits(userId))
  dispatch(fetchAllGroups())

  return API.get('/customers/' + userId, {params: {key}})
    .then(
      function(res) {
        let userCurrent = res.data.customer
        API.get('/addresses', {
          params: {
            key,
            'filter[id_customer]': userId,
            'filter[deleted]': false,
          },
        })
          .then(res => {
            let customerAddressId = res.data.addresses[0].id
            API.get('/addresses/' + customerAddressId, {params: {key}})
              .then(res => {
                userCurrent.addresses = res.data.address

                dispatch(fetchLatest(userCurrent)).then(({latest}) => {
                  // Obtain the latest cart
                  return dispatch(fetch(userCurrent, latest.id_cart))
                })
                dispatch(customerFetchSuccess(userCurrent))
                dispatch(getUserDetails(userCurrent))
                dispatch({
                  type: CUSTOMER_FETCH_SUCCESS,
                  customer: userCurrent,
                })
                return userCurrent
              })
          })
      },
      function(err) {
        return emptyResponse
      }
    )
    .catch(
      function(err) {
        return emptyResponse
      }
    )
}

export function editUser(userId, userDetails, userDetailsAddress, dispatch) {
	return function() {
		let builder = new xml2js.Builder()
		let userDetailsXML = builder.buildObject({
			prestashop: {
				customer: userDetails
			}
		})
		let userDetailsAddressXML = builder.buildObject({
			prestashop: {
				address: userDetailsAddress
			}
    })

    const key = store.get('_key')

		const emptyResponse = {}
		return API.put('/customers/' + userId, userDetailsXML, {params: {key}})
			.then(
				function(res) {
					let newUserDetails = res.data.customer
					return API.put('/addresses/' + userDetailsAddress.id, userDetailsAddressXML, {params: {key}})
						.then(
							function(res) {
								newUserDetails.addresses = res.data.address

								dispatch(editUserDetails(newUserDetails))
								return newUserDetails
							},
							function(err) {
								if (err.response) {
									return err.response.data
								}
								return emptyResponse
							}
						)
				},
				function(err) {
					if (err.response) {
						return err.response.data
					}
					return emptyResponse
				}
			)
			.catch(
				function(err) {
					return emptyResponse
				}
			)
	}
}

export function createUser(userDetails, dispatch) {
	return function() {
		// phone is sent with address request
		let userPhone = userDetails.phone
		delete userDetails.phone

		let builder = new xml2js.Builder()
		let userDetailsXML = builder.buildObject({
			prestashop: {
				customer: userDetails
			}
    })
    const key = store.get('_key')

		const emptyResponse = {}
		return API.post('/customers', userDetailsXML, {params: {key}})
			.then(
				function(res) {
					let userId = res.data.customer.id
					let userDetailsAddressXML = builder.buildObject({
						prestashop: {
							address: {
								id_customer: userId,
								id_country: 6,
								id_state: 334,
								alias: 'Reparto',
								lastname: userDetails.lastname,
								firstname: userDetails.firstname,
								address1: 'Pendiente de usuario',
								postcode: '00000',
								city: 'Barcelona',
								phone: userPhone,
								dni: '00000001A'
							}
						}
					})
					return API.post('/addresses', userDetailsAddressXML, {params: {key}})
						.then(
							function(res) {
								return res.data.address
							},
							function(err) {
								// delete recently created user
								API.delete('/customers/' + userId, {params: {key}})
								if (err.response) {
									return err.response.data
								}
								return emptyResponse
							}
						)
				},
				function(err) {
					if (err.response) {
						return err.response.data
					}
					return emptyResponse
				}
			)
			.catch(
				function(err) {
					return emptyResponse
				}
			)
	}
}

export function getUserHistory(userId, dispatch) {
	return function() {
    const emptyResponse = {}
    const key = store.get('_key')

		return API.get('/orders?filter[id_customer]=' + userId, {params: {key}})
			.then(
				function(res) {
					if (res.data.orders && res.data.orders.length > 0) {
						let userOrders = []
						let totalOrders = res.data.orders.length
						res.data.orders.forEach(function(order, index) {
							API.get('/orders/' + order.id, {params: {key}})
								.then(res => {
									if (Number(res.data.order.valid)) {
										userOrders.push(res.data.order)
									}

									userOrders = userOrders.sort(function(a, b) {
										return b.id - a.id
									})

									totalOrders--

									if (totalOrders === 0) {
										dispatch(getUserHistoryDetails(userOrders))
										return userOrders
									}
								})
						})
					}
				},
				function(err) {
					return emptyResponse
				}
			)
			.catch(
				function(err) {
					return emptyResponse
				}
			)
	}
}

export function setUserPrevision(userId, userDetails, previsionQty, dispatch) {
	return function() {
		userDetails.previsio_consum = previsionQty

		let builder = new xml2js.Builder()
		let userDetailsXML = builder.buildObject({
			prestashop: {
				customer: userDetails
			}
    })
    const key = store.get('_key')

		const emptyResponse = {}
		return API.put('/customers/' + userId, userDetailsXML, {params: {key}})
			.then(
				function(res) {
					let newUserDetails = res.data.customer
					return API.get('/addresses/' + userDetails.addresses.id, {params: {key}})
						.then(
							function(res) {
								newUserDetails.addresses = res.data.address

								dispatch(setUserPrevisionDetails(newUserDetails))
								return newUserDetails
							},
							function(err) {
								if (err.response) {
									return err.response.data
								}
								return emptyResponse
							}
						)
				},
				function(err) {
					return emptyResponse
				}
			)
			.catch(
				function(err) {
					return emptyResponse
				}
			)
	}
}

export function setOrderDelivery(order, deliveryDay, deliveryHour, userId, dispatch) {
	return function() {
		let orderRows = order.associations.order_rows
		order.fecha_entrega = deliveryDay
		order.hora_entrega = deliveryHour
		order.associations.order_rows = {
			order_row: orderRows
		}

		let builder = new xml2js.Builder()
		let orderXML = builder.buildObject({
			prestashop: {
				order: order
			}
    })
    const key = store.get('_key')

		const emptyResponse = {}
		return API.put('/orders/' + order.id, orderXML, {params: {key}})
			.then(
				function(res) {
					// update History
					API.get('/orders?filter[id_customer]=' + userId, {params: {key}})
						.then(res => {
							if (res.data.orders && res.data.orders.length > 0) {
								let userOrders = []
								let totalOrders = res.data.orders.length
								res.data.orders.forEach(function(order, index) {
									API.get('/orders/' + order.id, {params: {key}})
										.then(res => {
											if (Number(res.data.order.valid)) {
												userOrders.push(res.data.order)
											}

											userOrders = userOrders.sort(function(a, b) {
												return b.id - a.id
											})

											totalOrders--

											if (totalOrders === 0) {
												dispatch(setOrderDeliveryDate(userOrders))
												return userOrders
											}
										})
								})
							}
						})
				},
				function(err) {
					if (err.response) {
						return err.response.data
					}
					return emptyResponse
				}
			)
			.catch(function(err) {
				if (err.response) {
					return err.response.data
				}
				return emptyResponse
			})
	}
}

export function cancelOrder(order, userId, dispatch) {
	return function() {
		let orderRows = order.associations.order_rows
		order.current_state = 6
		order.valid = 0
		order.associations.order_rows = {
			order_row: orderRows
		}

		let builder = new xml2js.Builder()
		let orderXML = builder.buildObject({
			prestashop: {
				order: order
			}
    })
    const key = store.get('_key')

		const emptyResponse = {}
		return API.put('/orders/' + order.id, orderXML, {params: {key}})
			.then(
				function(res) {
					// update History
					API.get('/orders?filter[id_customer]=' + userId, {params: {key}})
						.then(res => {
							if (res.data.orders && res.data.orders.length > 0) {
								let userOrders = []
								let totalOrders = res.data.orders.length
								res.data.orders.forEach(function(order, index) {
									API.get('/orders/' + order.id, {params: {key}})
										.then(res => {
											if (Number(res.data.order.valid)) {
												userOrders.push(res.data.order)
											}

											userOrders = userOrders.sort(function(a, b) {
												return b.id - a.id
											})

											totalOrders--

											if (totalOrders === 0) {
												dispatch(setOrderDeliveryDate(userOrders))
												return userOrders
											}
										})
								})
							}
						})
				},
				function(err) {
					if (err.response) {
						return err.response.data
					}
					return emptyResponse
				}
			)
			.catch(function(err) {
				if (err.response) {
					return err.response.data
				}
				return emptyResponse
			})
	}
}
