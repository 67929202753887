export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_SQL = 'YYYY-MM-DD'
export const DATE_FORMAT_MONTH = 'YYYY-MM'

export const CANCELED_ORDER_STATE = 6
export const ONTHEWAY_ORDER_STATE = 4
export const DELIVERED_ORDER_STATE = 5
export const PENDING_ORDER_STATE = 2
export const CARDPENDING_ORDER_STATE = 11

export const ENDORSE_USERS_GROUP = 6
export const FEATURE_PREDOMINANCE_ID = 8
export const FEATURE_TASTE_ID = 7
export const LATEST_TIME_ALLOWED_FOR_CHANGES = 19
export const LATEST_TIME_ALLOWED_ORDER_CHANGES = LATEST_TIME_ALLOWED_FOR_CHANGES + ':00:00'

export const NIF_IMAGE_MAX_FILESIZE = 8 * 1024 * 1024
export const NIF_IMAGE_ALLOWED_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png']

export const CURRENCY_ID = 1
export const LANG_ID = 1
export const WIT_CARRIER_ID = 6
export const DAILY_WEIGHT_LIMIT = 15
// The customers' fee is a product, so here's its id hardcoded..
export const FEE_PRODUCT_ID = 32

export const MINIMUM_FEE = 40
export const MINIMUM_FEE_COLLAB = 35

export const HOTJAR_SNIPPET_VERSION = 6
let hotjarId = 1863663
let gaTrackId = 'UA-141184845-1'

export const ZENDESK_WIDGET_ID = 'ef1d7304-5b05-4ac2-a264-3f36ed898447'

// Ensure development hotjarid is set on develop branch deploys
if (process.env.CI && process.env.CI_COMMIT_REF_NAME === 'develop') {
  hotjarId = 1863546
}

export const HOTJAR_ID = hotjarId
export const GA_TRACK_ID = gaTrackId

export const CART_MODIFIED = 'Nos hemos quedado sin stock de alguno de los'
  + ' productos que habías añadido. Revisa el pedido y vuelve a la carta a'
  + ' añadir algo si lo crees conveniente.'
