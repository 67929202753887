import { connect } from 'react-redux'

import Variety from '../components/Product'

const mapStateToProps = (state) => ({
  categories: state.categories,
  cms: state.cms,
  combinations: state.combinations,
  cart: state.cart,
  currentCart: state.CartReducer.currentCart,
  customer: state.customers.customer,
  errors: state.categories.loading,
  loading: state.categories.loading,
  loaded: state.categories.loading,
  products: state.products,
  productFeatures: state.productFeatures,
  stocks: state.stocks,
  user: state.UserDetailsReducer.userDetails || {},
})

const ProductsContainer = connect(mapStateToProps)(Variety)


export default ProductsContainer
