import React, { Component, Fragment } from 'react'
import Link from '../../Common/Link'

export default class Context extends Component {
	render() {
		return (
			<Fragment>
				<h4>Bienvenida</h4>
				<div className="welcome">
					Hola!<br />
					Has sido avalado por un amigo para poder ser socio de WIT, un CSC de Barcelona donde puedes retirar a través de una web privada desde casa, el ámbito más privado que existe.<br />
					En WIT somos pocos, bien avenidos, y todo lo decidimos en la Asamblea mensual virtual a través de la web para que todos seamos partícipes del funcionamiento de la asociación.<br />
					Con este curso conocerás nuestros objetivos, estatutos y los derechos y obligaciones que adquirirás una vez te hayas hecho socio.<br />
					Empecemos,
				</div>

				<h4>Contexto</h4>
				<div className="section">
					<h6>Contexto Internacional y Nacional</h6>
					La convención única sobre estupefacientes es quien clasifica en unas listas todas las substancias psicotrópicas ilegales del mundo, donde encontramos el Cannabis. Desde entonces el cultivo, comercio y consumo del cannabis pasa a ser ilegal a nivel internacional.
					<ul>
						<li>Lista I: Sustancias con propiedades adictivas que presentan un alto riesgo de abuso.</li>
						<li>Lista IV: Las substancias más peligrosas que ya figuran en la lista I, especialmente perjudiciales y de valor médico o terapéutico muy limitado.</li>
					</ul>
					El estado español firmó la convención única sobre estupefacientes y en el año 1967 empezó a elaborar normativas con el fin de dar respuesta a los objetivos marcados por las Naciones Unidas.<br />
					A día de hoy tenemos las siguientes normativas que regulan de manera sancionadora algún aspecto de nuestra relación con el cannabis.*
					<ul>
						<li>
							<Link href="https://www.boe.es/buscar/act.php?id=BOE-A-2015-3442&p=20150331&tn=2" external>Ley Orgánica 4/2015</Link>, de 30 de marzo, de protección de la seguridad ciudadana.
						</li>
						<li>
							<Link href="https://www.boe.es/buscar/pdf/1995/BOE-A-1995-25444-consolidado.pdf" external>Ley Orgánica 10/1995</Link>, de 23 de noviembre, del Código Penal.
						</li>
						<li>
							<Link href="https://www.boe.es/buscar/act.php?id=BOE-A-2015-11722" external>Real Decreto Legislativo 6/2015</Link>, de 30 de octubre, por el que se aprueba el texto refundido de la Ley sobre Tráfico, Circulación de Vehículos a Motor y Seguridad Vial.</li>
					</ul>
					<small>*Si queréis información más específica sobre las normativas podéis consultar los enlaces.</small>

					<h6>Normativas reguladoras de las asociaciones</h6>
					Te preguntarás cómo puede ser que existan asociaciones cannábicas con todas las normativas que hay que van en contra de nuestra existencia, pues la respuesta es simple; Existe una Ley de asociaciones (<Link href="https://www.boe.es/buscar/doc.php?id=BOE-A-2002-5852" external>Ley Orgánica 1/2002, de 22 de marzo, reguladora del Derecho de Asociación</Link>) que regula a todo tipo de asociaciones y que regula los aspectos más generales de éstas.<br />
					Además, en 2017 se promovió una regulación entre federaciones y el Govern de Catalunya con la ILP La Rosa Verda que se llegó a aprobar en el Parlament pero revocado por el Tribunal Constitucional en 2018, no por ser ilegal que los consumidores nos unamos en asociaciones para hacer valer nuestros derechos fundamentales sino por ser la materia regulada competencia exclusiva del Estado.<br />
					Pese a ello, WIT apuesta totalmente por la creación de una regulación específica para asociaciones cannábicas.

					<h6>Alteridad</h6>
					La Alteridad siempre implica un “yo” o un “nosotros” frente a un “otros”, viene de alternar los pensamientos del uno por los del otro. Resumiendo y para que se entienda fácilmente, sería cambiar la propia perspectiva por la del otro.<br />
					En el caso de una asociación cannábica, vendría a ser que unas personas hacen y deciden por otros, en vez de ser una organización autogestionada por todas las personas miembros, y eso es justo lo contrario de lo que es  WIT, donde todas y todos formamos parte, decidimos conjunta y democráticamente sobre todos los aspectos que nos incumben de forma asamblearia. Nadie, está por encima de los demás.

					<h6>El uso compartido</h6>
					Hay una serie de conductas alrededor del uso de cannabis que están despenalizadas, significando que no tienen consecuencias penales y en vocabulario legal se las califica de conductas atípicas.<br />
					<u>Conductas atípicas: Aquellas conductas que no comportan peligro para la Salud Pública de terceras personas ajenas a un grupo de conocidos.</u><br />
					Las que nos interesan sobre todo en WIT son “El uso individual, compartido y la compra y distribución entre iguales”
					<ul>
						<li>El uso individual: Consumir cannabis o cualquier sustancia en el ámbito privado, no es motivo de sanción en ningún caso.</li>
						<li>El uso compartido: Es el acto de consumir cannabis entre un grupo de personas conocidas, previamente consumidoras y llevándolo a cabo en ámbito privado.</li>
						<li>Compra mancomunada y distribución entre iguales: Para poder hacer el acto de consumo, este grupo de “personas dependientes del cannabis” conocidos nos procuramos el cannabis a través de un fondo económico común.</li>
					</ul>
				</div>

				<h4>¿Qué es y cómo funciona WIT?</h4>
				<div className="section">
					<h6>Definición</h6>
					WIT somos un grupo de individuos constituidos como asociación sin ánimo de lucro de ámbito privado, por lo cual solo podemos acceder a ella personas mayores de 21 años avaladas por un amigo que ya sea miembro con anterioridad.<br /><br />
					A los socios de WIT nos gusta disfrutar del cannabis responsablemente en la comodidad de nuestro domicilio. Cuando entras a formar parte de WIT, nos aseguramos de que ya seas usuario/a de cannabis, ya sea de forma lúdica o terapéutica.<br /><br />
					A través de nuestro programa de autoabastecimiento garantizamos la calidad del cannabis distribuido y su control.<br /><br />
					Nuestros objetivos son:
					<ul>
						<li>La reducción de riesgos y daños asociados al uso de la substancia. Por eso que contamos con programas que pretenden guiarte hacia un consumo responsable.</li>
						<li>Desarrollar nuestra actividad respetando la legalidad vigente promocionando un debate social que abogue por la regulación integral del cannabis.</li>
					</ul>

					<h6>No tenemos ánimo de lucro</h6>
					La Ley de Asociaciones limita su ámbito a las asociaciones sin fines de lucro.<br />
					Asociación WIT carece de ánimo de lucro, cada euro que se genera de las cuotas anuales y las cuotas variables se reinvierte en los gastos de la asociación.<br />
					La persona encargada de administración se encarga de revisar el estado de cuentas, para que todas las personas miembros puedan consultarlas y para que estén al día frente a una posible inspección.<br />
					La persona encargada de tesorería se encarga de tener al día los ficheros de cuentas, ingresos y gastos, exponer el estado de cuentas de manera mensual a la Asamblea.

					<h6>Consumo en el domicilio</h6>
					WIT es sinónimo de consumo en el domicilio, como socio/a es preferible disfrutar del cannabis en casa, fuera del alcance de terceras personas y de acuerdo con la legalidad actual vigente. A través del sistema de reparto de cuotas entre miembros y el sistema de auto-envío podremos acceder a ellas de manera gradual, segura y totalmente privada en el ámbito de nuestro domicilio.<br /><br />
					De este modo el cannabis se mantiene en un círculo cerrado de socios en un espacio privado y nos aseguramos que no llegue a terceras personas. En WIT solo pueden retirar los socios, en el ámbito de su domicilio privado presentando su DNI/NIE/Pasaporte.

					<h6>Programa de autoabastecimiento</h6>
					El objetivo del programa de autoabastecimiento al que estás a punto de incorporarte es evitar los riesgos de los mercados informales, garantizando la calidad y salubridad del cannabis, sin vulnerar las actuales disposiciones legales aplicables.<br /><br />
					Para participar en el programa de autoabastecimiento es imprescindible que hayas plazas libres y que seas previo/a consumidor/a de cannabis. La Asamblea General será la encargada de validar la lista de participantes cada mes.<br />
					A diferencia de otras asociaciones, en WIT el programa es mensual aprobado por asamblea y restringido a un listado cerrado de socios. Cada mes la asamblea general bajo demanda de los participantes nos auto-abastecemos de la cantidad exacta de cannabis y derivados para el mes siguiente.<br /><br />
					Al acabar el curso deberás fijar tu primera previsión de consumo, mediante la cual la Asamblea sabrá la cantidad necesaria que necesita cada socio. La previsión de consumo se podrá modificar mediante la web.<br /><br />
					Las personas miembros nos autoabastecemos conjunta y mancomunadamente, y solo las personas miembros que hayan actualizado su previsión de consumo y las solicitudes de las cuales hayan sido aprobadas por Asamblea General podrán acceder al programa. La previsión máxima de cada persona miembro no podrá exceder los 60g mensuales y los 3g diarios. El máximo que se podrá retirar de una sola vez es de 15g y en tal caso deberemos esperar 5 días para volver a retirar.

					<h6>El transporte</h6>
					Con WIT podemos hacer la retirada de nuestras cuotas en casa haciendo una reserva previa a través del portal web. Estos envíos son considerados auto-envíos, de esta manera estaremos retirando la parte del cannabis que ya nos pertenece, indicada en la previsión de consumo.<br />
					Los auto-envíos se hacen en un paquete cerrado, nominativo y protegido por el artículo 18 C.E ya que contienen información confidencial. Puesto que estamos retirando algo que ya es nuestro, seremos el remitente y el destinatario de dichos envíos. Solo se hacen entregas en el área de Barcelona.<br />
					Haz clic aquí para ver el <a href="#toggle-map" onClick={this.toggleModalMap}>mapa de las áreas operativas</a>.

					<h6>Asamblea virtual mensual</h6>
					La Asamblea General se celebrará durante la última semana del mes mediante el foro online. Para participar activamente solo tenemos que entrar en el apartado Asamblea de tu usuario en la web. En las asambleas hablamos de todos los temas que afectan a WIT, y pueden ser propuestos por cualquier persona miembro. En WIT todos tenemos el mismo poder de decisión.<br />
					Si no participas no pasará nada, pero acatarás las decisiones de la Asamblea y perderás la oportunidad de ayudar a mejorar el proyecto. Si estás 3 meses sin participar de la asamblea pasarás a ser un socio no participante hasta que des tu aprobación o desacuerdo con la misma.

					<h6>Máximo de personas participantes</h6>
					Con el fin de garantizar la viabilidad de la asociación, y sobretodo la protección  efectiva de nuestros derechos fundamentales, en especial el derecho a la Salud, el máximo de personas que pueden participar en el programa de autoaprovisionamiento es de 500.<br /><br />
					Serás un/a socio/a participante si estás al corriente de tus cuotas y has participado en las Asambleas de los últimos 3 meses. Cada trimestre se validará el listado de personas socias participantes en la Asamblea General.<br />
					Si no participas de la asamblea virtual,  serás considerado/a no participante hasta que lo hagas y siempre y cuando haya plazas disponibles y la Asamblea General haya validado dicha actualización.

					<h6>Reservas, aportaciones y retiradas</h6>
					<ul>
						<li><u>Reservas</u>: ​Las reservas se hacen a través de la web, en cada reserva seleccionamos la variedad, la cantidad, la fecha de entrega y la franja horaria de la misma.</li>
						<li><u>Aportaciones</u>: Las aportaciones las harás desde el TPV virtual de la web o en metálico en tu domicilio. La aportación mínima será de 20€ (incluye el transporte). Las aportaciones son cuotas, no compras.</li>
						<li><u>Retiradas</u>:​ Las retiradas siempre serán nominativas, con exhibición del DNI o análogo, y solo las podrá efectuar el propio socio, nunca una tercera persona. Se efectuarán en tu domicilio durante la franja horaria que hayas seleccionado previamente a través de la reserva web, es necesario que estés en casa durante toda la franja horaria seleccionada. Las ausencias incomunicadas nos perjudican a todos e implica cubrir el coste del transporte extra.</li>
					</ul>

					<h6>Sede Social: Punto de Información</h6>
					La sede social de WIT tiene una función de carácter meramente informativo. En la sede no se pueden hacer retiradas ni consumir.

					<h6>Programa de reducción de riesgos y daños</h6>
					En WIT desarrollamos un programa de reducción de riesgos para ayudar a enfocar y regular nuestro consumo de forma responsable y con el fin de evitar usos problemáticos y garantizar la calidad y salubridad del producto, evitando con ello los riesgos de adulteración y contaminación del cánnabis adquirido en el mercado negro que carece de cualquier tipo de control de calidad y presenta muchas veces pesticidas, metales pesados o contaminación microbiológica. Porqué uno de nuestros máximos objetivos es el acceso al cannabis de forma segura y salubre, por que hacer un buen uso de la substancia es sinónimo de salud y calidad de vida.<br />
					Todos somos conscientes que el cannabis no es una sustancia inofensiva y nuestro objetivo es tener una buena relación de manera segura con la sustancia.<br /><br />
					Es por ello que te proporcionaremos información sobre el cannabis científica y contrastada, información amplia y detallada sobre las variedades disponibles en la carta, variedades y productos de CBD, información sobre vías de consumo alternativas a la combustión, seguimiento y monitorización de tu consumo, acceso a formularios de motivación del mismo, formaciones y capacitaciones a través de entidades especializadas y si lo necesitas asesoramiento específico sobre tu consumo así como analíticas periódicas con el fin de comprobar el contenido en cannabinoides, pesticidas y metales pesados.

					<h6>Programa terapéutico</h6>
					Ofrecemos un programa específico para personas que utilizan el cannabis por motivos terapéuticos o como paliativo. Si tienes una enfermedad diagnosticada, (recogida en la IACM) y siempre bajo prescripción médica, podrás auto-abastecerte sin necesitar el aval de una persona ya miembro de WIT.<br />
					Si es tu caso, gozarás de un 10% de descuento en todas las cuotas y aportaciones, tendrás un asesoramiento personalizado con los doctores de Kalapa Clínics, contarás con información detallada sobre las variedades y sus efectos terapéuticos, así como acceso a productos específicos para este tipo de uso e información sobre vías de consumo alternativas a la combustión.

					{/*<h6>Organigrama</h6>
					<table>
						<tbody>
							<tr>
								<td>&nbsp;</td>
								<td>Definición</td>
								<td>¿Quién la forma?</td>
								<td>Funciones</td>
							</tr>
							<tr>
								<td>ASAMBLEA GENERAL</td>
								<td>Es el órgano de máxima de decisión de WIT</td>
								<td>Todas los socios de WIT que estén al corriente de su cuota anual</td>
								<td>Debatir, aprobar o revocar todas las decisiones que afecten al funcionamiento de WIT</td>
							</tr>
							<tr>
								<td>EQUIPO ACTIVO</td>
								<td>Órgano que ejecuta las decisiones de la Asamblea General</td>
								<td>Miembros de WIT designados por la Asamblea General para desarrollar unas determinadas tareas</td>
								<td>Única y exclusivamente las dictadas por la Asamblea General</td>
							</tr>
							<tr>
								<td>JUNTA DIRECTIVA</td>
								<td>Órgano burocrático dado que la Ley de Asociaciones exige que haya una Junta Directiva en toda asociación</td>
								<td>Tres personas que forman de manera rotativa ocupan los cargos de Presidencia, Secretaría y Tesorería</td>
								<td>Sólo las que requiere la administración como trámites, convocatorias de asambleas o documentos oficiales</td>
							</tr>
						</tbody>
					</table>*/}
				</div>

				<h4>Responsabilidades como socio/a</h4>
				<div className="section">
					<h6>Nuevo/a Socio/a: requisitos</h6>
					Para poder ser una persona socia de WIT debes cumplir estrictamente todos los requisitos:
					<ul>
						<li>Ser mayor de 21 años y tener plena capacidad de obrar y decidir.</li>
						<li>Ser previa consumidora de cannabis o necesitar el cannabis por motivos terapéuticos.</li>
						<li>Que una persona miembro de WIT te avale.</li>
						<li>Entender el modelo de acceso al cannabis a través de WIT como una alternativa a los mercados informales y así mejorar tu salud y calidad de vida.</li>
						<li>Conocer el contenido de los Estatutos y del Régimen Interno, compartir sus objetivos, y entender las particularidades de la asociación.</li>
						<li>Entender que pasas a formar parte de la Asamblea General y como tal eres co-responsable de las decisiones que se tomen desde el seno de la misma.</li>
						<li>Comprometerse a respetar la legalidad y jurisprudencias vigentes.</li>
					</ul>

					<h6>AVALADOR/A: Definición, ¿Cómo se avala?, responsabilidades del avalador/a</h6>
					La persona que te avala, es aquella persona amiga tuya que ya pertenece a WIT y da fe de que cumples con todos los requisitos para entrar a formar parte del grupo de consumo.<br />
					Tienes dos formas de avalar a un/a amigo/a:
					<ul>
						<li>Físicamente en el punto de información</li>
						<li>A través de la web, en el apartado "Avalar" a un nuevo miembro</li>
					</ul>
					La responsabilidad que tendrás como persona avaladora es garantizar que el/la nuevo/a miembro cumple con los mencionados requisitos.<br />
					La nueva persona miembro deberá realizar este mismo curso tras tu aval, se valorará su entrada en la asamblea general que tiene lugar la última semana del mes, tras la misma si su entrada ha sido aprobada se le enviaran el correo de bienvenida y las claves de acceso a la web.

					<h6>Derechos como miembro</h6>
					Todos los recogidos en los Estatutos y el Régimen Interno, así como los recogidos en la Ley Orgánica 1/2002, de 22 de marzo, reguladora del Derecho de Asociación.<br />
					Pero, ante todo, entender WIT y a las personas que la componemos como una gran familia que toma decisiones de forma legal, democrática, asamblearia y conjunta con la finalidad de auto-abastecernos, evitando mercados alternativos que para nada favorecen nuestra calidad de vida y salud.

					<h6>Deberes como miembro</h6>
					Todos los recogidos en los Estatutos y el Régimen Interno, así como los recogidos en la Ley Orgánica 1/2002, de 22 de marzo, reguladora del Derecho de Asociación así como el art. 368 del Código Penal y normativa específica detallada en la solicitud de admisión de socio.<br />
					Entre todos los deberes, hay que destacar:
					<ul>
						<li>Que entiendas como deber la co-responsabilidad que implica pertenecer a WIT y a la Asamblea General, y lo importante que es para el grupo tu opinión y que participes compartiéndola.</li>
						<li>Comprometerse a destinar tu consumo al ámbito privadod e tu domicilio y de manera únicamente personal.</li>
					</ul>
				</div>
			</Fragment>
		)
	}
}
