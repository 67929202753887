import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "react-accessible-accordion/dist/fancy-example.css";
import "../About/About.css"; // no custom CSS uses the same as About.css

class Faqs extends Component {
	render() {
		return (
			<div className="wit-page">
				<Header {...this.props} />

				<div className="page-title">
					<p>FAQs</p>
				</div>

				<div className="accordion-about">
					<p className="disclaimer">
						Ninguna información de estas FAQ’s sustituye a la normativa interna, en caso que algo no coincida siempre prevalecerá lo que indique en cada caso la última versión de la documentación oficial aprobada por la Asamblea General.<br /><br />
						Ponte en contacto si tienes dudas o sugerencias sobre nuevas FAQ’s.
					</p>

					<h4>RESERVAS</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO SE HACEN LAS RESERVAS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Una vez elegidas las variedades y cantidades que queremos retirar, accedemos a la pestaña “Cesta” y seguimos los pasos indicados.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿A QUÉ HORA LLEGARÁ LA RESERVA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Durante la franja de 1h seleccionada. Antes de hacer la reserva es importante asegurarnos que estaremos en casa durante ese rato. Para recordar la hora seleccionada consulta el historial o el mail de confirmación.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿PODEMOS ACORDAR LA HORA DE ENTREGA CON EXACTITUD?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No, por cuestiones prácticas el socio transportista puede llegar en cualquier momento de la franja seleccionada.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO PODEMOS MODIFICAR EL DÍA Y LA HORA DE LA RESERVA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Enviando un e-mail a <a href="mailto:info@bcnwit.org">info@bcnwit.org</a> o llamando al <a href="tel://936776759">93 677 67 59</a>.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO ELIMINAR UNA RESERVA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Enviando un e-mail a <a href="mailto:info@bcnwit.org">info@bcnwit.org</a> o llamando al <a href="tel://936776759">93 677 67 59</a>.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿POR QUÉ HAY FRANJAS HORARIAS BLOQUEADAS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Porque no hay antelación suficiente, porque la franja está llena o porque está cerrada.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>RETIRAR</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿DÓNDE PODEMOS RETIRAR LAS RESERVAS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Única y exclusivamente en nuestro domicilio particular.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿PODEMOS RETIRAR EN OTRO LUGAR?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No, por razones legales sólo podemos retirar en nuestro domicilio particular.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿PUEDE OTRA PERSONA RETIRAR POR NOSOTROS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No, por razones legales sólo la podemos retirar personalmente.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>NO ESTARÉ EN CASA A LA HORA SELECCIONADA, ¿QUÉ HAGO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Ponte en contacto con el equipo activo llamando al <a href="tel://936776759">93 677 67 59</a>.<br />
									A todos nos puede ocurrir un día, pero las ausencias en retiradas nos perjudican a todos y pueden ser motivo de expulsión.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ PASA SI LLEGA LA RESERVA Y NO ESTOY EN CASA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Un socio te llamará por teléfono y esperaremos 5 minutos.<br />
									Si en este tiempo no has llegado, la reserva se programará para el día siguiente; recuerda que puedes eliminar la reserva o reprogramarla llamando al <a href="tel://936776759">93 677 67 59</a>.<br />
									A todos nos puede ocurrir un día, pero las ausencias reiteradas nos perjudican a todos y pueden ser motivo de expulsión.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>DÍAS Y HORARIO DE RETIRADA</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>De lunes a sábado el horario es de 15:00h a 22:00h.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿PODEMOS RETIRAR EN EL PUNTO DE INFORMACIÓN?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>No, sólo en el domicilio particular.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>APORTAR</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO HACEMOS LAS APORTACIONES?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>En metálico en el domicilio o con tarjeta bancaria en la misma web, siempre antes de retirar.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PROBLEMAS CON LA APORTACIÓN ON-LINE, ¿POR QUÉ?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									El banco solo acepta tarjetas emitidas en España y habilitadas para compras on-line.<br />
									Si ese no es tu caso, ponte en <Link to="/contacto">contacto</Link>.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>AVALAR AMIG@S</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿ACEPTAMOS NUEVOS SOCIOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Sí, siempre que cumplan con los requisitos establecidos por la normativa interna.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿TENEMOS UN LÍMITE DE SOCIOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Sí, el límite fijado por la asamblea general es de 500 socios, de esta forma nos aseguramos que la actividad queda entre pocos amigos. En caso que llegáramos a los 500 la asamblea deberá decidir qué hacer al respecto. Para más información consulta las FAQ’s sobre previsión de consumo y lista de participantes.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO INVITAR A UN AMIGO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Accediendo al apartado "Avalar" a través del menú de la web o haciendo clic <Link to="/avalar">aquí</Link>.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ RESPONSABILIDAD TENGO AL AVALAR A ALGUIEN?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Cuando avalas a un amigo/a, dás garantía al resto de socios y dejas firmado que es una persona responsable y de confianza, que entiende la legalidad vigente y nuestra normativa interna y se compromete a cumplir estrictamente con ello y que es consumidor de cannabis con fines de salud y de mejora de la calidad de vida.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CUÁNTO CUESTA HACERSE SOCIO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>La cuota anual es de 20€ y deberá renovarse anualmente.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CUÁNDO PODRÁ RETIRAR EL NUEVO SOCIO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Desde que la asamblea apruebe su solicitud cada 15 días; el día siguiente recibirá las claves de acceso a la web y podrá empezar a hacer reservas.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PROCESO DE INSCRIPCIÓN</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>El solicitante es avalado por un socio.</li>
									<li>Realiza el curso de solicitud de acceso y se valida su documentación.</li>
									<li>Cada 15 días, la junta directiva bajo el mandato expreso de la Asamblea General  acepta el ingreso de los nuevos socios, que son aprobados por la propia Asamblea mensual de todos los socios.</li>
									<li>El mismo día de la aprobación recibimos un correo de bienvenida con las claves de acceso.</li>
									<li>Cuando llegue su primera reserva, deberemos acreditar los datos con el documento de identidad, firmar la documentación y aportar la cuota anual.</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ REQUISITOS SE DEBEN CUMPLIR PARA SER SOCIO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>Que otro socio, como avalador, dé garantía de que es una persona responsable, que entiende y comparte el proyecto y que es consumidor habitual de cannabis o derivados con fines de salud y mejora de la calidad de vida.</li>
									<li>Ser mayor de 21 años y gozar de plena capacidad de obrar y decidir.</li>
									<li>
										Demostrar idoneidad para formar parte de Wit acreditando:
										<ul>
											<li>Ser usuario de cannabis con fines de salud o mejora de su calidad de vida y tener interés legítimo en mejorar los hábitos de consumo a través de la Asociación.</li>
											<li>Conocer nuestros estatutos, objetivos, actividades y particularidades. Para más información ver apartado “Responsabilidad del socio”.</li>
											<li>Congregar con nuestros fines y objetivos y se compromete a cumplir con ellos.</li>
											<li>Participar en la toma de decisiones de manera asamblearia y, como miembro de la Asamblea General de socios, entiende su co-responsabilidad en las decisiones que ésta tome.</li>
										</ul>
									</li>
									<li>Haber superado con éxito el curso de formación y que la asamblea apruebe la solicitud.</li>
									<li>Aportar copia de DNI/NIE/Pasaporte</li>
									<li>Abonar 20€ de cuota anual</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>MODIFICAR DATOS PERSONALES</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>CAMBIO DE CONTRASEÑA, NOMBRE DE USUARIO, TELÉFONO Y EMAIL</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Podemos hacerlo fácilmente desde el apartado "EDITAR" en el menú de usuario o haciendo click <Link to="/usuario">aquí</Link>.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>CAMBIO DE DOMICILIO</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Comunícalo con antelación por e-mail a <a href="mailto:info@bcnwit.org">info@bcnwit.org</a></p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>ACTUALIZACIÓN DE PREVISIÓN DE CONSUMO</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Siempre que estés a punto de sobrepasar tu previsión, la web te avisará para que puedas actualizar tu previsión de consumo. También puedes actualizarla desde tu perfil directamente desde el apartado "PREVISIÓN" del menú de usuario o haciendo click <Link to="/prevision">aquí</Link>.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO PODEMOS DARNOS DE BAJA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Poniéndonos en contacto a través del correo <a href="mailto:info@bcnwit.org">info@bcnwit.org</a></p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>PREVISIÓN DE CONSUMO</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ ES LA PREVISIÓN DE CONSUMO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Es la cantidad que prevemos consumir al mes. Es importante para que mensualmente nos podamos auto-aprovisionar de la cantidad exacta para abastecer la demanda mensual de todos los socios y llevar un control preciso de nuestro consumo.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO ACTUALIZAMOS LA PREVISIÓN DE CONSUMO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Para modificarla en otro momento, entra en el apartado "PREVISIÓN" desde el menú de usuario y allí podrás realizarlo fácilmente, o si lo prefieres haz clic <Link to="/prevision">aquí</Link>.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>ASAMBLEAS</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ ES LA ASAMBLEA GENERAL DE SOCIOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Es el órgano supremo de la Asociación y donde se toman todas las decisiones; todos formamos parte de ella. Una vez al mes nos reunimos a través de la herramienta virtual de la propia web para revisar y decidir el funcionamiento de manera distendida y con total transparencia.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿DÓNDE Y CUANDO NOS REUNIMOS EN ASAMBLEA?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Mediante el foro de la web debatimos y tomamos las decisiones entre todos, el último miércoles de cada mes.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO PARTICIPAR?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Entra en el foro Asamblea de la web y opina sobre las propuestas planteadas en el orden del día.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿DE QUÉ SE HABLA EN LAS ASAMBLEAS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Es un espacio distendido para comentar nuestras inquietudes e intereses alrededor del cannabis y aplicarlos a WIT, nuestro proyecto compartido.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ PASA SI NO PARTICIPAMOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Nada, pero perdemos la oportunidad de adaptar la Asociación a nuestras necesidades y de fomentar un proyecto horizontal y participativo. Tal y como firmamos en la solicitud de admisión, somos co-responsables de las decisiones que tome la Asamblea, así que está bien hacer valer nuestra voz.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CÓMO PODEMOS REVISAR LAS DECISIONES TOMADAS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>En el apartado “Asamblea” hay un registro de todas las discusiones así como las actas definitivas de cada asamblea.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>NO ESTOY DE ACUERDO CON ALGO, ¿CÓMO LO PUEDO CAMBIAR?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Haz una propuesta en la asamblea y lo debatiremos con los demás socios, si la mayoría está de acuerdo procederemos a aplicar los cambios correspondientes.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>REDUCCIÓN DE RIESGOS</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿POR QUÉ UN PROGRAMA DE REDUCCIÓN DE RIESGOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Porque nuestra finalidad es acceder al cannabis con fines de salud y mejora de la calidad de vida, y para ello es fundamental estar informados y asesorados respecto sus efectos y contraindicaciones. Una de las principales motivaciones de WIT es que a la vez que accedemos al cannabis tengamos acceso a un potente programa pensado para evitar los riesgos que puede tener para la salud.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿EN QUÉ CONSISTE NUESTRO PROGRAMA DE REDUCCIÓN DE RIESGOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>Información contrastada acerca de los riesgos asociados al consumo de cannabis mediante charlas informativas, materiales impresos, página web, asistencia personalizada, etc.</li>
									<li>Carta ámplia e información detallada sobre las variedades, para poder elegir según los efectos deseados.</li>
									<li>Variedades con alta presencia de CBD, el cannabinoide con más aplicaciones terapéuticas y que, además, actúa como atenuante de los efectos psicodélicos del THC.</li>
									<li>Vías de consumo alternativas a la combustión.</li>
									<li>Historial de consumo para una mejor monitorización de nuestro hábitos.</li>
									<li>Análisis de los principios activos de las variedades de cannabis más consumidas.</li>
									<li>Formación y capacitación del equipo activo a cargo de instituciones públicas y otras entidades (Energy Control, FedCac, Iceers, etc.) para que puedan prestar asesoramiento fiable a los socios que lo soliciten (link contacto).</li>
									<li>Administración de cuestionarios sobre el consumo responsable y de cuestionarios informativos a los nuevos solicitantes y los socios que lo requieran (link contacto).</li>
									<li>Estrecha colaboración con entidades públicas y privadas de trayectoria contrastada en materia de reducción de riesgos.</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>NECESITO ASESORAMIENTO SOBRE MI CONSUMO, ¿DE QUÉ RECURSOS DISPONEMOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>Podemos empezar por realizar un test anónimo en <a href="http://drugsmeter.com" rel="noopener noreferrer" target="_blank">Drugsmeter</a>. Esta potente herramienta global nos ayudará a hacernos preguntas que nunca nos habíamos planteado y nos dará una orientación de nuestro nivel de riesgo comparando nuestro consumo con el de los demás usuarios.</li>
									<li>Nos podemos poner en contacto con el equipo activo. El personal de WIT está formado en la materia por instituciones públicas y otras entidades especializadas para podernos asesorar con garantías y total privacidad. Además disponemos de materiales informativos que nos pueden resultar muy útiles.</li>
									<li>Si lo necesitamos, el equipo activo nos pondrá en contacto con profesionales médicos especializados entidad colaboradora que nos puede prestar atención profesional personalizada.</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ VARIEDADES SON LAS MÁS INDICADAS PARA REDUCIR LOS RIESGOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Aquellas cuyos efectos y contraindicaciones están en línea con nuestro caso. Las variedades con alto contenido en CBD no tienen efectos psicoactivos y atenúa los efectos del THC. Si queremos un efecto ligero, buscaremos las variedades con menos THC. Si buscamos efectos fuertes y directos tenemos concentrados con altas concentraciones de THC.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUÉ VIAS DE CONSUMO SON LAS MÁS INDICADAS PARA REDUCIR LOS RIESGOS?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>La vaporización, las tinturas o los comestibles ya que eliminamos así los riesgos asociados a la combustión.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>TERAPÉUTICOS</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿POR QUÉ UN PROGRAMA TERAPÉUTICO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Porque con nuestra finalidad de acceder al cannabis con fines de salud y mejora de la calidad de vida es básico tener un programa terapéutico para, entre todos, ayudar a los socios que padecen una enfermedad y necesitan el cannabis en su día a día de forma terapéutica y bajo certificación médica.</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿EN QUÉ CONSISTE NUESTRO PROGRAMA TERAPÉUTICO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>10% de descuento en todas las cuotas y aportaciones.</li>
									<li>Asesoramiento médico personalizado con doctores colegiados.</li>
									<li>Información detallada sobre las variedades y sus efectos terapéuticos.</li>
									<li>Productos específicos para uso terapéutico.</li>
									<li>Vías de consumo alternativas a la combustión.</li>
									<li>Equipo activo con formación específica a cargo de entidades sanitarias públicas y privadas de trayectoria contrastadas.</li>
									<li>El límite de socios no afecta a los socios terapéuticos dado que son ilimitados.</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿QUIÉN PUEDE SER SOCIO TERAPÉUTICO?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Cualquier usuario de cannabis por razones terapéuticas que tenga diagnosticada una enfermedad para la que el cannabis tiene efectos terapéuticos según la <a href="https://www.cannabis-med.org/index.php?tpl=page&id=21&lng=es" rel="noopener noreferrer" target="_blank">IACM</a> y aporte un certificado médico. Los usuarios que disponen del certificado no tienen la necesidad de ser avalados.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>

					<h4>RESPONSABILIDAD DEL SOCIO</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿CUÁLES SON NUESTRAS PRINCIPALES RESPONSABILIDADES?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ul>
									<li>Destinar el cannabis para un uso exclusivamente personal.</li>
									<li>Consumirlo en el ámbito privado del domicilio particular y no sacarlo de este ámbito en ningún caso.</li>
									<li>Como socios, formamos parte de la Asamblea General. Es nuestra responsabilidad participar del órgano supremo de la asociación ya que asumimos nuestra co-responsabilidad de las decisiones que esta tome.</li>
									<li>Ser conocedor y comprometerse a cumplir con los Estatutos, objetivos, actividades y particularidades de la Asociación y comprometerse a cumplir con ellos, con el reglamento de régimen interno, con la solicitud de admisión de socio y la de participación en el programa de auto-abastecimiento y acceso al cannabis y con las decisiones tomadas por la Asamblea General.</li>
									<li>Ser conocedor y cumplir estrictamente la legalidad vigente, con especial atención a los siguientes artículos.</li>
								</ul>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>¿EN QUÉ CASOS SE PIERDE LA CONDICIÓN DE SOCIO POR SANCIÓN?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>Consulta los Estatutos Art. 28-32.</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>
				</div>

				<Footer />
			</div>
		);
	}
}

export default Faqs;
