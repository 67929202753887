import {
  CUSTOMER_FETCH,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_HISTORY,
  CUSTOMER_FETCH_HISTORY_ERROR,
  CUSTOMER_FETCH_HISTORY_SUCCESS,
  CUSTOMER_FETCH_SUCCESS,
} from '../constants/action-types'
import { loading } from '../constants/mappers'

const defaultState = {
  customer: {},
  historic: [],
  loading: false,
  loaded: false,
  error: false,
}

const onFetchError = (state, { error }) => {
  if (error.response && error.response.status === 404) {
    error = 'No existe tal socio'
  }

  return {
    ...state,
    error,
    loading: false,
  }
}

const onFetchHistorySuccess = (state, {historic}) => {
  const hArray = [
    ['Mes', 'Consumo (€)', 'Artículos (unidades)'],
  ]
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril',
    'Mayo', 'Junio', 'Julio', 'Agosto',
    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
  ]
  historic.forEach(({month, paid, products}) => {
    hArray.push([
      months[Number(month) - 1],
      Number(paid),
      Number(products),
    ])
  })
  return {
    ...state,
    historic: hArray,
    loading: false,
    loaded: true,
  }
}


const CustomersReducer  = (state = defaultState, action = {}) => {
  switch (action.type) {
    case CUSTOMER_FETCH_HISTORY:
    case CUSTOMER_FETCH:
      return loading(state)

    case CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        customer: {
          ...action.customer,
          course: action.customer.course || !!Number(action.customer.curs_fet),
        },
      }

    case CUSTOMER_FETCH_HISTORY_ERROR:
    case CUSTOMER_FETCH_ERROR:
      return onFetchError(state, action)

    case CUSTOMER_FETCH_HISTORY_SUCCESS:
      return onFetchHistorySuccess(state, action)

    default:
      return state
  }
}

export default CustomersReducer
