import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isUserLoggedIn } from "./Utils";

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render = { props =>
			isUserLoggedIn() ? (
				<Component {...props} />
			) : (
				<Redirect to={{
					pathname: '/',
					state: {
						from: props.location
					}
				}} />
			)
		}
	/>
);
