import {
  PRODUCTS_FETCH_ALL,
  PRODUCTS_FETCH_ALL_SUCCESS,
  PRODUCTS_FETCH_ALL_ERROR,
  PRODUCTS_SET_ACTIVE,
  PRODUCTS_SET_ACTIVE_BY_REF,
  PRODUCTS_RESET_ACTIVE_BY_CAT,
  STOCKS_FETCH,
  STOCKS_FETCH_SUCCESS,
  STOCKS_FETCH_ERROR,
  PRODUCTS_RESET_ACTIVE,
} from '../constants/action-types'
import Api from '../helpers/Api'
import { fetchByProductId } from './combinations'
import { fetchAllFeaturesAndValues } from './product-features'
import store from 'store2'

export const fetchAllProductsAction = () => ({
  type: PRODUCTS_FETCH_ALL,
})

export const fetchAllProductsSuccessAction = (products) => ({
  type: PRODUCTS_FETCH_ALL_SUCCESS,
  products,
})

export const fetchAllProductsErrorAction = (error) => ({
  type: PRODUCTS_FETCH_ALL_ERROR,
  error,
})

export const setActiveAction = (id) => ({
  type: PRODUCTS_SET_ACTIVE,
  id,
})

export const setActive = (id) => (dispatch, getState) => {
  // Check if there's its combination already
  if (!getState().combinations.byProductId[id]) {
    dispatch(fetchByProductId(id))
  }
  return dispatch(setActiveAction(id))
}

export const resetActive = () => ({
  type: PRODUCTS_RESET_ACTIVE,
})

export const resetActiveForCategory = (category) => ({
  type: PRODUCTS_RESET_ACTIVE_BY_CAT,
  category,
})

export const setActiveByRefAction = (ref) => ({
  type: PRODUCTS_SET_ACTIVE_BY_REF,
  ref,
})

export const setActiveByRef = (ref) => (dispatch, getState) => {
  // Check if there's its combination already
  const product = getState().products.byRef[ref]
  // Avoid crashing app in case product does not exist
  if (!product) {
    return false
  }
  if (!getState().combinations.byProductId[product.id]) {
    dispatch(fetchByProductId(product.id))
  }
  return dispatch(setActiveByRefAction(ref))
}

export const fetchAllStock = () => (dispatch) => {
  dispatch({
    type: STOCKS_FETCH
  })

  const key = store.get('_key')

  return Api.get('/stock_availables', {
    params: {
      display: 'full',
      key,
    },
  })
  .then(({data: {stock_availables}}) =>
    dispatch({type: STOCKS_FETCH_SUCCESS, stocks: stock_availables})
  )
  .catch((error) => dispatch({type: STOCKS_FETCH_ERROR, error}))
}

export const fetchAll = (loadpf = true) => (dispatch) => {
  dispatch(fetchAllProductsAction())
  dispatch(fetchAllStock())

  const key = store.get('_key')

  return Api.get('/products', {
    params: {
      display: 'full',
      'filter[active]': 1,
      key,
    },
  })
  .then((result) => {
    if (!loadpf) {
      return result
    }

    const { products } = result.data
    dispatch(fetchAllFeaturesAndValues(products))

    return result
  })
  .then(({data}) => dispatch(fetchAllProductsSuccessAction(data.products)))
  .catch((error) => dispatch(fetchAllProductsErrorAction(error)))
}
