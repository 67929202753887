import { connect } from 'react-redux'

import { fetchTodaySchedule } from '../actions/cart'
import Delivery from '../components/Cart/DeliveryButton'
import { today } from '../constants/globals'

const mapStateToProps = (state) => ({
  text: 'Hoy',
  user: state.UserDetailsReducer.userDetails || {},
  schedules: state.cart.schedules.today,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetch: (postcode) => fetchTodaySchedule(postcode),
  date: () => today(),
})

const TodayDeliveryContainer = connect(mapStateToProps, mapDispatchToProps)(Delivery)


export default TodayDeliveryContainer
