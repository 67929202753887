import React, { Component } from 'react'

import './Modal.css'

export default class Modal extends Component {
  render() {
    return (
      <div className='modal'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              { this.props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
