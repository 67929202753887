import {
  PF_FETCH_ALL_SUCCESS,
  PF_FETCH_ALL_VALUES_SUCCESS,
} from '../constants/action-types'
import { byRef } from '../constants/mappers'
import { groupBy } from '../constants/globals'

const initialState = {
  features: {},
  values: {
    all: {},
    byFeatureId: {},
  },
}

const ProductFeaturesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PF_FETCH_ALL_SUCCESS:
      return {
        ...state,
        features: byRef(action.features, 'id'),
      }
    case PF_FETCH_ALL_VALUES_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          all: byRef(action.values, 'id'),
          byFeatureId: groupBy(action.values),
        },
      }
    default:
      return state
  }
}

export default ProductFeaturesReducer
