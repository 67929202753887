import React, { Component } from 'react'
import moment from 'moment'

import {
  DATE_FORMAT,
} from '../../../constants/constants'
import { canStillMakeChanges } from '../../../helpers/Utils'
import { cancelOrder } from '../../../actions/orders'

export default class CancelDelivery extends Component {
  onCancelClick() {
    const { dispatch, order, customer } = this.props

    if (!window.confirm('¿Seguro que quieres cancelar la aportación?')) {
      return
    }

    return dispatch(cancelOrder(customer, order))
  }

  render() {
    const { order } = this.props

    if (!order.fecha_entrega?.length || !order.hora_entrega?.length) {
      return null
    }

		const [hour] = order.hora_entrega.split('-');
    const deliveryDate = moment(
      `${order.fecha_entrega} ${hour}`,
      `${DATE_FORMAT} HH:mm`
    )
    const now = moment()

    if (!now.isBefore(deliveryDate) || !canStillMakeChanges(order)) {
      return null
    }

    return (
      <button onClick={this.onCancelClick.bind(this)} className='btn'>
        Cancelar Aportación
      </button>
    )
  }
}
