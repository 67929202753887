import React, { Component } from 'react'

import Modal from './Modal'

export default class ModalWithButton extends Component {
  render() {
    if (!this.props.visible) {
      return null;
    }

    const text = this.props.text ? this.props.text : 'Entendido'

    return (
      <Modal>
        {this.props.children}
        <button className='btn' onClick={this.props.onClick}>
          {text}
        </button>
      </Modal>
    )
  }
}
