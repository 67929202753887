import React, { Component } from 'react'
import classNames from 'classnames'

import Whatsapp from './Whatsapp'

export default class EmptyWitPage extends Component {
  title() {
    if (!this.props.title) {
      return
    }

    return (
      <div className='page-title standalone'>
        <p>{this.props.title}</p>
      </div>
    )
  }
  render() {
    const classes = [
      'wit-page'
    ]
    if (this.props.className) {
      classes.push(this.props.className)
    }
    return (
      <div className={classNames(classes)}>
        {this.title()}
        {this.props.children}
        <Whatsapp {...this.props} />
      </div>
    )
  }
}
