import store from 'store2'
import moment from 'moment'

import {
  DATE_FORMAT,
  LATEST_TIME_ALLOWED_FOR_CHANGES,
  LATEST_TIME_ALLOWED_ORDER_CHANGES,
} from '../constants/constants'

export function stripHtmlFromText(html)
{
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export function scrollStepTop(delayInMs, scrollStepInPx)
{
	var intervalId = setInterval(function() {
		if (window.pageYOffset === 0) {
        	clearInterval(intervalId);
	    }
	    window.scroll(0, window.pageYOffset - scrollStepInPx);
	}, delayInMs);
}

export function formatPrice(price) {
	return Number(price).toFixed(2) + "€";
}

export function formatWeight(weight) {
	return Number(weight).toFixed(2) + "gr";
}

export function validateCuotaDate(lastCuota) {
	const dateToday = new Date();
	const dateLastCuota = new Date(lastCuota);
	const diffTime = Math.abs(dateToday.getTime() - dateLastCuota.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays > 365) {
		return false;
	} else {
		return true;
	}
}

export function setNewCuotaDate() {
	var newDate = new Date();

	return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
}

export function validateBirthday(birthday) {
	const dateBirthday = new Date(birthday);
	const day = dateBirthday.getDate();
	const month = dateBirthday.getMonth();
	const year = dateBirthday.getFullYear();

	if (new Date(year+21, month, day) >= new Date()) {
		return false;
	} else {
		return true;
	}
}

export function testLocalStorage() {
	let lsTest = 'lstest';
	try {
		localStorage.setItem('_lsTest', lsTest);
		localStorage.removeItem('_lsTest');
		return true;
	} catch(e) {
		return false;
	}
}

export const isUserLoggedIn = () => {
  if (!testLocalStorage()) {
    return false
  }

  const user = store.get('_userId')
  const lastLogin = store.get('_userLastLogin')
  const key = store.get('_key')

  if (!user || !lastLogin || !key) {
    return false
  }

  if (key === 'undefined' || user === 'undefined') {
    return false
  }

  const dateNow = new Date();
  const lastUserLogin = new Date(Number(lastLogin));
  const diffSecs = (dateNow.getTime() - lastUserLogin.getTime()) / 1000;

  return diffSecs < 3600 && user
}


export const storageLogin = ({auth, id}) => {
  store.set('_key', auth)
  store.set('_userId', id)
  store.set('_userLastLogin', Date.now())

  return store.has('_key') && store.has('_userId') && store.has('_userLastLogin')
}

/**
 * Determines if the user is still able to make changes to the order. Note that this
 * only verifies if the order is in the last allowed schedule, which behaves different
 * than the other orders.
 *
 * @param {order} order
 */
export const canStillMakeChanges = (order) => {
  const [orderTime] = order.hora_entrega.split('-');
  let [hour] = orderTime.split(':')

  // properly cast
  hour = parseInt(hour, 10)

  if (hour >= LATEST_TIME_ALLOWED_FOR_CHANGES) {
    const now = moment()
    const maxAllowedDate = moment(
      `${order.fecha_entrega} ${LATEST_TIME_ALLOWED_ORDER_CHANGES}`,
      `${DATE_FORMAT} HH:mm`
    )

    return now.isBefore(moment(maxAllowedDate))
  }

  return true
}
