import React, { Component } from 'react'

import { formatPrice } from '../../constants/globals'
import { makeCalculations, verify } from '../../actions/cart'
import { fetchAll as fetchAllCombinations } from '../../actions/combinations'
import { fetchAll as fetchAllProducts } from '../../actions/products'
import DiscountRow from './DiscountRow'

export default class TotalRow extends Component {
  componentDidMount() {
    const { dispatch } = this.props

    // Always retrieve all products and combinations to ensure stock is properly checked
    Promise.all([
      dispatch(fetchAllCombinations()),
      dispatch(fetchAllProducts(false)),
    ])
    .then(() => {
      dispatch(verify())
      return dispatch(makeCalculations())
    })
  }

  render() {
    const { cart: {calculated} } = this.props

    return (
      <tfoot>
        <DiscountRow
          percent={calculated.discount.percent}
          discount={calculated.discount.amount}
        />
        <tr>
          <td>&nbsp;</td>
          <td>Total Reserva</td>
          <td>{formatPrice(calculated.total)}</td>
        </tr>
      </tfoot>
    )
  }
}
