import React, { Component } from 'react'
import classNames from 'classnames'

import './Modal.css'

export default class ModalReader extends Component {
  modalContent = null

  state = {
    read: false,
    open: true,
  }

  componentDidMount() {
    this.modalContent.addEventListener('scroll', this.onModalScroll.bind(this))
    this.modalContent.scrollTop = 0
  }

  assignModalContentRef(ref) {
    this.modalContent = ref
  }

  onModalScroll(scroll) {
    const { scrollHeight, scrollTop, clientHeight} = this.modalContent
    if (scrollHeight - (scrollTop + 100) <= clientHeight) {
      this.setState({read: true})
    }
  }

  handleOnClick() {
    if (typeof this.props.onClick === 'function') {
      return this.props.onClick()
    }

    this.setState({open: false})
  }

  get isButtonDisabled() {
    if (this.state.read) {
      return {}
    }

    return {
      disabled: true,
    }
  }

  get shouldRender() {
    if (typeof this.props.open === 'undefined') {
      return this.state.open
    }

    return this.props.open
  }

  render() {
    let classes = ['modal', 'scrollable']
    const buttonText = this.props.buttonText || 'he leído y acepto'
    if (!this.shouldRender) {
      classes.push('hidden')
    }

    return (
      <div className={classNames(classes)}>
        <div className='modal-dialog'>
          <div className='modal-content' ref={this.assignModalContentRef.bind(this)}>
            <div className='modal-body'>
              { this.props.children }
            </div>
          </div>
          <div className='modal-footer fixed'>
            <button
              {...this.isButtonDisabled}
              onClick={this.handleOnClick.bind(this)}
              className='btn success'>
                { buttonText }
            </button>
          </div>
        </div>
      </div>
    )
  }
}
