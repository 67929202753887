import API from '../helpers/Api'
import store from 'store2'

import {
	GET_CART_BY_USER,
	CREATE_NEW_CART,
	DELETE_CURRENT_CART,
	RESET_CURRENT_CART,
} from '../constants/action-types'
import { fetchByProductId } from './combinations'
import { fetchAction, fetchSuccessAction } from './cart'

// Action creators
export function getCartDetailsByUser(currentCart) {
	return {
		type: GET_CART_BY_USER,
		currentCart
	}
}

export function createCart(currentCart) {
	return {
		type: CREATE_NEW_CART,
		currentCart
	}
}

export function deleteCart(currentCart) {
	return {
		type: DELETE_CURRENT_CART,
		currentCart
	}
}

export function resetCart(currentCart) {
	return {
		type: RESET_CURRENT_CART,
		currentCart
	}
}

export const fetchUserCart = (userId) => (dispatch) => {
  dispatch(fetchAction())

  const key = store.get('_key')

  const emptyResponse = {}

  return API.get('/carts?filter[id_customer]=' + userId, {params: {key}})
    .then(
      function(res) {
        if (res.data.carts && res.data.carts.length > 0) {
          // get the last cart always
          let sortedCarts = res.data.carts
          sortedCarts = res.data.carts.sort(function(a, b) {
            return b.id - a.id
          })

          let currentCartId = sortedCarts[0].id

          // check if cart is completed (has a valid order associated)
          API.get('/orders?filter[id_cart]=' + currentCartId, {params: {key}})
            .then(res => {
              if (!res.data.orders || res.data.orders.length <= 0) {

                API.get('/carts/' + currentCartId, {params: {key}})
                  .then(res => {
                    // get each cart product data
                    if (res.data.cart.associations
                      && res.data.cart.associations.cart_rows
                      && res.data.cart.associations.cart_rows.length > 0) {
                      let currentCart = res.data.cart
                      let totalProducts = res.data.cart.associations.cart_rows.length
                      res.data.cart.associations.cart_rows.forEach(function(product, index) {
                        let combinationPrice = 0
                        let combinationWeight = 0
                        API.get('/products/' + product.id_product, {params: {key}})
                          .then(res => {
                            // get product combination data
                            let currentProductRow = res.data.product
                            dispatch(fetchByProductId(product.id_product))
                            API.get('/combinations/' + product.id_product_attribute, {params: {key}})
                              .then(res => {
                                combinationPrice = res.data.combination.price
                                combinationWeight = res.data.combination.weight

                                // add product details to cart data
                                currentCart.associations.cart_rows[index].product_data = {
                                  name: currentProductRow.name,
                                  combination_price: combinationPrice,
                                  combination_weight: combinationWeight
                                }

                                totalProducts--

                                if (totalProducts === 0) {
                                  dispatch(fetchSuccessAction(currentCart))
                                  dispatch(getCartDetailsByUser(currentCart))
                                  return currentCart
                                }
                              })
                          })
                      })
                    }
                  })

              }
            })
        }
      },
      function(err) {
        return emptyResponse
      }
    )
    .catch(
      function(err) {
        return emptyResponse
      }
    )
}
