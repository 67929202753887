import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import store from 'store2'

import { fetch } from '../../actions/assemblies'
import WitPage from '../Common/WitPage'
import PendingCerts from './PendingCerts'
import Announcement from './Announcement'
import History from './History'


export default class Assembly extends Component {
  componentDidMount() {
    if (!this.props.assemblies.ids.length) {

      this.props.dispatch(fetch(store.get('_userId')))
    }
  }

  render() {
    const assemblies = Object.values(this.props.assemblies.all)

    return (
      <WitPage {...this.props}>
        <div className="page-title">
          <p>ASAMBLEA</p>
        </div>
        <div className='styled-content'>
          <p>
            En WIT funcionamos a través de asambleas mensuales, por lo que necesitamos
            de tu participación para el buen funcionamiento.
          </p>
          <p>
            Sólo tienes que votar las actas de asamblea que tengas pendientes.
          </p>
          <p>
            Puedes ver el contenido de cada acta antes de enviar tu voto y siempre puedes
            escribirnos a través del <Link to="/contacto">contacto</Link> si tienes
            alguna sugerencia o pregunta.
          </p>
          <p>
            Gracias por tu colaboración y confianza en WIT... ¡tu voto importa!
          </p>
          <h4>Convocatoria de asamblea</h4>
          <Announcement {...this.props} assemblies={assemblies} />
          <h4>Actas pendientes de tu aprobación</h4>
          <PendingCerts {...this.props} assemblies={assemblies} />
          <h4>Histórico de actas de asambleas</h4>
          <History {...this.props} assemblies={assemblies} />
        </div>
      </WitPage>
    )
  }
}
