import React, { Component } from "react";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import classNames from 'classnames';
import { toastr } from "react-redux-toastr";
import { RadioGroup, Radio } from "react-radio-group";
import axios from "axios";

import { API_URL_FORMS } from "../../helpers/api-config";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./Contact.css";

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			btnDropdownVisible: false,
			contactType: 'ASUNTO',
			contactMessage: '',
			formcondition1: '',
		};

		this.initialState = this.state;

		this.toggleBtnDropdown = this.toggleBtnDropdown.bind(this);
		this.onChangeContactMessage = this.onChangeContactMessage.bind(this);
		this.onChangeFormCondition1 = this.onChangeFormCondition1.bind(this);
		this.sendContactRequest = this.sendContactRequest.bind(this);
	}

	toggleBtnDropdown() {
		this.setState(state => ({
			btnDropdownVisible: !state.btnDropdownVisible
		}));
	}

	onChangeContactMessage(event) {
		const eventValue = event.target.value;
		this.setState(state => ({
			contactMessage: eventValue
		}));
	}

	onChangeFormCondition1(value) {
		this.setState(state => ({
			formcondition1: value
		}));
	}

	setContactType(type) {
		this.setState(state => ({
			contactType: type
		}));
	}

	sendContactRequest() {
		if (this.state.contactType !== 'ASUNTO' && this.state.contactMessage !== '') {
			toastr.success('Hemos recibido tu mensaje y te daremos respuesta pronto. Gracias por confiar en WIT!');

			let formAction = 'contacto';
			let postFieldType = this.state.contactType;
			let postFieldMessage = this.state.contactMessage;
			axios.post(API_URL_FORMS + '?action=' + formAction + '&type=' + postFieldType + '&msg=' + postFieldMessage + '&nombre=' + this.props.userDetails.firstname + '&apellidos=' + this.props.userDetails.lastname);
		} else if (this.state.formcondition1 === '') {
			toastr.error('Debes aceptar los términos y condiciones');
		} else {
			toastr.error('Es necesario seleccionar un asunto y escribir un mensaje');
		}
	}

	render() {
		var btnDropdownClass = classNames({
			'active': this.state.btnDropdownVisible,
			'btn-dropdown': true
		});

		return (
			<div className="wit-page">
				<Header {...this.props} />

				<div className="page-title">
					<p>CONTACTO</p>
				</div>

				<div className="contact-form-wrapper">
					<p>
						Asociación WIT<br />
						<a href="mailto:info@bcnwit.org">info@bcnwit.org</a><br />
						Teléfono de contacto <a href="tel://936776759">93 677 67 59</a>
					</p>
					<div className="contact-form-container">
						<div className="input-form-2col">
							<div className="input-form-col col1">
								<div className={btnDropdownClass} onClick={this.toggleBtnDropdown}>
									<span>{this.state.contactType}</span>
									<Animated
										animationIn="fadeIn"
										animationOut="fadeOut"
										animateOnMount={false}
										className="faster"
										isVisible={this.state.btnDropdownVisible}>
										<div className="btn-dropdown-content">
											<a href="#bookings" className="item" onClick={(ev) => { this.setContactType('RESERVAS') }}>RESERVAS</a>
											<a href="#general" className="item" onClick={(ev) => { this.setContactType('GENERAL') }}>GENERAL</a>
											<a href="#suggestions" className="last-item" onClick={(ev) => { this.setContactType('SUGERENCIAS') }}>SUGERENCIAS</a>
										</div>
									</Animated>
								</div>
							</div>
							<div className="input-form-col col2">
								<textarea placeholder="Escribe aquí tu mensaje" value={this.state.contactMessage} onChange={this.onChangeContactMessage}></textarea>
							</div>
						</div>

						<div className="legal-form-disclaimer">
							<u>INFORMACION BASICA DE PROTECCION DE DATOS</u>: En cumplimiento del deber de informar a los interesados de las circunstancias y condiciones del tratamiento de sus datos y de los derechos que le asisten, ponemos a su disposición la siguiente información<br /><br />
							<u>RESPONSABLE DE TRATAMIENTO</u>: Asociación Cannábica Wit<br /><br />
							<u>FINALIDAD DEL TRATAMIENTO DE SUS DATOS</u>: Gestionar y responder su consulta<br /><br />
							<u>CONSERVACIÓN DE LOS DATOS</u>: Los datos se conservan el tiempo estrictamente necesario para la relación y lo que le es exigible legalmente, siendo destruidos posteriormente mediante los procesos seguros de la organización<br /><br />
							<u>LEGITIMACION PARA EL TRATAMIENTO</u>: Interés legítimo de las partes para dar respuesta a la solicitud de información<br /><br />
							<u>DESTINATARIOS DE SUS DATOS PERSONALES</u>: No se prevén cesiones de datos salvo en aquellos casos en que exista una obligación legal. No hay previsión de transferencias de datos internacionales<br /><br />
							<u>SUS DERECHOS</u>: Puede revocar el consentimiento y ejercer sus Derechos a acceder, rectificar, oponerse, limitar, portar y suprimir los datos, escribiendo a Asociación Cannábica Wit, en Plaza Tetuán 40 1º Puerta 15, 08010 Barcelona (Barcelona) además de acudir a la autoridad de control competente (AEPD)<br /><br />
						</div>

						<div className="legal-form-conditions">
							<RadioGroup name="formcondition1" selectedValue={this.state.formcondition1} onChange={this.onChangeFormCondition1}>
								<label htmlFor="formcondition1a"><Radio value="true" /><span>Acepto las condiciones de uso del formulario de contacto</span></label>
							</RadioGroup>
						</div>

						<button onClick={this.sendContactRequest}>ENVIAR</button>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: state.UserDetailsReducer.userDetails
	}
}

export default connect(mapStateToProps, null)(Contact);
