import {
  PRODUCTS_FETCH_ALL_SUCCESS,
  PRODUCTS_FETCH_ALL,
  PRODUCTS_FETCH_ALL_ERROR,
  PRODUCTS_SET_ACTIVE,
  PRODUCTS_SET_ACTIVE_BY_REF,
  PRODUCTS_RESET_ACTIVE_BY_CAT,
  PRODUCTS_RESET_ACTIVE,
} from '../constants/action-types'
import { ids, byRef, loading } from '../constants/mappers'
import { getNextProduct } from '../constants/globals'

const initialState = {
  data: {
    all: {},
    ids: [],
  },
  byRef: {},
  byCategory: {},
  active: {
    name: '',
    reference: '',
  },
  loading: false,
  loaded: false,
  error: false,
}

const onFetchAllSuccess = (state, { products }) => ({
  ...state,
  data: {
    ...ids(products),
  },
  byRef: byRef(products, 'reference'),
  loading: false,
  loaded: true,
})

const sortByCategories = (state) => {
  const categorized = {}
  state.data.ids.forEach((id) => {
    state.data.all[id].associations.categories.forEach((category) => {
      if (typeof categorized[category.id] == 'undefined') {
        categorized[category.id] = []
      }

      categorized[category.id].push(state.data.all[id])
    })
  })

  return {
    ...state,
    byCategory: categorized,
  }
}

const onFetchAllError = (state, { error }) => ({
  ...state,
  loading: false,
  loaded: false,
  error,
})

const onSetActive = (state, {id}) => ({
  ...state,
  active: {
    ...state.data.all[id],
  },
})

const onSetActiveByRef = (state, {ref}) => ({
  ...state,
  active: {
    ...state.byRef[ref],
  },
})

const onResetActiveByCategory = (state, { category }) => {
  const current = category.associations.products[0]
  const products = state.data
  let product = products.all[current.id]

  if (!product) {
    product = getNextProduct(category.associations.products, products.all, 0)
  }

  return {
    ...state,
    active: {
      ...product,
    },
  }
}

export const onResetActive = (state) => ({
  ...state,
  active: {
    ...initialState.active,
  }
})

const ProductsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCTS_RESET_ACTIVE:
      return onResetActive(state)
    case PRODUCTS_RESET_ACTIVE_BY_CAT:
      return onResetActiveByCategory(state, action)
    case PRODUCTS_FETCH_ALL:
      return loading(state)
    case PRODUCTS_FETCH_ALL_SUCCESS:
      state = onFetchAllSuccess(state, action)
      state = sortByCategories(state)
      return state
    case PRODUCTS_FETCH_ALL_ERROR:
      return onFetchAllError(state, action)

    case PRODUCTS_SET_ACTIVE:
      return onSetActive(state, action)
    case PRODUCTS_SET_ACTIVE_BY_REF:
      return onSetActiveByRef(state, action)

    default:
      return state
  }
}

export default ProductsReducer
