import React, { Component } from 'react'
import { Formik } from 'formik'
import { Animated } from 'react-animated-css'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { isUserLoggedIn, storageLogin } from '../../helpers/Utils'
import { getUser } from '../../actions/userdetails-actions'
import ModalRecovery from '../../helpers/Modal'
import { ProperApi } from '../../helpers/Api'
import { setAuthKey } from '../../actions/api'
import logo from '../../images/logo-s1.svg'
import Text from '../Common/Forms/Text'

import './Login.css'

Yup.setLocale({
  mixed: {
    required: 'Este campo no puede quedar vacío',
  },
})

const genericError = () => toastr.error("Error", "Ha habido un error al iniciar sesión. Reinténtalo y, si persiste, coméntanos el problema.")

class Login extends Component {
  state = {
    modalRecoveryVisible: false,
  }

  fields = {
    user: '',
    password: '',
  }

	constructor(props) {
		super(props)

		this.toggleModalRecovery = this.toggleModalRecovery.bind(this)
  }

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.props.history.push('/carta')
    }
  }

	toggleModalRecovery() {
		this.setState(state => ({
			modalRecoveryVisible: !state.modalRecoveryVisible
		}))
  }

  validationSchema() {
    const schema = {}
    Object.keys(this.fields).forEach((field) => {
      schema[field] = Yup.string().required()
    })

    return Yup.object().shape(schema)
  }

  render() {
    return (
      <div className='login-wrapper'>
        <Formik
          initialValues={this.fields}
          onSubmit={(values, actions) => {
            ProperApi.post('/login', values).then((response) => {
              // We really use localstorage... maybe not required anymore
              this.props.dispatch(setAuthKey(response.data.auth))

              if (storageLogin(response.data)) {
                this.props.dispatch(getUser(response.data.id))
                this.props.history.push('/carta')
              } else {
                actions.setSubmitting(false)

                genericError()
              }
            }).catch((error) => {
              const {response} = error
              const {data} = response

              if (data.errors && response.status !== 401) {
                data.errors.forEach((error) => {
                  toastr.error("Error", error.message)
                })

                return
              }

              if (response.status === 401) {
                toastr.error("Error", "Datos incorrectos")
              } else {
                genericError()
              }

              actions.setSubmitting(false)
            })
          }}
          validationSchema={this.validationSchema()}
        >
          {({handleChange, handleSubmit, handleBlur, values, errors, isSubmitting}) =>
            <div className='login-container'>
              <div className="logo-wrapper">
                <img alt="logo" src={logo} />
              </div>
              <form onSubmit={handleSubmit}>
                <Text
                  name='user'
                  label='DNI'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                />
                <Text
                  name='password'
                  label='Contraseña'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  type='password'
                />
                <a href="#password-forgot" className="forgot-password" onClick={this.toggleModalRecovery}>
                  He olvidado mi contraseña
                </a>
                <button className='btn' type='submit' disabled={isSubmitting}>
                  Entrar
                </button>
              </form>
            </div>
          }
        </Formik>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeOut'
          animateOnMount={false}
          className='faster modal-wrapper'
          isVisible={this.state.modalRecoveryVisible}>
          <ModalRecovery modalType='recovery' toggleModal={this.toggleModalRecovery} />
        </Animated>
      </div>
    )
  }
}


export default connect()(Login)
