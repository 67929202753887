import {
	GET_CART_BY_USER,
	ADD_CART_PRODUCT,
	DELETE_CART_PRODUCT,
	CREATE_NEW_CART,
	DELETE_CURRENT_CART,
	RESET_CURRENT_CART,
	SET_CART_DELIVERY_DATE
} from '../constants/action-types'

const initialState = {
	currentCart: {}
}

const CartReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case ADD_CART_PRODUCT:
		case GET_CART_BY_USER:
		case DELETE_CART_PRODUCT:
		case CREATE_NEW_CART:
		case DELETE_CURRENT_CART:
		case RESET_CURRENT_CART:
		case SET_CART_DELIVERY_DATE:
			return {
				...state,
				currentCart: action.currentCart,
			}
		default:
			return state
	}
}

export default CartReducer
