import React, { Component } from 'react'
import { FEATURE_PREDOMINANCE_ID } from '../../constants/constants'
import Tastes from './Tastes'
import Effect from './Effect'


export default class Effects extends Component {

  get predominance() {
    const { product, productFeatures } = this.props

    if (!product || !product.associations) {
      return ''
    }

    const { associations: { product_features } } = product

    if (!product_features) {
      return ''
    }

    const pf = product_features.find(({ id }) =>
      parseInt(id, 10) === FEATURE_PREDOMINANCE_ID
    )

    if (typeof pf !== 'object') {
      return ''
    }

    const result = productFeatures.values.all[pf.id_feature_value]

    if (typeof result !== 'object') {
      return ''
    }

    return result.value
  }

  render() {
    const { product } = this.props

    if (parseInt(product.type_witproduct, 10)) {
      return null
    }

    let effects = {}
    Object.keys(product).forEach((key) => {
      const title = key.match(/^titulo_efecto_([0-9]{1})/)
      if (title && title.length) {
        const [,id] = title
        const value = product[key]
        if (value && value.length) {
          if (!effects[id]) {
            effects[id] = {}
          }
          effects[id].title = value
        }
      }

      const value = key.match(/^valor_efecto_([0-9]{1})/)
      if (value && value.length) {
        const [,id] = value
        const val = product[key]
        if (val && val.length) {
          effects[id].value = val
        }
      }
    })

    return (
      <div className='variety-fx active'>
        <div className='variety-fx-data'>
          <p>{this.predominance}</p>
          {
            Object.values(effects).map((effect, index) =>
              <Effect
                {...effect}
                index={index}
                key={`effect-${index}`}
              />
            )
          }
        </div>
        <Tastes {...this.props} />
      </div>
    )
  }
}
