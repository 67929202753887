import { connect } from 'react-redux'

import Course from '../components/User/Course'

const mapStateToProps = (state) => ({
  customer: state.customers.customer,
  error: state.customers.error,
})

const CourseContainer = connect(mapStateToProps)(Course)


export default CourseContainer
