import {
  CMS_FETCH_ALL,
  CMS_FETCH_ALL_ERROR,
  CMS_FETCH_ALL_SUCCESS,
  CMS_MARK_AS_READ,
} from '../constants/action-types'
import Api from '../helpers/Api'
import store from 'store2'
import { checkSessionData } from './api'

export const fetchAllCmsAction = () => ({
  type: CMS_FETCH_ALL,
})

export const fetchAllCmsErrorAction = (error) => ({
  type: CMS_FETCH_ALL_ERROR,
  error,
})

export const fetchAllCmsSuccessAction = ({ content_management_system }) => ({
  type: CMS_FETCH_ALL_SUCCESS,
  cms: content_management_system,
})

export const markAsRead = (id) => ({
  type: CMS_MARK_AS_READ,
  id,
})

export const fetchAll = () => (dispatch, getState) => {
  dispatch(fetchAllCmsAction())

  const key = store.get('_key')

  return Api.get('/content_management_system', {
    params: {
      display: 'full',
      'filter[active]': 1,
      key,
    },
  })
  .then((data) => dispatch(fetchAllCmsSuccessAction(data.data)))
  .catch(checkSessionData)
  .catch((error) => dispatch(fetchAllCmsErrorAction(error)))
}
