import { API_SET_AUTH_KEY } from '../constants/action-types'

const defaultState = {
  key: '',
}

const onAuthKeySet = (state, {key}) => ({
  ...state,
  key,
})

const APIReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case API_SET_AUTH_KEY:
      return onAuthKeySet(state, action)
    default:
      return state
  }
}

export default APIReducer
