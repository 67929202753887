import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<ul className="footer-container">
					<li><Link to="/privacidad-aviso-legal">Política de Privacidad y Aviso Legal</Link> | </li>
					<li><Link to="/politica-cookies">Política de Cookies</Link> | </li>
					<li><Link to="/condiciones-generales-reserva">Condiciones Generales de Reserva</Link></li>
				</ul>
			</div>
		);
	}
}

export default Footer;