import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'

import * as Yup from 'yup'
import moment from 'moment'
import Axios from 'axios'
import { Formik, Field } from 'formik'

import { NIF_IMAGE_MAX_FILESIZE, NIF_IMAGE_ALLOWED_MIME_TYPES } from '../../../constants/constants'
import { ProperApi } from '../../../helpers/Api'
import { API_URL_FORMS } from '../../../helpers/api-config'
import RadioButtons from '../../Common/Forms/RadioButtons'
import Text from '../../Common/Forms/Text'
import DateSelect from '../../Common/Forms/DateSelect'
import ImageInput from './ImageInput'

const validateFileNotEmpty = (value) => value
const validateFileSize = (value) => value && value.size <= NIF_IMAGE_MAX_FILESIZE
const validateFileType = (value) => value && NIF_IMAGE_ALLOWED_MIME_TYPES.includes(value.type)

Yup.setLocale({
  mixed: {
    required: 'Este campo no puede quedar vacío',
  },
})

export default class CourseForm extends Component {
  fields = {
    firstname: '',
    lastname: '',
    dni: '',
    address: '',
    postcode: '',
    birthday: '',
    previsio_consum: '',
    nif_front: '',
    nif_back: '',
  }

  render() {
    const { customer } = this.props
    const baseSchema = {}

    Object.keys(this.fields).forEach((field) => {
      baseSchema[field] = Yup.string().required()
    })
    const q = [1,2,5,6,8]
    for (const i of q) {
      baseSchema[`respuesta_${i}`] = Yup.string().required()
    }
    baseSchema.birthday = Yup.date().required()
    baseSchema.nif_front = Yup.mixed()
      .test('empty', 'Debes seleccionar un fichero', validateFileNotEmpty)
      .test('fileType', 'Sólo se admiten formatos de imagen válidos.', validateFileType)
      .test('fileSize', 'Fichero demasiado grande', validateFileSize)
    baseSchema.nif_back = baseSchema.nif_front

    const Schema = Yup.object().shape(baseSchema)

    return (
      <div className='course-form-wrapper'>
        <h4>Cuestionario</h4>
        <Formik
          initialValues={this.fields}
          validationSchema={Schema}
          onSubmit={(values, { setSubmitting }) => {
            let { birthday } = values
            const form = new FormData()
            form.append('formDniFiles[]', values.nif_front, values.nif_front.name)
            form.append('formDniFiles[]', values.nif_back, values.nif_back.name)

            const groups = [];
            if (customer.associations && customer.associations.groups && customer.associations.groups.length > 0) {
              customer.associations.groups.forEach((group, index) => {
                groups.push({
                  id: group.id
                });
              });
              customer.associations.groups = {
                group: groups
              }
            }
            const answers = []
            for (let i = 1; i <= 9; i++) {
              const val = values[`respuesta_${i}`]
              if (val && val.length) {
                answers.push(`Resposta ${i}: ${val}`)
              }
            }
            let query = {
              ...values,
            }
            query.birthday = moment(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
            delete query.nif_back
            delete query.nif_front

            query = {
              ...customer,
              ...query,
              curs_fet: 1,
              respostes_test: answers.join(', '),
            }

            ProperApi
              .put(`/signup/${customer.id}`, query)
              .then(() => Axios.post(`${API_URL_FORMS}?action=curso`, form, {
                params: {id: customer.id},
                headers: {
                  'accept': 'application/json',
                  'Accept-Language': 'en-US,en;q=0.8',
                  'Content-Type': `multipart/form-data; boundary=${form._boundary}`
                },
              }))
              .then(() => {
                toastr.success('Recibido!')
                window.location.reload()
              })
              .catch((error) => {
                console.error(error.response)
                toastr.error('Ha habido un error procesando la petición. Revisa los datos y si sigue fallando vuelve a intentarlo más tarde.')
                if (error.response.data && error.response.data.errors && error.response.data.errors.length) {
                  error.response.data.errors.forEach((err) => toastr.error(err.message))
                }
                setSubmitting(false)
              })
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setValues,
            ...formik //, this trailing coma makes the CI compiler fail, although it works locally..
          }) => (
            <form onSubmit={handleSubmit}>
              <p>¿Por qué nos unimos como asociación?</p>
              <RadioButtons name='respuesta_1' errors={errors} onChange={handleChange}>
                {
                  [
                    {
                      value: 'a',
                      label: 'Para poder defender nuestros derechos como consumidores de cánnabis',
                    },
                    {
                      value: 'b',
                      label: 'Para fomentar el mercado negro',
                    },
                    {
                      value: 'c',
                      label: 'Para reducir los riesgos asociados al consumo',
                    },
                  ]
                }
              </RadioButtons>
              <p>¿Puedo sacar el cánnabis de mi domicilio?</p>
              <RadioButtons name='respuesta_2' errors={errors} onChange={handleChange}>
                {
                  [
                    {
                      value: 'a',
                      label: 'Sí, no hay ningún problema pues es legal',
                    },
                    {
                      value: 'b',
                      label: 'No, está completamente prohibido e implica la inmediata expulsión de la asociación y denuncia a las autoridades',
                    },
                  ]
                }
              </RadioButtons>
              <p>¿Qué significa que hacemos un autoabastecimiento compartido del cannabis?</p>
              <RadioButtons name='respuesta_5' errors={errors} onChange={handleChange}>
                {
                  [
                    {
                      value: 'a',
                      label: 'Que de forma colectiva y solidaria nos proveemos de nuestra previsión de consumo',
                    },
                    {
                      value: 'b',
                      label: 'Que hacemos el consumo de forma común',
                    },
                    {
                      value: 'c',
                      label: 'Que garantizamos hacer uso y consumo en el ámbito privado',
                    },
                  ]
                }
              </RadioButtons>
              <p>¿Dónde podré recibir las retiradas?</p>
              <RadioButtons name='respuesta_6' errors={errors} onChange={handleChange}>
                {
                  [
                    {
                      value: 'a',
                      label: 'Única y exclusivamente en mi domicilio',
                    },
                    {
                      value: 'b',
                      label: 'En cualquier dirección',
                    },
                    {
                      value: 'c',
                      label: 'En el punto de información',
                    },
                  ]
                }
              </RadioButtons>
              <p>¿Podré avalar a otro socio?</p>
              <RadioButtons name='respuesta_8' errors={errors} onChange={handleChange}>
                {
                  [
                    {
                      value: 'a',
                      label: 'Sí, mediante la propia web',
                    },
                    {
                      value: 'b',
                      label: 'Sí, mediante la web siempre que cumpla con los requisitos para ser socio y existan plazas libres',
                    },
                  ]
                }
              </RadioButtons>
              <hr />
              <p>
                Estamos a punto de finalizar, ya solo nos queda que nos indiques
                tus datos personales y la previsión de consumo mensual que necesitas.
              </p>
              <h4>Datos Personales</h4>
              <Text
                name='firstname'
                label='Nombre'
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
              />
              <Text
                name='lastname'
                label='Apellidos'
                onChange={handleChange}
                values={values}
                errors={errors}
              />
              <Text
                name='dni'
                label='DNI'
                onChange={handleChange}
                values={values}
                errors={errors}
              />
              <Text
                name='address'
                label='Domicilio Particular'
                onChange={handleChange}
                values={values}
                errors={errors}
              />
              <Text
                name='postcode'
                label='Código Postal'
                onChange={handleChange}
                values={values}
                errors={errors}
              />
              <DateSelect
                name='birthday'
                label='Fecha de nacimiento'
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
              />
              <hr />
              <h4>Previsión de consumo</h4>
              <p>
                Solicito participar en la cantidad de los siguientes gramos al mes (máximo 60g):
              </p>
              <Text
                name='previsio_consum'
                label='Previsión de consumo'
                type='number'
                max='60'
                min='1'
                step='1'
                onChange={handleChange}
                values={values}
                errors={errors}
              />
              <h4>SUBE TU DNI</h4>
              <p>Sube una foto del anverso de tu documento de identidad, sea tanto DNI, NIE o Pasaporte, dónde se pueda distinguir la información</p>
              <Field
                name='nif_front'
                component={ImageInput}
                setFieldValue={setFieldValue}
                errors={errors}
              />

              <p>Sube tambien una foto del reverso de tu documento de identidad</p>
              <Field
                name='nif_back'
                component={ImageInput}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <button className='btn' type='submit' disabled={isSubmitting}>
                Enviar curso
              </button>
            </form>
          )}
        </Formik>
      </div>
    )
  }
}
