import React, { Component } from "react";

import Footer from "../Footer/Footer";

import "./Footer.css";

class PrivacyLegal extends Component {
	render() {
		return (
			<div className="wit-page">
				<div className="page-title standalone">
					<p>PRIVACIDAD Y AVISO LEGAL</p>
				</div>

				<div className="legal-container">
					<h2>PROTECCIÓN DE DATOS ASOCIACIÓN CANNÁBICA WIT</h2>
					<p>
						La asociación garantiza a los usuarios de la página web que cumple con la Ley Orgánica de Protección de Datos y Garantía de los Derechos Digitales, y que ha establecido las medidas de seguridad exigidas por las leyes y reglamentos destinados a preservar el secreto, confidencialidad e integridad en el tratamiento de sus datos personales.<br /><br />
						Dichos datos personales serán utilizados en la forma y con las limitaciones y derechos que concede la protección de datos de carácter personal.<br /><br />
						La recogida y tratamiento de los datos personales solicitados tiene como <u>Finalidad</u>: la atención al usuario, tanto administrativa como comercial. <u>Legitimación</u>: La base legal para el tratamiento de sus datos personales es el consentimiento que presta al aceptar esta Política de Privacidad antes de enviarnos/ facilitarnos sus datos. <u>Destinatarios</u>: no se comunicará sus datos a ningún tercero, salvo que se le informe de ello expresamente o exista obligación legal.  Asociación Cannábica Wit se compromete a guardar la máxima reserva, secreto y confidencialidad sobre la información de los datos personales que tiene a su disposición. <u>Duración</u>: Sus datos, serán conservados el tiempo mínimo necesario para satisfacer la finalidad para la cual los facilitó así como para atender las responsabilidades que se pudieran derivar de los datos suministrados y de cualquier otra exigencia legal. <u>Derechos</u>: El interesado es titular de los derechos de acceso, rectificación, supresión, limitación y oposición al tratamiento de sus datos personales, así como el derecho a la portabilidad de los mismos. Asimismo, en cualquier momento el interesado puede retirar el consentimiento prestado sin que ello afecte a la licitud del tratamiento, así como la posibilidad de acudir ante la autoridad de control competente (AEPD).
					</p>

					<h2>AVISO LEGAL LSSI – CE</h2>
					<p>
						En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), la entidad Asociación Cannábica Wit le informa que es titular del sitio web. De acuerdo con la exigencia del artículo 10 de la citada Ley, informa de los siguientes datos:<br /><br />
						El titular de este sitio web es: Asociación Cannábica Wit<br />
						NIF: G67373373<br />
						Domicilio social: Plaza Tetuán 40 1º Puerta 15, 08010 Barcelona (Barcelona)
					</p>

					<h2>USUARIOS</h2>
					<p>
						El acceso y/o uso de este portal le atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento.
					</p>

					<h2>USO DEL PORTAL</h2>
					<p>
						La página web proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a Asociación Cannábica Wit o a sus licenciantes a los que el USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos.<br />
						En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma. El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios que Asociación Cannábica Wit ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para:
						<ul>
							<li>incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público</li>
							<li>difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos</li>
							<li>provocar daños en los sistemas físicos y lógicos de Asociación Cannábica Wit, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados;</li>
							<li>intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar manipular sus mensajes. Asociación Cannábica Wit se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación. En cualquier caso, Asociación Cannábica Wit no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación.</li>
						</ul>
					</p>

					<h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
					<p>
						Asociación Cannábica Wit será titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).<br /><br />
						Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Asociación Cannábica Wit. El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Asociación Cannábica Wit. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el las páginas de Asociación Cannábica Wit.
					</p>

					<h2>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h2>
					<p>
						Asociación Cannábica Wit no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
					</p>

					<h2>MODIFICACIONES</h2>
					<p>
						Asociación Cannábica Wit se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su portal.
					</p>

					<h2>ENLACES</h2>
					<p>
						En el caso de que en la página web se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, Asociación Cannábica Wit no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso Asociación Cannábica Wit asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.
					</p>

					<h2>DERECHO DE EXCLUSIÓN</h2>
					<p>
						Asociación Cannábica Wit se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.
					</p>

					<h2>GENERALIDADES</h2>
					<p>
						Asociación Cannábica Wit perseguirá el incumplimiento de las presentes condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.
					</p>

					<h2>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h2>
					<p>
						Asociación Cannábica Wit podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen.<br />
						La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
					</p>

					<h2>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
					<p>
						La relación entre Asociación Cannábica Wit y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad anteriormente indicada.
					</p>
				</div>

				<Footer />
			</div>
		);
	}
}

export default PrivacyLegal;