import { connect } from 'react-redux'

import { fetchTomorrowSchedule } from '../actions/cart'
import Delivery from '../components/Cart/DeliveryButton'
import { tomorrow } from '../constants/globals'

const mapStateToProps = (state) => ({
  text: 'Mañana',
  user: state.UserDetailsReducer.userDetails || {},
  schedules: state.cart.schedules.tomorrow,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetch: (postcode) => fetchTomorrowSchedule(postcode),
  date: () => tomorrow(),
})

const TomorrowDelivery = connect(mapStateToProps, mapDispatchToProps)(Delivery)


export default TomorrowDelivery
