import React, { Component } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "react-accessible-accordion/dist/fancy-example.css";
import "./About.css";

class About extends Component {
	render() {
		return (
			<div className="wit-page">
				<Header {...this.props} />

				<div className="page-title">
					<p>WIT</p>
				</div>

				<div className="accordion-about">
					<p className="disclaimer">
						WIT somos una Asociación Cannábica que nace de las necesidades de los usuarios de cannabis de garantizar nuestro derecho a la Salud y poder consumir cannabis de calidad sin adulteraciones y con controles estrictos de la calidad del producto, con el fin de reducir los riesgos asociados a nuestro consumo.<br /><br/>
						La Asamblea Fundacional celebrada el día 24/5/19 decidió por unanimidad de los socios por el momento no tener un club sino poder efectuar las retiradas y consumirlas dentro del ámbito de nuestro domicilio privado asumiendo de forma responsable todos los socios cumplir con la normativa interna y cumplir con la legalidad vigente y con los compromisos adquiridos mediante la firma de la solicitud de admisión.<br /><br />
						A través de WIT podemos disfrutar del cannabis en nuestro domicilio, minimizando los riesgos asociados al consumo y de velar por nuestra salud y consumirlo de forma responsable.
					</p>

					<h4>NORMAS DE PARTICIPACIÓN</h4>
					<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PARTICIPACIÓN ACTIVA</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									La estructura de la asociación permite implicarse de forma que todos compartamos los mismo objetivos y actuemos con la misma responsabilidad.<br />
									A través de la asamblea virtual nos reunimos una vez al mes para debatir  y decidir de forma democrática, transparente y horizontal las pautas de nuestra actividad y seguir cooperando para el desarrollo del modelo asociativo de ususarios de cánnabis en defensa de nuestros Derechos Fundamentales.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>CONSUMO RESPONSABLE Y REDUCCIÓN DE RIESGOS</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Colaboramos con profesionales del sector en materia de reducción de riesgos, y contamos con herramientas para poder mejorar nuestros hábitos:
									<ul>
										<li>Carta de variedades de calidad analizadas por laboratorios independientes certificados.</li>
										<li>El cannabis se mantiene siempre en un circuito cerrado de socios, con una trazabilidad total.</li>
										<li>Información detallada sobre las variedades para poder conocer los efectos y decidir en función de ellos.</li>
										<li>Planificación del consumo mediante las reservas previas evitando así hábitos impulsivos.</li>
										<li>Análisis de la concentración de cannabinoides, pestcidas y metales pesados con el fin de descartar productos que superen los límites permitidos.</li>
										<li>Historial de consumo.</li>
										<li>Actividades colectivas para prevención de riesgos e información sobre las propiedades, contraindicaciones y efectos secundarios.</li>
										<li>Asesoramiento personalizado a socios con consumo problemático por parte del personal con formación certificada o de profesionales externos.</li>
									</ul>
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>RESPONSABILIDAD PERSONAL</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Los socios de WIT somos conscientes que el cannabis, con malos hábitos de consumo, puede tener efectos perjudiciales para la salud y nos responsabilizamos del cumplimiento de la normativa interna y de la legalidad vigente.<br />
									Todos los socios deben colaborar y velar por el cumplimiento de la normativa interna.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>CONSUMO EN EL DOMICILIO</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									En WIT queremos disfrutar los efectos del cannabis en el ambiente tranquilo y privado de nuestro domicilio particular, fuera de la vista de terceras personas.<br />
									A través de un sistema de auto-envíos privados, todos podemos retirar el cannabis en nuestra casa de manera segura, y totalmente privada.<br />
									El cannabis no podrá salir del interior de nuestro domicilio, en caso de incumplimiento de esta normativa el socio será expulsado y denunciado ante las autoridades policiales.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>ADMISIÓN DE SOCIOS</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Sólo se admitirán nuevos miembros si hay plazas disponibles y cumplen todos los requisitos.<br />
									Una vez cada 15 días la Junta Directiva aprobará los nuevos socios mediante petición expresa de la Asamblea General, que posteriormente serán aprobados por todos en la asamblea mensual.<br />
									Para garantizar su idoneidad, tras el aval recomendado por un socio, deberán completar el curso formativo demostrando que son consumidores responsables y que concuerdan con los objetivos de la asociación.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PRORGRAMA DE AUTOABASTECIMIENTO Y ACCESO AL CANNABIS</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Nuestra actividad se basa en el autoconsumo de forma transparente, de este modo cada mes revisamos y aprobamos en asamblea la cantidad necesaria para auto-abastecernos, según las previsiones de consumo y bajo la solicitud de cada participante para el periodo concreto del mes siguiente.<br />
									Una vez hacemos nuestra previsión lo vamos retirando gradualmente y conforme al programa de reducción de riesgos de forma responsable.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PROGRAMA PARA SOCIOS TERAPÉUTICOS</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Nuestro modelo es especialmente adecuado para los socios que consumen con fines terapéuticos y que tienen problemas de movilidad.<br />
									Para que éstos puedan disponer de los servicios que necesitan, el resto hacemos un esfuerzo un poco mayor en las cuotas y asumimos parte de su coste para que tengan un 10% de descuento en todas sus cuotas.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>LEGALIDAD</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									El objetivo principal de la Asociación WIT es garantizar a nuestros socios una práctica legal y responsable hacia el resto de sociedad.<br />
									Los socios de WIT contamos con el asesoramiento de profesionales y expertos de múltiples áreas y trabajamos en colaboración diariamente para que el modelo de cultivo compartido tenga cabida en nuestra sociedad de forma legal y en el más estricto ámbito privado.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>PREVISIÓN DE CONSUMO</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>
									Las previsiones de consumo de todos los socios se incorporarán al programa mensual de acceso al cannabis aprobado en asamblea, de manera que tendremos la certeza de qué cantidad necesitamos auto-proveernos de manera mensual.<br />
									El máximo permitido por socio es de 60gr. mensuales, 3 gramos diarios, pudiendo retirar hasta 15 gramos de una sola vez pero en este caso deberemos esperar 5 días para volver a retirar.
								</p>
							</AccordionItemPanel>
						</AccordionItem>
					</Accordion>
				</div>

				<Footer />
			</div>
		);
	}
}

export default About;
