import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import bcrypt from "bcryptjs"
import { toastr } from "react-redux-toastr"
import store from "store2"

import { editUser } from "../../actions/userdetails-actions"
import { scrollStepTop } from "../../helpers/Utils"
import API from "../../helpers/Api"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import "./User.css"

class User extends Component {
	constructor(props) {
		super(props)

		this.state = {
			inputEmail: '',
			inputPhone: '',
			inputPasswordCurrent: '',
			inputPasswordNew: '',
			inputPasswordConfirm: '',
			errorInputEmail: '',
			errorInputPhone: '',
			errorInputPasswordCurrent: '',
			errorInputPasswordNew: '',
			defaultFormDataLoaded: false
		}

		this.onChangeEmail = this.onChangeEmail.bind(this)
		this.onChangePhone = this.onChangePhone.bind(this)
		this.onChangePasswordCurrent = this.onChangePasswordCurrent.bind(this)
		this.onChangePasswordNew = this.onChangePasswordNew.bind(this)
		this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)
		this.editCurrentUser = this.editCurrentUser.bind(this)
	}

	componentDidMount() {
		this.setState(state => ({
			defaultFormDataLoaded: false
		}))
	}

	componentDidUpdate() {
		if (this.props.userDetails.addresses
			&& this.props.userDetails.addresses.id
			&& !this.state.defaultFormDataLoaded) {
			this.setState(state => ({
				inputEmail: this.props.userDetails.email,
				inputPhone: this.props.userDetails.addresses.phone,
				defaultFormDataLoaded: true
			}))
		}
	}

	onChangeEmail(event) {
		const eventValue = event.target.value
		this.setState(state => ({
			inputEmail: eventValue
		}))
	}

	onChangePhone(event) {
		const eventValue = event.target.value
		this.setState(state => ({
			inputPhone: eventValue
		}))
	}

	onChangePasswordCurrent(event) {
		const eventValue = event.target.value
		this.setState(state => ({
			inputPasswordCurrent: eventValue
		}))
	}

	onChangePasswordNew(event) {
		const eventValue = event.target.value
		this.setState(state => ({
			inputPasswordNew: eventValue
		}))
	}

	onChangePasswordConfirm(event) {
		const eventValue = event.target.value
		this.setState(state => ({
			inputPasswordConfirm: eventValue
		}))
	}

	editCurrentUser() {
		let userDetails = {...this.props.userDetails}
		let userDetailsAddress = {...this.props.userDetails.addresses}
		delete userDetails.addresses

		// update email & phone
		if (this.state.inputEmail !== '') {
			userDetails.email = this.state.inputEmail
		} else {
			// error empty field
			this.setState(state => ({
				errorInputEmail: 'La dirección de correo es obligatoria',
				errorInputPhone: '',
				errorInputPasswordCurrent: '',
				errorInputPasswordNew: ''
			}))
			scrollStepTop(20, 80)
			return
		}

		if (this.state.inputPhone !== '') {
			userDetailsAddress.phone = this.state.inputPhone
		} else {
			// error empty field
			this.setState(state => ({
				errorInputEmail: '',
				errorInputPhone: 'El número de teléfono es obligatorio',
				errorInputPasswordCurrent: '',
				errorInputPasswordNew: ''
			}))
			scrollStepTop(20, 80)
			return
		}

		// update password
		if (this.state.inputPasswordCurrent !== ''
			|| this.state.inputPasswordNew !== ''
			|| this.state.inputPasswordConfirm !== '') {
			if (bcrypt.compareSync(this.state.inputPasswordCurrent, userDetails.passwd)) {
				if (this.state.inputPasswordNew !== ''
					&& this.state.inputPasswordConfirm !== ''
					&& this.state.inputPasswordNew === this.state.inputPasswordConfirm) {
					userDetails.passwd = this.state.inputPasswordNew
				} else {
					// error match new passwords
					this.setState(state => ({
						errorInputEmail: '',
						errorInputPhone: '',
						errorInputPasswordCurrent: '',
						errorInputPasswordNew: 'La nueva contraseña no coincide'
					}))
					return
				}
			} else {
				// error current password
				this.setState(state => ({
					errorInputEmail: '',
					errorInputPhone: '',
					errorInputPasswordCurrent: 'La contraseña actual es incorrecta',
					errorInputPasswordNew: ''
				}))
				return
			}
		}

		// SEND
		// reset state errors
		this.setState(state => ({
			errorInputEmail: '',
			errorInputPhone: '',
			errorInputPasswordCurrent: '',
			errorInputPasswordNew: '',
			inputPasswordCurrent: '',
			inputPasswordNew: '',
			inputPasswordConfirm: ''
		}))

		// parse user groups for XML
		let userGroups = []
		if (userDetails.associations && userDetails.associations.groups && userDetails.associations.groups.length > 0) {
			userDetails.associations.groups.forEach((group, index) => {
				userGroups.push({
					id: group.id
				})
			})
			userDetails.associations.groups = {
				group: userGroups
			}
    }
    const key = store.get('_key')

		// check if email do not exists
		API.get('/customers?filter[email]=' + userDetails.email, {params: {key}})
			.then(res => {
				if (res.data.length === 0 || userDetails.email === this.props.userDetails.email) {
					// api call
					this.props.editUser(localStorage.getItem('_userId'), userDetails, userDetailsAddress)
						.then(res => {
							if (res.errors && res.errors.length > 0) {
								if (res.errors[0].message.includes('email')) {
									toastr.error('La dirección de correo no es válida')
								} else if (res.errors[0].message.includes('phone')) {
									toastr.error('El número de teléfono no es válido')
								} else if (res.errors[0].message.includes('password')) {
									toastr.error('La contraseña no es válida')
								} else {
									toastr.error('Por favor, revisa que los campos sean correctos')
								}
							} else {
								toastr.success('Los datos se han actualizado correctamente')
								scrollStepTop(20, 80)
							}
						})
				} else {
					this.setState(state => ({
						errorInputEmail: 'La dirección de correo ya existe',
						errorInputPhone: '',
						errorInputPasswordCurrent: '',
						errorInputPasswordNew: ''
					}))
					scrollStepTop(20, 80)
					return
				}
			})
	}

	renderAddressAttribute(addressAttribute) {
		if (this.props.userDetails.addresses
			&& this.props.userDetails.addresses.id) {
			if (addressAttribute === 'dni') {
				return this.props.userDetails.addresses.dni
			} else if (addressAttribute === 'address1') {
				return this.props.userDetails.addresses.address1
			} else if (addressAttribute === 'postcode') {
				return this.props.userDetails.addresses.postcode
			} else if (addressAttribute === 'city') {
				return this.props.userDetails.addresses.city
			}
		}

		return ''
	}

	render() {
		return (
			<div className="wit-page">
				<Header {...this.props} />

				<div className="page-title">
					<p>EDITAR TUS DATOS</p>
				</div>

				<div className="user-form-wrapper">
					<div className="details-form">
						<h4>INFORMACIÓN DE SOCIO</h4>

						<label htmlFor="address-dni">DNI</label>
						<input disabled type="text" name="address-dni" value={this.renderAddressAttribute('dni')} />
						<span className="error-msg"></span>

						<div className="input-form-2col">
							<div className="input-form-col">
								<label htmlFor="user-firstname">Nombre</label>
								<input disabled type="text" name="user-firstname" value={this.props.userDetails.firstname} />
								<span className="error-msg"></span>
							</div>
							<div className="input-form-col">
								<label htmlFor="user-lastname">Apellidos</label>
								<input disabled type="text" name="user-lastname" value={this.props.userDetails.lastname} />
								<span className="error-msg"></span>
							</div>
						</div>

						<label htmlFor="user-bday">Fecha de nacimiento</label>
						<input disabled type="text" name="user-bday" value={this.props.userDetails.birthday} />
						<span className="error-msg"></span>

						<label htmlFor="user-email">Correo electrónico</label>
						<input type="text" name="user-email" value={this.state.inputEmail} onChange={this.onChangeEmail} />
						<span className="error-msg">{this.state.errorInputEmail}</span>

						<label htmlFor="address-phone">Teléfono de contacto</label>
						<input type="text" name="address-phone" value={this.state.inputPhone} onChange={this.onChangePhone} />
						<span className="error-msg">{this.state.errorInputPhone}</span>
					</div>

					<div className="address-form">
						<h4>DIRECCIÓN ACTUAL</h4>

						<label htmlFor="address-address1">Dirección</label>
						<input disabled type="text" name="address-address1" value={this.renderAddressAttribute('address1')} />
						<span className="error-msg"></span>

						<div className="input-form-2col">
							<div className="input-form-col">
								<label htmlFor="address-postcode">Código postal</label>
								<input disabled type="text" name="address-postcode" value={this.renderAddressAttribute('postcode')} />
								<span className="error-msg"></span>
							</div>
							<div className="input-form-col">
								<label htmlFor="address-city">Ciudad</label>
								<input disabled type="text" name="address-city" value={this.renderAddressAttribute('city')} />
								<span className="error-msg"></span>
							</div>
						</div>
					</div>

					<div className="password-form">
						<h4>CONTRASEÑA</h4>

						<label htmlFor="password-current">Contraseña actual</label>
						<input type="password" name="password-current" value={this.state.inputPasswordCurrent} onChange={this.onChangePasswordCurrent} />
						<span className="error-msg">{this.state.errorInputPasswordCurrent}</span>

						<label htmlFor="password-new">Nueva contraseña</label>
						<input type="password" name="password-new" value={this.state.inputPasswordNew} onChange={this.onChangePasswordNew} />
						<span className="error-msg"></span>

						<label htmlFor="password-confirm">Repite la nueva contraseña</label>
						<input type="password" name="password-confirm" value={this.state.inputPasswordConfirm} onChange={this.onChangePasswordConfirm} />
						<span className="error-msg">{this.state.errorInputPasswordNew}</span>
					</div>

					<div className="instructions">
						<p>
							Hay algunos datos que no se pueden modificar sin la previa aprobación del equipo WIT.<br />
							Si quieres modificar alguno de esos datos, por favor, ponte en contacto con nosotros.<br />
							Puedes hacerlo via teléfono o enviándonos un mensaje desde el <Link to="/contacto">contacto</Link>
						</p>
						<button onClick={this.editCurrentUser}>MODIFICAR DATOS</button>
					</div>
				</div>

				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: state.UserDetailsReducer.userDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		editUser: (userId, userDetails, userDetailsAddress) => {
			return editUser(userId, userDetails, userDetailsAddress, dispatch)() // 'return' for Promise
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
