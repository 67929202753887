import React, { Component } from 'react'

import { fetchAll } from '../../actions/products'
import RemoveProduct from './Remove'
import { formatWeight, formatPrice } from '../../constants/globals'


export default class CartRow extends Component {
  componentDidMount() {
    const { dispatch } = this.props

    if (!this.props.products.loaded) {
      dispatch(fetchAll())
    }
  }

  render() {
    const { row, products, combinations } = this.props
    const product = products.data.all[row.id_product]
    const combination = combinations.data.all[row.id_product_attribute]

    if (!product || !combination) {
      return null
    }

    let weight = 0
    let price = 0
    if (combination.price) {
      price = combination.price * row.quantity
    }
    if (combination.weight) {
      weight = combination.weight * row.quantity
    }

    let priceAndWeight = formatPrice(price)
    if (weight) {
      priceAndWeight += ` / ${formatWeight(weight)}`
    }

    let quantity = null
    if (row.quantity > 1) {
      quantity = (
        <span className='quantity'>
          (x{row.quantity})
        </span>
      )
    }

    return (
      <tr>
        <td>
          <RemoveProduct
            {...this.props}
            combination={combination}
          />
        </td>
        <td>{product.name}{quantity}</td>
        <td>{priceAndWeight}</td>
      </tr>
    )
  }
}
