import React, { Component } from 'react'
import ProgressiveImage from 'react-progressive-image'

import { API_WS_KEY, API_URL } from '../../helpers/api-config'


export default class CategoryImage extends Component {
  render() {
    const { category } = this.props
    if (!category || !category.id) {
      return null
    }

    const image = `${API_URL}/images/categories/${category.id}?ws_key=${API_WS_KEY}`

    return (
      <ProgressiveImage
        src={image}
        placeholder={`${process.env.PUBLIC_URL}/images/weed.jpg`}
      >
        {(src, loading) => (
          <img
            src={src}
            alt={`${category.name}`}
            style={{
              opacity: loading ? .5 : 1,
            }}
          />
        )}
      </ProgressiveImage>
    )
  }
}
