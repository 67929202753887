import React, { Component } from "react";

import Footer from "../Footer/Footer";

import "./Footer.css";

class GeneralConditions extends Component {
    render() {
        return (
            <div className="wit-page">
                <div className="page-title standalone">
					<p>CONDICIONES DE RESERVA</p>
				</div>

                <div className="legal-container">
                    <h2>CONDICIONES DE RESERVA</h2>
					<p>
                        Le invitamos a revisar las condiciones generales de reserva que regulan los productos PLAZA TETUAN 40 1º PUERTA 15, 08010 BARCELONA a través del motor de reservas online incluida en este sitio web.<br /><br />
                        Al usar este sitio web usted queda vinculado a estas condiciones por lo que le recomendamos que las lea detenidamente. Si usted no está de acuerdo con ellas deberá abandonar el sitio web.
					</p>

                    <h2>DISPOSICIONES PRELIMINARES</h2>
                    <p>
                        Las presentes condiciones de reserva son de aplicación para todas las reservas de artículos de la marca ASOCIACION CANABICA WIT, que hayan sido realizadas a través del sitio web http://bcnwit.org (en adelante "sitio web").<br /><br />
                        Al realizar una reserva, usted nos garantiza que es mayor de 21 años y que tiene capacidad legal para cursar contratos vinculantes.<br /><br />
                        Las reservas solamente podrán realizarse para retirar nuestra parte de la previsión de consumo sin ánimo de reventa. Está terminantemente prohibida cualquier reventa o distribución de los productos retirados a través del sitio web.<br /><br />
                        Nos reservamos el derecho de poder anular reservas fraudulentas, falsas o especulativas así como aquellas reservas que hayan sido solicitados por algún socio con el que pueda existir alguna diferencia/controversia en reservas anteriores, o cuándo existan dudas razonables que nos puedan hacer pensar que el socio está violando alguna de las condiciones de reserva especificadas o esté participando en alguna actividad delictiva.<br /><br />
                        Podremos actualizar las presentes condiciones de reserva, así como el aviso legal en cualquier momento. Usted siempre podrá consultar e imprimir la versión actualizada de ambos textos pulsando los vínculos "Condiciones de Reserva" y "Avisos Legales".
                    </p>

                    <h2>OBLIGACIONES DEL USUARIO</h2>
                    <p>
                        Como usuario está obligado a comunicar a ASOCIACION CANABICA WIT todos los datos necesarios para el acceso y utilización de los servicios de este sitio web. Estos deberán ser veraces y actuales y consiente que podremos hacer uso de ellos para ponemos en contacto con usted si es necesario.<br /><br />
                        Es indispensable que facilite toda la información que catalogamos como obligatoria, ya que, de no hacerlo no podremos cursar su reserva. No seremos responsables en ningún caso de posibles retrasos o fallos en la entrega como consecuencia del error u omisión de estos datos.<br /><br />
                        El socio deberá hacer un uso adecuado de los servicios incluidos en el sitio web, siempre de conformidad con el ordenamiento jurídico y no llevará a cabo actividad alguna que dificulte o interfiera en su funcionamiento.
                    </p>

                    <h2>MEDIDAS DE SEGURIDAD</h2>
                    <p>
                        Estableceremos todos los mecanismos razonables a nuestro alcance para garantizar que sus datos personales se conservan con seguridad en todo momento. Sin embargo, usted acepta que ninguna transmisión de datos a través de Internet puede garantizarse como segura frente a accesos por receptores no autorizados y no nos podrá hacer responsables por cualquier fallo de seguridad salvo que se deba a nuestra negligencia.<br /><br />
                        Asimismo, usted deberá adoptar las medidas de seguridad necesarias, tanto personales como materiales, para mantener la confidencialidad de su nombre de usuario y contraseña y notificarnos inmediatamente la pérdida, extravío, sustracción, robo o acceso ilegítimo de su nombre de usuario y contraseña, así como su conocimiento por terceras personas.
                    </p>

                    <h2>CONDICIONES APLICABLES A LAS RESERVAS</h2>
                    <p>
                        Todos los productos que se muestran en la web son gestionados directamente por ASOCIACION CANABICA WIT con CIF G67373373  y domicilio en PLAZA TETUAN 40 1º PUERTA 15, 08010 BARCELONA, a petición de sus socios.<br /><br />
                        Podrá navegar en el sitio web hasta encontrar el artículo deseado. Una vez localizado, pulsando sobre el mismo accederá a toda la información de detalle que le ofrece el sitio web. Desde ahí podrá añadir el artículo a su reserva.<br /><br />
                        Si está realizando una reserva por primera vez con nosotros, es porque un socio le ha avalado y ha superado el curso formativo para ser miembro, entonces ya estará registrado como socio en el sitio web. <br /><br />
                        Tanto en el caso de la primera reserva como en el caso de que se trate de un socio registrado y con una cuenta activa, deberá introducir su dni, nie o pasaporte y contraseña y el sistema reconocerá sus datos.<br /><br />
                        Una vez esté en su cuenta podrá continuar su reserva y finalmente confirmar la hora de retirada.
                    </p>

                    <h2>COBERTURA DE LA OFERTA</h2>
                    <p>
                        La zona de entrega de estos artículos se circunscribe, de momento, a la ciudad de Barcelona. Al aceptar la reserva, usted confirma ser un socio residente en la ciudad d e Barcelona.
                    </p>

                    <h2>DISPONIBILIDAD</h2>
                    <p>
                        Todas las reservas están sujetas a disponibilidad. No nos hacemos responsables de la falta de existencia o de la indisponibilidad de los productos.<br /><br />
                        Si un producto no estuviera disponible una vez realizada la reserva, se le informaría lo antes posible por correo electrónico o por teléfono para modificar la reserva o, en caso contrario, su anulación.<br /><br />
                        Ante cualquier duda, se recomienda ponerse en contacto con el servicio de atención al cliente.
                    </p>

                    <h2>CONTRATO CON ASOCIACIÓN CANÁBICA WIT Y ACEPTACIÓN DE RESERVAS</h2>
                    <p>
                        La reserva no será aceptada por nosotros hasta que el importe haya sido cargado a su cuenta. Tras confirmar la reserva, recibirá un e-mail como acuse de recibo, pero ello no implicará que el pedido haya sido aceptado, ya que estarán sujetos a nuestra posterior comprobación y aceptación.<br /><br />
                        Le confirmaremos la aceptación del pedido en un e-mail en el que le informaremos de que el pedido está siendo enviado. Sólo en ese momento quedará formalizado el contrato entre usted y nosotros, y solamente para los artículos relacionados en dicha confirmación de envío.
                    </p>

                    <h2>CONFIRMACIÓN Y ANULACIÓN DE RESERVAS</h2>
                    <p>
                        Con anterioridad a la finalización de la reserva por su parte en el sitio web, usted podrá revisar todos los detalles asociados al mismo (importes desglosados en todos los epígrafes necesarios - impuestos, costes de transporte, selección de artículos, etc.), pudiendo corregir lo que considere necesario.<br /><br />
                        Con posterioridad a la confirmación no será posible modificar ni anular el pedido a través del sitio web, deberá ponerse en contacto. En tal caso, el pedido sólo podrá ser modificado o anulado si el artículo no ha entrado en fase de expedición desde nuestros almacenes.
                    </p>

                    <h2>MONEDA, IMPUESTOS Y PRECIO</h2>
                    <p>
                        De acuerdo con la legislación vigente, todas las reservas desde nuestro sitio web no estarán sujetas al impuesto sobre el valor añadido, dado que somos una Asociación sin ánimo de lucro.<br /><br />
                        Todos los precios se muestran en euros (€) y deben ser considerados como el precio final a pagar por el socio.
                    </p>

                    <h2>FORMA DE PAGO Y SEGURIDAD</h2>
                    <p>
                        Cualquier pedido realizado debe ser abonado de forma inmediata tras la confirmación de la reserva. Únicamente se cursarán reservas para los que se haya completado y verificado el pago del mismo.<br /><br />
                        El pago debe realizarse mediante tarjeta de crédito y débito (emitida por una entidad con sede social en España) o a contra rembolso.<br /><br />
                        El importe de la reserva se cargará en su tarjeta, de forma que deberá indicarnos el número, la fecha de caducidad así como el código de seguridad CW2 (en el reverso de su tarjeta). En el caso de que su tarjeta esté securizada, se le pedirán los códigos de seguridad pertinentes.<br /><br />
                        Estos datos no serán guardados o manipulados por nosotros, sino que serán registrados directamente en la pasarela de pagos de la entidad financiera correspondiente. Para mayor seguridad, utilizaremos un Servicio de Autenticación de Comercio Electrónico Seguro, sistema establecido por Visa / MasterCard / Eurocard, por el que cliente se autentifica ante su banco emisor y el comercio se autentifica ante su banco adquiriente.<br /><br />
                        El cargo se llevará a cabo en tiempo real a través de esta pasarela de pago. En caso de que el cargo no pueda llevarse a cabo finalmente (por cualquier causa), la reserva quedará anulado automáticamente.
                    </p>

                    <h2>ENTREGAS: TIPOS, PLAZOS Y PROBLEMAS</h2>
                    <p>
                        La entrega podrá realizarse única y exclusivamente en el domicilio personal del socio, no podrá realizarse en ninguna otra dirección. La entrega a domicilio de las reservas será realizada por un operador logístico dentro del horario seleccionado en el sitio web. Si no hay nadie en dicha dirección cuándo el operador logístico acuda, este llamará para confirmar que no está en su domicilio y su departamento de atención al cliente se intentará poner en contacto con usted para cerrar la entrega en otro momento.<br /><br />
                        El plazo de entrega habitual estará aproximadamente entre 2 y 24 horas tras la aprobación del pedido. Este plazo puede aumentar por circunstancias derivadas de (a título informativo únicamente) la distancia geográfica, condiciones climatológicas, guerras, huelgas, cualquier forma de intervención gubernamental, incumplimientos de nuestros proveedores, etc. así como cualquier otra imputable al operador logístico encargado de la entrega del producto. No nos hacemos responsables de los posibles retrasos que puedan originarse por cualquiera de los motivos citados anteriormente.<br /><br />
                        Si, en el momento de la entrega el embalaje del pedido está dañado, deberá abrir el paquete en presencia del operador logístico para verificar el estado de los artículos. Si se observan daños deberá detallarlos en el albarán de entrega y ponerse en contacto. Repararemos o sustituiremos sin coste alguno para usted todos aquellos artículos que hayan sido dañados o perdidos durante el transporte, siempre y cuándo, nos notifique dicha incidencia lo antes posible.<br /><br />
                        Una vez entregado el pedido, pasará a formar parte de su propiedad y, por tanto, todos los daños que puedan originarse serán de su responsabilidad.
                    </p>

                    <h2>POLÍTICA DE DEVOLUCIÓN: CAMBIO Y REEMBOLSO</h2>
                    <p>
                        Derecho de desistimiento:<br />
                        El Cliente dispondrá de un plazo máximo de 14 días naturales desde la entrega del Producto o desde la celebración del contrato si se trata de un servicio, para comunicar su desistimiento total o parcial de la reserva del Producto o servicio, de conformidad con la legislación aplicable. La devolución efectuada en plazo no comportará penalización alguna. No obstante, el Cliente deberá asumir los gastos de envío.<br /><br />
                        Indique los artículos a devolver al servicio de atención al cliente. Deberá esperar que el operador logístico le recoja el/los artículo/s en el embalaje que él mismo le traiga.<br /><br />
                        Una vez haya sido recepcionada la devolución por nosotros, verificaremos que los artículos devueltos físicamente se corresponden con la reserva y adicionalmente que éstos han sido recibidos en perfectas condiciones de uso, completos, en su embalaje original y sin indicios de haber sido usados. Si la comprobación del estado de la reserva es satisfactoria procederemos a reembolsarte el dinero que hubiese pagado en su momento en la misma tarjeta de crédito desde la que realizó el pago, en el plazo máximo de un mes. En caso contrario, nos pondremos en contacto con usted para enviarle de nuevo el artículo (los costes de transporte asociados a este nuevo envío, correrán por su cuenta).
                    </p>

                    <h2>GARANTÍA DE LOS PRODUCTOS</h2>
                    <p>
                        En caso de resultar un defecto provocado por el mal uso, la reparación será facturable previo presupuesto.<br /><br />
                        ASOCIACION CANABICA WIT realizará cambios o devoluciones siempre que:<br />
                        Se dé comunicación previa a nuestro Servicio de Atención al Cliente en el plazo de una semana: Este paso es indispensable sea cual sea el motivo que provoca la devolución. Podrá hacerlo enviándonos un correo electrónico a info@bcnwit.org adjuntando copia de la reserva junto con su código que aparece en el historial en el Asunto del email. Nosotros te indicaremos cómo puedes actuar para la devolución del artículo.<br /><br />
                        El artículo a devolver deberá estar correctamente embalado para su devolución. El embalaje debe encontrarse en perfecto estado y conservar todos sus accesorios e instrucciones de origen para poder ser devuelto.<br /><br />
                        El incumplimiento del plazo de una semana no supondrá la pérdida del derecho de saneamiento que corresponda, siendo responsable el cliente, no obstante, de los daños o perjuicios efectivamente ocasionados por el retraso de la comunicación.
                    </p>

                    <h2>NULIDAD PARCIAL</h2>
                    <p>
                        Si cualquier parte de estas condiciones de servicios fuera contraria a Derecho y, por tanto, inválida, ello no afectará a las otras disposiciones conformes a Derecho. Las partes se comprometen a renegociar aquellos puntos de las condiciones de servicio que resultaran nulos y a incorporarlos al resto de las condiciones de servicio.
                    </p>

                    <h2>MODIFICACIONES DE LAS CONDICIONES GENERALES DE RESERVA</h2>
                    <p>
                        ASOCIACION CANABICA WIT se reserva el derecho a modificar las condiciones generales de reserva sin previo aviso, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que presten a través del mismo como la forma en la que estos parezcan presentados o localizados en sus servidores.<br /><br />
                        Estos cambios deberán de ser aceptados por el usuario cada vez que haga efectiva una reserva a través de la web.<br /><br />
                        Los socios que no estén conformes con las modificaciones de las condiciones generales, deberán notificarlo y, a partir de la fecha en la cual la nueva versión entrara en vigor, deberán de dejar de utilizar los servicios de la web.<br /><br />
                        En el supuesto de que algunos de los términos de las condiciones generales de reserva sean declarados ilegales o no oponibles por una decisión judicial, las demás disposiciones mantendrán su vigencia.
                    </p>

                    <h2>LEY DE APLICABLE Y JURISDICCIÓN</h2>
                    <p>
                        Este sitio se encuentra ubicado y es operador de España. Todas las cuestiones relativas a este sitio se rigen por las leyes españolas y se someten a la jurisdicción de los Juzgados y Tribunales competentes de España, sin que sean de aplicación los principios sobre conflictos de leyes.<br /><br />
                        En aquellos casos en que el socio no tenga la condición de consumidor, o que tenga domicilio fuera de España, se someterá a los Juzgados y Tribunales nacionales, con renuncia expresa a cualquier otro fuero que pudiera corresponderles.<br /><br />
                        Si el socio decide utilizar o consultar este sitio fuera de España, deberá tener presente que lo hace por propia iniciativa, y que es responsable del cumplimiento de las leyes pertinentes.<br /><br />
                        El presente contrato entrará en vigor y surtirá plenos efectos desde el momento en que se produzca la aceptación del socio.<br /><br />
                        El presente contrato, y, por tanto, las condiciones generales que incorpora, juntamente con los anexos (de existir), además de ser leídos en esta web, pueden almacenarse, archivarse y reproducirse por los medios electrónicos o informáticos habituales.
                    </p>
                </div>

                <Footer />
            </div>
        );
    }
}

export default GeneralConditions;