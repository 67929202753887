import React, { Component } from 'react'
import classNames from 'classnames'

export default class Effect extends Component {
  render() {
    const { title, value } = this.props

    const classes = ['variety-fx-item']
    if (typeof this.props.index !== 'undefined') {
      classes.push(`effect-${this.props.index}`)
    }
    if (this.props.className) {
      classes.push(this.props.className)
    }

    return (
      <div className={classNames(classes)}>
        <span>{title}</span>
        <div className='fx-slider'>
          <div style={{width: `${value}%`}} />
        </div>
      </div>
    )
  }
}
