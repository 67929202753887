import React, { Component } from 'react'
import { Route, Switch } from 'react-router'


import { PrivateRoute } from './helpers/PrivateRoute'
import NotFoundPage from './helpers/NotFoundPage'

import Login from './components/Login'
import UserPage from './components/User/User'
import PrevisionPage from './components/User/Prevision'
import PasswordResetPage from './components/User/PasswordReset'
import AboutPage from './components/About/About'
import FaqsPage from './components/Faqs/Faqs'
import ContactPage from './components/Contact/Contact'
import PrivacyLegal from './components/Footer/PrivacyLegal'
import Cookies from './components/Footer/Cookies'
import GeneralConditions from './components/Footer/GeneralConditions'

import Assemblies from './containers/Assemblies'
import Categories from './containers/Categories'
import Cart from './containers/Cart'
import CoursePage from './containers/Course'
import History from './containers/User/History'
import ProductsContainer from './containers/Products'
import EndorsementContainer from './containers/Endorsement'

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={Login} />
        <PrivateRoute exact path='/carta' component={Categories} />
        <PrivateRoute exact path='/carta/:category' component={ProductsContainer} />
        <PrivateRoute exact path='/carta/:category/:product' component={ProductsContainer} />
        <PrivateRoute exact path='/cesta' component={Cart} />
        <PrivateRoute exact path='/usuario' component={UserPage} />
        <PrivateRoute exact path='/historial' component={History} />
        <PrivateRoute exact path='/avalar' component={EndorsementContainer} />
        <PrivateRoute exact path='/prevision' component={PrevisionPage} />
        <PrivateRoute exact path='/asamblea' component={Assemblies} />
        <Route exact path='/curso-de-alta' component={CoursePage} />
        <Route exact path='/recuperar-acceso' component={PasswordResetPage} />
        <PrivateRoute exact path='/sobre-wit' component={AboutPage} />
        <PrivateRoute exact path='/faqs' component={FaqsPage} />
        <PrivateRoute exact path='/contacto' component={ContactPage} />
        <Route exact path='/privacidad-aviso-legal' component={PrivacyLegal} />
        <Route exact path='/politica-cookies' component={Cookies} />
        <Route exact path='/condiciones-generales-reserva' component={GeneralConditions} />
        <Route path='*' component={NotFoundPage} />
      </Switch>
    )
  }
}
