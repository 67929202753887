import React, { Component } from 'react'
import classNames from 'classnames'
import { Animated } from 'react-animated-css'
import { setDeliveryDate, setDeliverySchedule } from '../../actions/cart'

export default class DeliveryButton extends Component {
  state = {
    dropDownVisible: false,
  }

  toggleVisibility() {
    this.setState({dropDownVisible: !this.state.dropDownVisible})
  }

  setSchedule(delivery) {
    const { dispatch, date } = this.props

    dispatch(setDeliveryDate(date()))
    dispatch(setDeliverySchedule(delivery))
  }

  componentDidMount() {
    const { dispatch, fetch, schedules, user } = this.props
    if (!schedules.length) {
      dispatch(fetch(user.addresses.postcode))
    }
  }

  render() {
    const { text, schedules } = this.props

    const className = classNames({
			'active': this.state.dropDownVisible,
			'btn-dropdown': true
    })

    let options = schedules || []

    options = options.map((delivery, index) => (
      <button
        key={index}
        onClick={this.setSchedule.bind(this, delivery)}
      >
        {delivery}
      </button>
    )).filter((val) => val !== null)

    if (!options.length) {
      options.push(
        <button key='no-delivery' disabled>Sin repartos disponibles</button>
      )
    }

    return (
      <div className={className} onClick={this.toggleVisibility.bind(this)}>
        <span>{text}</span>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeOut'
          animateOnMount={false}
          className='faster'
          isVisible={this.state.dropDownVisible}>
          <div className='btn-dropdown-content'>
            {options}
          </div>
        </Animated>
      </div>
    )
  }
}

