import React, { Component, Fragment } from 'react'

import { setField } from '../../actions/cart'

export default class Change extends Component {
  onKeyUp(event) {
    const { dispatch } = this.props

    dispatch(setField('cambio', event.target.value))
  }

  render() {
    if (!this.props.visible) {
      return null
    }

    const min = 1
    const max = 5
    const placeholder = (Math.trunc((Math.random() * 200) % (max - min + 1) + min)) * 100

    return (
      <Fragment>
        <h4>¿Necesitas cambio? Indícanos cuánto aportarás.</h4>
        <div className='form-group'>
          <input
            type='text'
            name='change'
            placeholder={placeholder}
            onKeyUp={this.onKeyUp.bind(this)}
          />
        </div>
      </Fragment>
    )
  }
}
