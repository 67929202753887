import React, { Component } from 'react'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'

import {
  DATE_FORMAT,
} from '../../../constants/constants'
import { canStillMakeChanges } from '../../../helpers/Utils'
import { fetchSchedule } from '../../../actions/cart'
import ScheduleModal from '../../Cart/ScheduleModal'
import CalendarModal from './CalendarModal'
import { changeDeliveryDateTime } from '../../../actions/orders'

export default class ChangeDelivery extends Component {
  state = {
    changeDate: false,
    schedules: [],
    date: null,
    time: null,
  }

  onChangeClick() {
    const { order } = this.props

    const [hour] = order.hora_entrega.split('-');
    const deliveryDate = moment(
      `${order.fecha_entrega} ${hour}`,
      `${DATE_FORMAT} HH:mm`
    )
    const now = moment()

    if (!now.isBefore(deliveryDate) || !canStillMakeChanges(order)) {
      return alert('Lamentablemente ya no puedes hacer cambios en esta reserva.')
    }

    this.setState({
      changeDate: true,
    })
  }

  onCalendarClick(date) {
    const { customer } = this.props
    const deliveryDate = moment(date).format(DATE_FORMAT)

    fetchSchedule(deliveryDate, customer.addresses.postcode).then(({data}) => {
      if (!data.length) {
        return toastr.error('No hay horarios disponibles para la fecha seleccionada')
      }
      this.setState({
        changeDate: false,
        schedules: data,
        date: deliveryDate,
      })
    })
  }

  setDeliveryDateTime(schedule) {
    const { customer, dispatch, order } = this.props

    dispatch(changeDeliveryDateTime(customer, order, this.state.date, schedule))
  }

  render() {
    const { order } = this.props

    if (!order.fecha_entrega?.length || !order.hora_entrega?.length) {
      return null
    }

    const [hour] = order.hora_entrega.split('-');
    const deliveryDate = moment(
      `${order.fecha_entrega} ${hour}`,
      `${DATE_FORMAT} HH:mm`
    )
    const now = moment()

    if (!now.isBefore(deliveryDate) || !canStillMakeChanges(order)) {
      return null
    }

    return (
      <>
        <button onClick={this.onChangeClick.bind(this)} className='btn'>
          Cambiar reparto
        </button>
        <CalendarModal
          visible={this.state.changeDate}
          value={deliveryDate.toDate()}
          onDateSelect={this.onCalendarClick.bind(this)}
        />
        <ScheduleModal
          {...this.props}
          schedules={this.state.schedules}
          emptySchedules={() => this.setState({schedules: []})}
          setDeliverySchedule={this.setDeliveryDateTime.bind(this)}
        />
      </>
    )
  }
}
