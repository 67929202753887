import { connect } from 'react-redux'

import Assembly from '../components/Assembly'

const mapStateToProps = (state) => ({
  assemblies: state.assemblies.data,
  cms: state.cms,
  customer: state.customers.customer,
})

const AssembliesContainer = connect(mapStateToProps)(Assembly)

export default AssembliesContainer
