import React, { Component } from 'react'

import { today, tomorrow, isDateBeforeRightNow, convertToValidDateTime, getFirstTimeInSchedule } from '../../constants/globals'
import TodayDelivery from '../../containers/TodayDelivery'
import TomorrowDelivery from '../../containers/TomorrowDelivery'
import { resetDeliveryDate } from '../../actions/cart'
import Calendar from './DeliveryCalendar'

export default class DeliveryDate extends Component {
  componentDidUpdate() {
    const cart = this.props.cart.data

    if (cart.fecha_entrega) {
      if (isDateBeforeRightNow(convertToValidDateTime(cart.fecha_entrega, getFirstTimeInSchedule(cart.hora_entrega)))) {
        this.props.dispatch(resetDeliveryDate())
      }
    }
  }

  render() {
    const cart = this.props.cart.data

    const rows = cart.associations.cart_rows.cart_row || cart.associations.cart_rows
    if (!cart.associations || (cart.associations && !rows.length)) {
      return null
    }

    const date = cart.fecha_entrega
    const schedule = cart.hora_entrega

    let text = 'Selecciona una fecha de reparto del calendario, hoy o mañana.'

    if (date) {
      text = `El ${date}`
    }

    if (date === today()) {
      text = 'Hoy'
    }

    if (date === tomorrow()) {
      text = 'Mañana'
    }

    if (date && schedule) {
      text += ` de ${schedule}`
    }

    return (
      <div className='cart-delivery cart'>
        <h4>¿CUÁNDO LO QUIERES?</h4>
        <p className='selected-date'>
          {text}
        </p>
        <div className='cart-delivery-options'>
          <Calendar {...this.props} />
          <TodayDelivery {...this.props} />
          <TomorrowDelivery {...this.props} />
        </div>
      </div>
    )
  }
}
