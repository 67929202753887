import React, { Component, Fragment } from 'react'
import { fetchAll, markAsRead } from '../../actions/cms'
import Modal from './Modal'


export default class CMSModals extends Component {
  componentDidMount() {
    if (!this.props.cms.data.ids.length) {
      this.props.dispatch(fetchAll())
    }
  }

  onBtnClick(id) {
    this.props.dispatch(markAsRead(id))
  }

  render() {
    return (
      <Fragment>
        {
          this.props.cms.display.map((id) => (
            <Modal key={`modal-alert-${id}`}>
              <div className='styled-content block-links'>
                <h4>{this.props.cms.data.all[id].meta_title}</h4>
                <div dangerouslySetInnerHTML={{
                  __html: this.props.cms.data.all[id].content
                }} />
                <button className='btn' onClick={this.onBtnClick.bind(this, id)}>Entendido</button>
              </div>
            </Modal>
          ))
        }
      </Fragment>
    )
  }
}
