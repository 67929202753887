import React, { Component } from 'react'

import Modal from '../Common/Modal'

export default class ScheduleModal extends Component {
  onScheduleSelect(schedule) {
    this.props.setDeliverySchedule(schedule)
    this.props.emptySchedules()
  }

  render() {
    const { schedules } = this.props

    if (!this.props.schedules.length) {
      return null
    }

    return (
      <Modal>
        <p>Selecciona la hora de reparto:</p>
        {
          schedules.map((schedule, index) => (
            <button
              key={`schedule-${index}`}
              className='btn'
              onClick={this.onScheduleSelect.bind(this, schedule)}
            >
              {schedule}
            </button>
          ))
        }
      </Modal>
    )
  }
}
