import { actions as tActions } from 'react-redux-toastr'
import Axios from 'axios'

import {
  CART_FETCH,
  CART_FETCH_SUCCESS,
  CART_FETCH_ERROR,
  CART_CREATE,
  CART_PRODUCT_ADD,
  CART_PRODUCT_ADD_SUCCESS,
  CART_PRODUCT_ADD_ERROR,
  CART_PRODUCT_REMOVE,
  CART_PRODUCT_REMOVE_SUCCESS,
  CART_PRODUCT_REMOVE_ERROR,
  CART_CALCULATE,
  CART_CALCULATE_ERROR,
  CART_CALCULATE_SUCCESS,
  CART_CREATE_ERROR,
  CART_CREATE_SUCCESS,
  SCHEDULE_FETCH_TODAY,
  SCHEDULE_FETCH_TODAY_ERROR,
  SCHEDULE_FETCH_TODAY_SUCCESS,
  SCHEDULE_FETCH_TOMORROW,
  SCHEDULE_FETCH_TOMORROW_SUCCESS,
  SCHEDULE_FETCH_TOMORROW_ERROR,
  CART_DELIVERY_DATE_SET,
  CART_DELIVERY_SCHEDULE_SET,
  CART_DELIVERY_DATE_RESET,
  CART_FIELD_SET,
  CART_DELIVERY_SCHEDULE_RESET,
  CART_RESET,
  CART_UPDATE,
  CART_UPDATE_ERROR,
  CART_UPDATE_SUCCESS,
} from '../constants/action-types'
import {
  CURRENCY_ID,
  LANG_ID,
  DAILY_WEIGHT_LIMIT,
  CART_MODIFIED,
} from '../constants/constants'
import {
  json2Xml,
  toInteger,
  getBestDiscount,
  tomorrow,
  today,
} from '../constants/globals'
import Api from '../helpers/Api'
import { API_URL_FORMS } from '../helpers/api-config'
import store from 'store2'

export const fetchAction = (customer, latest) => ({
  type: CART_FETCH,
  customer,
  latest,
})

export const fetchSuccessAction = (cart) => ({
  type: CART_FETCH_SUCCESS,
  cart,
})

export const fetchErrorAction = (error) => ({
  type: CART_FETCH_ERROR,
  error,
})

export const createAction = (customer) => ({
  type: CART_CREATE,
  customer,
})

export const createSuccessAction = (cart) => ({
  type: CART_CREATE_SUCCESS,
  cart,
})

export const createErrorAction = (error) => ({
  type: CART_CREATE_ERROR,
  error,
})

export const addToCartAction = (combination, stock) => ({
  type: CART_PRODUCT_ADD,
  combination,
  stock,
})

export const addToCartSuccessAction = (cart, combination, stock) => ({
  type: CART_PRODUCT_ADD_SUCCESS,
  cart,
  combination,
  stock,
})

export const addToCartErrorAction = (cart) => ({
  type: CART_PRODUCT_ADD_ERROR,
  cart,
})

export const removeFromCartAction = (index) => ({
  type: CART_PRODUCT_REMOVE,
  index,
})

export const removeFromCartSuccessAction = (cart) => ({
  type: CART_PRODUCT_REMOVE_SUCCESS,
  cart,
})

export const removeFromCartErrorAction = (error) => ({
  type: CART_PRODUCT_REMOVE_ERROR,
  error,
})

export const setDeliveryDate = (date) => ({
  type: CART_DELIVERY_DATE_SET,
  date,
})

export const resetDeliveryDate = () => ({
  type: CART_DELIVERY_DATE_RESET,
})

export const resetDeliverySchedule = () => ({
  type: CART_DELIVERY_SCHEDULE_RESET,
})

export const setDeliverySchedule = (schedule) => ({
  type: CART_DELIVERY_SCHEDULE_SET,
  schedule,
})

export const setField = (field, value) => ({
  type: CART_FIELD_SET,
  field,
  value,
})

export const reset = () => ({
  type: CART_RESET,
})

export const verify = () => (dispatch, getState) => {
  dispatch({
    type: 'cart/verify',
  })

  const {
    stocks,
    products,
    cart: { data },
  } = getState()
  const cart = data
  const { data: { byProductAndAttribute } } = stocks

  if (!stocks.loaded) {
    return dispatch({
      type: 'cart/verify::error',
      error: 'Cart information is not properly loaded',
    })
  }

  if (!cart.associations.cart_rows.length) {
    return dispatch({
      type: 'cart/verify::error',
      error: 'There ain\'t products in the cart',
    })
  }

  const rows = [...cart.associations.cart_rows]
  let modified = false

  for (let key in rows) {
    const { id_product, id_product_attribute, quantity } = rows[key]
    const stock = {...byProductAndAttribute[id_product][id_product_attribute]}
    const product = {...products.data.all[id_product]}

    const stockQuantity = toInteger(stock.quantity)
    const orderQuantity = toInteger(quantity)

    if (!stockQuantity || !Object.keys(product).length) {
      // If there's no stock, just remove the item from cart
      delete rows[key]
      modified = true

      continue
    }

    if (stockQuantity && stockQuantity < orderQuantity) {
      modified = true
      const diff = stockQuantity - orderQuantity
      rows[key] = {
        ...rows[key],
        quantity: orderQuantity + diff,
      }
    }
  }

  if (modified) {
    dispatch(tActions.add({
      title: CART_MODIFIED,
      type: 'error',
    }))

    return dispatch(update({
      ...cart,
      associations: {
        ...cart.associations,
        cart_rows: rows.filter((row) => row !== null),
      },
    }))
  }

  return dispatch({
    type: 'cart/verify::success',
  })
}

export const makeCalculations = () => (dispatch, getState) => {
  dispatch({
    type: CART_CALCULATE,
  })

  const {
    products,
    combinations,
    groups,
    UserDetailsReducer: {
      userDetails,
    },
    cart: { data }
  } = getState()
  const cart = data

  let subtotal = 0

  if (!products.loaded || !combinations.loaded) {
    return dispatch({
      type: CART_CALCULATE_ERROR,
      error: 'Products information is not properly loaded',
    })
  }

  cart.associations.cart_rows.forEach(({id_product_attribute, quantity}) => {
    const combination = combinations.data.all[id_product_attribute]
    let price = combination.price
    if (!isNaN(price)) {
      if (typeof price === 'string') {
        price = Number(price)
      }
      subtotal += (price * quantity)
    }
  })

  const userGroups = userDetails.associations.groups.map(({id}) => id)
  const percent = getBestDiscount(userGroups, Object.values(groups.data.all))
  let discount = 0
  let total = subtotal

  if (percent) {
    discount = subtotal * (percent / 100)
    total = subtotal - discount
  }

  dispatch({
    type: CART_CALCULATE_SUCCESS,
    calculated: {
      subtotal,
      total,
      discount: {
        percent,
        amount: discount,
      },
    },
  })
}

export const addToCart = (combination, stock) => (dispatch, getState) => {
  dispatch(addToCartAction(combination, stock))

  const {
    cart: {data},
    combinations,
    UserDetailsReducer: { limits },
  } = getState()

  let rows = []
  if (data.associations && data.associations.cart_rows && data.associations.cart_rows.length) {
    rows = data.associations.cart_rows
  }

  let item = rows.find(({id_product_attribute}) => (
    toInteger(id_product_attribute) === combination.id
  ))
  if (!item) {
    item = {
      id_product: combination.id_product,
      id_product_attribute: combination.id,
      id_address_delivery: data.id_address_delivery,
      quantity: 1,
    }
    rows.push(item)
  } else {
    item.quantity++
    const index = rows.findIndex(({id_product_attribute}) => (
      toInteger(id_product_attribute) === combination.id
    ))

    rows[index] = item
  }

  let weight = 0
  rows.forEach((row) => {
    const combination = combinations.data.all[row.id_product_attribute]
    if (combination && combination.weight) {
      weight += parseFloat(combination.weight) * toInteger(row.quantity)
    }
  })

  const totalConsumption = (parseFloat(limits.consumed) + weight)
  let error = false
  if (weight > DAILY_WEIGHT_LIMIT) {
    error = 'Esta aportación supera el límite de 15 gramos por día.'
  }
  if (totalConsumption > limits.prevision) {
    error = 'Debes actualizar tu previsión de consumo para realizar esta aportación.'
  }
  if (error) {
    dispatch(tActions.add({
      title: error,
      type: 'error',
    }))
    return dispatch(addToCartErrorAction(error))
  }

  const cart = {
    ...data,
    associations: {
      cart_rows: {
        cart_row: rows,
      }
    },
  }

  const key = store.get('_key')

  return Api.put(`/carts/${cart.id}`, json2Xml({cart}), {params: {key}})
    .then((data) => {
      dispatch(tActions.add({
        title: 'Producto añadido correctamente a la cesta',
        type: 'success',
      }))
      return dispatch(addToCartSuccessAction(cart, combination, stock))
    })
    .catch((error) => dispatch(addToCartErrorAction(error)))
}

export const create = (customer) => (dispatch) => {
  dispatch(createAction(customer))

  const cart = {
    id_address_delivery: customer.addresses.id,
    id_customer: customer.id,
    id_currency: CURRENCY_ID,
    id_lang: LANG_ID,
  }

  const key = store.get('_key')

  return Api.post('/carts', json2Xml({cart}), {params: {key}})
    .then(({data}) => dispatch(createSuccessAction(data.cart)))
    .catch((error) => dispatch(createErrorAction(error)))
}

export const fetch = (customer, latest) => (dispatch) => {
  dispatch(fetchAction(customer, latest))

  const key = store.get('_key')

  return Api.get('/carts', {
    params: {
      'filter[id_customer]': customer.id,
      display: 'full',
      sort: '[id_DESC]',
      limit: 1,
      key,
    },
  }).then(({data}) => {
    if (data.carts && data.carts.length) {
      const cart = data.carts.pop()

      if (!latest || (latest && cart.id > latest)) {
        return dispatch(fetchSuccessAction(cart))
      }
    }
    // Cart needs to be created
    return dispatch(create(customer))
  })
  .catch((error) => dispatch(fetchErrorAction(error)))
}

export const fetchSchedule = (date, postcode) =>
  Axios.get(`${API_URL_FORMS}`, {
    params: {
      action: 'horarios',
      dia: date,
      cp: postcode,
    },
  })

export const fetchTodaySchedule = (postcode) => (dispatch) => {
  dispatch({
    type: SCHEDULE_FETCH_TODAY,
  })

  return Axios.get(`${API_URL_FORMS}`, {
    params: {
      action: 'horarios',
      dia: today(),
      cp: postcode,
    },
  })
  .then(({data}) => dispatch({
    type: SCHEDULE_FETCH_TODAY_SUCCESS,
    schedules: data,
  }))
  .catch((error) =>
    dispatch({type: SCHEDULE_FETCH_TODAY_ERROR, error})
  )
}

export const fetchTomorrowSchedule = (postcode) => (dispatch) => {
  dispatch({
    type: SCHEDULE_FETCH_TOMORROW,
  })

  return Axios.get(`${API_URL_FORMS}`, {
    params: {
      action: 'horarios',
      dia: tomorrow(),
      cp: postcode,
    },
  })
  .then(({data}) => dispatch({
    type: SCHEDULE_FETCH_TOMORROW_SUCCESS,
    schedules: data,
  }))
  .catch((error) =>
    dispatch({type: SCHEDULE_FETCH_TOMORROW_ERROR, error})
  )
}

export const removeFromCart = (index) => (dispatch, getState) => {
  dispatch(removeFromCartAction(index))

  let cart = {...getState().cart.data}
  const rows = [...cart.associations.cart_rows]
  // remove item from current cart copy
  delete rows[index]

  cart = {
    ...cart,
    associations: {
      ...cart.associations,
      cart_rows: {
        cart_row: rows,
      },
    },
  }

  const key = store.get('_key')

  return Api.put(`/carts/${cart.id}`, json2Xml({cart}), {params: {key}})
    .then(({data}) => {
      dispatch(removeFromCartSuccessAction(data.cart))

      return dispatch(makeCalculations())
    })
    .catch((error) => dispatch(removeFromCartErrorAction(error)))
}

export const update = (cart) => (dispatch) => {
  dispatch({
    type: CART_UPDATE,
  })

  const data = {...cart}
  const rows = [...data.associations.cart_rows]
  data.associations.cart_rows = {}
  data.associations.cart_rows.cart_row = rows

  const key = store.get('_key')

  return Api.put(`/carts/${cart.id}`, json2Xml({cart: data}), {params: {key}})
    .then(({data}) => {
      dispatch({
        type: CART_UPDATE_SUCCESS,
        cart: data.cart,
      })

      return dispatch(makeCalculations())
    })
    .catch((error) => dispatch({
      type: CART_UPDATE_ERROR,
      error,
    }))
}
