import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { setActive } from '../../actions/products'


export default class ProductsMenu extends Component {
  onClick(id) {
    this.props.dispatch(setActive(id))
  }

  render() {
    const { products, category, active } = this.props

    return (
      <ul className='varieties-nav-list'>
        {
          category.associations.products.map(({id}) => {
            const product = products.data.all[id]
            if (!product) {
              // Non active products are still visible under category sorted array
              return null
            }
            const classes = []
            if (product.id === active) {
              classes.push('active')
            }
            const link = `/carta/${category.link_rewrite}/${product.reference}`
            return (
              <li
                key={`product-menu-${product.id}`}
                className={classNames(classes)}
              >
                <Link to={link} onClick={this.onClick.bind(this, product.id)}>{product.name}</Link>
              </li>
            )
          })
        }
      </ul>
    )
  }
}
