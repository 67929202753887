import React, { Component } from 'react'
import store from 'store2'
import GChart from 'react-google-charts'

import { fetch as fetchCustomer, fetchHistory } from '../../../actions/customers'


export default class Chart extends Component {
  componentDidMount() {
    const { customer, dispatch } = this.props
    if (!customer?.id) {
      dispatch(fetchCustomer(store.get('_userId'))).then(() => {
        dispatch(fetchHistory(this.props.customer))
      })
      return
    }

    dispatch(fetchHistory(customer))
  }

  render() {
    const { historic, loaded } = this.props.customers

    if (loaded && !historic.length) {
      return (
        <p>
          Aquí aparecerá una gráfica siempre y cuando hayas
          hecho aportaciones en el último año
        </p>
      )
    }

    return <GChart
      chartType='ColumnChart'
      loader={<p>Cargando datos...</p>}
      data={historic}
      options={{
        title: '',
        backgroundColor: 'black',
        colors: ['#26b8b4', '#8bf2ed'],
        width: '100%',
        height: 300,
        legend: 'none',
        chartArea: {
          width: '100%',
          height: '80%'
        },
        bar: { groupWidth: '100%' },
        hAxis: {
          textStyle: { color: 'white' }
        },
        vAxis: {
          textStyle: { color: 'white' },
          gridlines: { count: 4 }
        }
      }}
    />
  }
}
