import { connect } from 'react-redux'

import Endorsement from '../components/User/Endorsement'

const mapStateToProps = (state) => ({
  cms: state.cms,
  customer: state.customers.customer,
})

const EndorsementContainer = connect(mapStateToProps)(Endorsement)

export default EndorsementContainer
