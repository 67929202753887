import React, { Component } from 'react'
import Calendar from 'react-calendar'
import Modal from '../../Common/Modal'


export default class CalendarModal extends Component {
  render() {
    const { visible, value, onDateSelect } = this.props

    if (!visible) {
      return null
    }

    return (
      <Modal>
        <Calendar
          onChange={onDateSelect}
          locale='es-ES'
          showNavigation={true}
          minDate={new Date()}
          value={value}
          className='active'
        />
      </Modal>
    )
  }
}
