export const byRef = (arrayOfObjects, id = 'slug') => {
  const all = {}
  arrayOfObjects.forEach((content) => {
    all[content[id]] = content
  })

  return all
}

/**
 * Converts an array of objects into an object containing the keys `ids`
 * and `all`. The `ids` field will be an array containing all the ids, whilst
 * the `all` field will be an object containing all the objects with they id as
 * key of the object.
 *
 * @param {array} arrayOfObjects An array of objects to be mapped.
 * @param {string} id The id fieldname. 'id' by default.
 */
export const ids = (arrayOfObjects, id = 'id') => {
  const all = {}
  const ids = []
  arrayOfObjects.forEach((content) => {
    all[content[id]] = content
    ids.push(content[id])
  })

  return {
    all,
    ids,
  }
}


/**
 * Used by reducers to just set loading to true,
 * loaded to false and also reset errors.
 *
 * @param {state} state The current redux state.
 */
export const loading = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  errors: false,
})
