import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import { setUserPrevision } from "../../actions/userdetails-actions";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./Prevision.css";

class Prevision extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputPrevision: '',
			errorInputPrevision: '',
			currentPrevision: '',
			lastUpdate: ''
		};

		this.initialState = this.state;

		this.onChangePrevision = this.onChangePrevision.bind(this);
		this.changePrevision = this.changePrevision.bind(this);
	}

	componentDidMount() {
		this.setCurrentPrevision();
	}

	componentDidUpdate() {
		this.setCurrentPrevision();
	}

	onChangePrevision(event) {
		const eventValue = event.target.value;
		this.setState(state => ({
			inputPrevision: eventValue
		}));
	}

	setCurrentPrevision() {
		if (this.props.userDetails.previsio_consum && this.state.currentPrevision === '') {
			this.setState(state => ({
				currentPrevision: this.props.userDetails.previsio_consum + 'gr / mes',
				lastUpdate: moment(this.props.userDetails.date_upd).format('DD-MM-YYYY')
			}));
		}
	}

	changePrevision() {
		if (this.state.inputPrevision === '') {
			this.setState(state => ({
				errorInputPrevision: 'La previsión de consumo no puede estar vacía'
			}));
		} else if (Number(this.state.inputPrevision) > 60) {
			this.setState(state => ({
				errorInputPrevision: 'La máxima previsión de consumo es de 60g / mes'
			}));
		} else if (Number(this.state.inputPrevision) <= 0) {
			this.setState(state => ({
				errorInputPrevision: 'La mínima previsión de consumo es de 1g / mes'
			}));
		} else {
			this.setState(state => ({
				errorInputPrevision: ''
			}));

			let userDetails = {...this.props.userDetails};
			delete userDetails.address;

			// parse user groups for XML
			let userGroups = [];
			if (userDetails.associations && userDetails.associations.groups && userDetails.associations.groups.length > 0) {
				userDetails.associations.groups.forEach((group, index) => {
					userGroups.push({
						id: group.id
					});
				});
				userDetails.associations.groups = {
					group: userGroups
				}
			}

			this.props.setUserPrevision(userDetails.id, userDetails, this.state.inputPrevision)
				.then(res => {
					if (res.errors && res.errors.length > 0) {
						toastr.error('No se ha podido actualizar la previsión de consumo');
					} else {
						this.setState(state => ({
							currentPrevision: res.previsio_consum + 'gr / mes'
						}));
						toastr.success('Los datos se han actualizado correctamente');
					}
				});
		}
	}

	render () {
		return (
			<div className="wit-page">
				<Header {...this.props} />

				<div className="page-title">
					<p>PREVISIÓN DE CONSUMO</p>
				</div>

				<div className="prevision-form-wrapper">
					<h4>TU PREVISIÓN DE CONSUMO MENSUAL</h4>
					<p>Última actualización de consumo realizada el {this.state.lastUpdate}</p>
					<p className="quantity">{this.state.currentPrevision}</p>
					<p className="notes">
						Recuerda que la previsión de consumo es muy importante para organizar la carta y disponibilidad de variedades.<br />
						Puedes modificar la previsión de consumo en cualquier momento tanto para aumentarla como para reducirla.<br />
						El máximo consumo permitido mensualmente por cada usuario es de 60g.<br />
						Recuerda también que el límite de reserva diario es de 15g.
					</p>
					<div className="prevision-form-container">
						<input type="text" name="prevision-quantity" placeholder="Modificar previsión de consumo" value={this.state.inputPrevision} onChange={this.onChangePrevision} />
						<span className="error-msg">{this.state.errorInputPrevision}</span>
						<button onClick={this.changePrevision}>MODIFICAR</button>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: state.UserDetailsReducer.userDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUserPrevision: (userId, userDetails, previsionQty) => {
			return setUserPrevision(userId, userDetails, previsionQty, dispatch)(); // 'return' for Promise
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Prevision);