import React, { Component } from "react";

import Footer from "../Footer/Footer";

import "./Footer.css";

class Cookies extends Component {
	render() {
		return (
			<div className="wit-page">
				<div className="page-title standalone">
					<p>POLÍTICA DE COOKIES</p>
				</div>

				<div className="legal-container">
					<p>
						Una cookie es un archivo de información que el servidor de este sitio web envía al dispositivo (ordenador, smartphone, tablet, etc.) de quien accede a la página para almacenar y recuperar información sobre la navegación que se efectúa desde dicho equipo.<br />
						Asociación Cannábica Wit utiliza diversos tipos de cookies (técnicas, analíticas y sociales) únicamente con la finalidad de mejorar la navegación del usuario en el sitio web, sin ningún tipo de objeto publicitario o similar.<br /><br />
						Asociación Cannábica Wit, a través de las cookies no recoge dato personal alguno. Todas las cookies, salvo las utilizadas por el sistema de estadísticas Google Analytics son temporales y desaparecen al concluir la sesión.<br /><br />
						Asociación Cannábica Wit utiliza en este sitio web las cookies que se detallan a continuación:
					</p>

					<table>
						<thead>
							<tr>
								<td>Tipo</td>
								<td>Caducidad</td>
								<td>Finalidad</td>
								<td>Propias/Terceros</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>_ga</td>
								<td>Sesión: 1 día</td>
								<td>Datos para el análisis del comportamiento web de los usuarios con Google Analytics</td>
								<td>Terceros</td>
							</tr>
							<tr>
								<td>_gat</td>
								<td>Sesión: 1 día</td>
								<td>Datos para el análisis del comportamiento web de los usuarios con Google Analytics</td>
								<td>Terceros</td>
							</tr>
							<tr>
								<td>_gid</td>
								<td>Sesión: 1 día</td>
								<td>Datos para el análisis del comportamiento web de los usuarios con Google Analytics</td>
								<td>Terceros</td>
							</tr>
						</tbody>
					</table>

					<p>
						El usuario podrá, en cualquier momento, elegir qué cookies quiere que funcionen en este sitio web mediante:
						<ul>
							<li>La configuración del navegador; (indicar enlace en cada navegador para habilitar o deshabilitar cookies)</li>
							<li>Herramientas de terceros, disponibles on line, que permiten a los usuarios detectar las cookies en cada sitio web que visita y gestionar su desactivación.<br />Ni esta web, ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.</li>
						</ul>
						La aceptación de la presente política implica que el usuario ha sido informado de una forma clara y completa sobre el uso de dispositivos de almacenamiento y recuperación de datos (cookies) así como que Asociación Cannábica Wit dispone del consentimiento del usuario para el uso de las mismas tal y como establece el artículo 22 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSI-CE).
					</p>
				</div>

				<Footer />
			</div>
		);
	}
}

export default Cookies;