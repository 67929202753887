import React, { Component, Fragment } from 'react'
import { FEATURE_TASTE_ID } from '../../constants/constants'
import ReactTooltip from 'react-tooltip'


export default class Tastes extends Component {
  get tastes() {
    const { product, productFeatures } = this.props
    const tastes = []

    const areFeaturesDefined = !product || !product.associations
      || !Object.keys(productFeatures.values.all).length
      || !product.associations.product_features

    if (areFeaturesDefined) {
      return tastes
    }

    product.associations.product_features.forEach((feature) => {
      if (parseInt(feature.id, 10) === FEATURE_TASTE_ID) {
        tastes.push(productFeatures.values.all[feature.id_feature_value])
      }
    })

    return tastes
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  render() {
    const { tastes } = this

    return (
      <div className='variety-fx-icons'>
        {
          tastes.map(({id, value}) => (
            <Fragment key={`taste-${id}`}>
              <img
                alt={value} title={value}
                data-tip={`Sabor ${value}`}
                src={`${process.env.PUBLIC_URL}/images/icons/tastes/${value}.png`}
              />
              <ReactTooltip />
            </Fragment>
          ))
        }
      </div>
    )
  }
}
