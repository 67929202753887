import store from 'store2'

import {
  COMBINATIONS_FETCH,
  COMBINATIONS_FETCH_SUCCESS,
  COMBINATIONS_FETCH_ERROR,
  COMBINATIONS_FETCH_ALL,
  COMBINATIONS_FETCH_ALL_SUCCESS,
  COMBINATIONS_FETCH_ALL_ERROR,
} from '../constants/action-types'
import Api from '../helpers/Api'

export const fetchAllAction = () => ({
  type: COMBINATIONS_FETCH_ALL,
})

export const fetchAllSuccessAction = (combinations) => ({
  type: COMBINATIONS_FETCH_ALL_SUCCESS,
  combinations,
})

export const fetchAllErrorAction = (error) => ({
  type: COMBINATIONS_FETCH_ALL_ERROR,
  error,
})

export const fetchAll = () => (dispatch) => {
  dispatch(fetchAllAction())

  const key = store.get('_key')

  return Api.get('/combinations', {
    params: {
      display: 'full',
      key,
    },
  })
  .then(({data: {combinations}}) => dispatch(fetchAllSuccessAction(combinations)))
  .catch((error) => dispatch(fetchAllErrorAction(error)))
}

export const fetchByProductId = (id) => (dispatch) => {
  dispatch({
    type: COMBINATIONS_FETCH,
  })

  const key = store.get('_key')

  return Api.get('/combinations', {
    params: {
      display: 'full',
      'filter[id_product]' : id,
      key,
    },
  })
    .then(({data: {combinations}}) => dispatch({
      type: COMBINATIONS_FETCH_SUCCESS,
      combinations,
      productId: id,
    }))
    .catch((error) => dispatch({
      type: COMBINATIONS_FETCH_ERROR,
      error,
    }))
}
