import store from 'store2'
import moment from 'moment'

import {
  CANCELED_ORDER_STATE,
  CURRENCY_ID,
  LANG_ID,
  WIT_CARRIER_ID,
} from '../constants/constants'
import {
  ORDERS_CANCEL,
  ORDERS_CANCEL_ERROR,
  ORDERS_CANCEL_SUCCESS,
  ORDERS_CHANGE_DELIVERY,
  ORDERS_CHANGE_DELIVERY_ERROR,
  ORDERS_CHANGE_DELIVERY_SUCCESS,
  ORDERS_CREATE,
  ORDERS_CREATE_ERROR,
  ORDERS_CREATE_SUCCESS,
  ORDERS_FETCH_ALL,
  ORDERS_FETCH_ALL_ERROR,
  ORDERS_FETCH_ALL_SUCCESS,
  ORDERS_FETCH_LATEST,
  ORDERS_FETCH_LATEST_ERROR,
  ORDERS_FETCH_LATEST_SUCCESS,
  ORDERS_FETCH_MONTH,
  ORDERS_FETCH_MONTH_ERROR,
  ORDERS_FETCH_MONTH_SUCCESS,
  ORDERS_FETCH_ORDERED_DATES,
  ORDERS_FETCH_ORDERED_DATES_ERROR,
  ORDERS_FETCH_ORDERED_DATES_SUCCESS,
} from '../constants/action-types'
import Api from '../helpers/Api'
import { json2Xml } from '../constants/globals'

import { reset as resetCart } from './cart'


export const fetchLatestAction = (customer) => ({
  type: ORDERS_FETCH_LATEST,
  customer,
})

export const fetchLatestSuccessAction = (customer, latest) => ({
  type: ORDERS_FETCH_LATEST_SUCCESS,
  customer,
  latest,
})

export const fetchLatestErrorAction = (customer, error) => ({
  type: ORDERS_FETCH_LATEST_ERROR,
  customer,
  error,
})

export const fetchAllAction = () => ({
  type: ORDERS_FETCH_ALL,
})

export const fetchAllSuccessAction = (orders) => ({
  type: ORDERS_FETCH_ALL_SUCCESS,
  orders,
})

export const fetchAllErrorAction = (error) => ({
  type: ORDERS_FETCH_ALL_ERROR,
  error,
})

export const fetchLatest = (customer) => (dispatch) => {
  dispatch(fetchLatestAction())

  const key = store.get('_key')

  return Api.get('/orders', {
    params: {
      'filter[id_customer]' : customer.id,
      display: 'full',
      sort: '[id_DESC]',
      limit: 1,
      key,
    },
  })
  .then(({data}) => {
    if (data.orders && data.orders.length) {
      return dispatch(fetchLatestSuccessAction(customer, data.orders.pop()))
    }

    return dispatch(fetchLatestSuccessAction(customer, {}))
  })
  .catch((error) => dispatch(fetchLatestErrorAction(customer, error)))
}

export const fetchAllMonthForDate = (customer, date) => (dispatch) => {
  dispatch({
    type: ORDERS_FETCH_MONTH,
    date,
  })

  const key = store.get('_key')
  const oDate = typeof date === 'object' ? date : new Date(date)

  const year = moment(oDate).format('YYYY')
  const month = moment(oDate).format('MM')
  const lastDay = new Date(Number(year), Number(month), 0).getDate()

  return Api.get('/orders', {
    params: {
      'filter[id_customer]' : customer.id,
      'filter[date_add]': `[${year}-${month}-01,${year}-${month}-${lastDay}]`,
      date: 1,
      display: 'full',
      sort: '[id_DESC]',
      key,
    },
  })
  .then(({data}) => {
    if (data.orders && data.orders.length) {
      return dispatch({
        type: ORDERS_FETCH_MONTH_SUCCESS,
        orders: data.orders,
        date,
      })
    }

    return dispatch({
      type: ORDERS_FETCH_MONTH_SUCCESS,
      orders: [],
      date,
    })
  })
  .catch((error) => dispatch({
    type: ORDERS_FETCH_MONTH_ERROR,
    error,
  }))
}

export const fetchOrderedMonths = (customer) => (dispatch) => {
  dispatch({
    type: ORDERS_FETCH_ORDERED_DATES,
  })

  const key = store.get('_key')

  return Api.get('/orders', {
    params: {
      'filter[id_customer]' : customer.id,
      display: '[id,date_add]',
      sort: '[id_DESC]',
      key,
    },
  }).then(({data}) => {
    if (data.orders && data.orders.length) {
      return dispatch({
        type: ORDERS_FETCH_ORDERED_DATES_SUCCESS,
        orders: data.orders,
      })
    }

    dispatch({
      type: ORDERS_FETCH_ORDERED_DATES_SUCCESS,
      orders: [],
      loading: false,
      loaded: true,
    })
  }).catch((error) => {
    dispatch({
      type: ORDERS_FETCH_ORDERED_DATES_ERROR,
      error,
      loading: false,
    })
  })
}

export const fetchAll = (customer) => (dispatch) => {
  dispatch(fetchAllAction())

  const key = store.get('_key')

  return Api.get('/orders', {
    params: {
      'filter[id_customer]' : customer.id,
      display: 'full',
      sort: '[id_ASC]',
      key,
    },
  })
  .then(({data}) => {
    if (data.orders && data.orders.length) {
      return dispatch(fetchAllSuccessAction(customer, data.orders))
    }

    return dispatch(fetchAllSuccessAction(customer, []))
  })
  .catch((error) => dispatch(fetchAllErrorAction(customer, error)))
}

export const create = (user, cart) => (dispatch) => {
  dispatch({
    type: ORDERS_CREATE,
  })

  const order = {
    // static fields
    id_currency: CURRENCY_ID,
    id_lang: LANG_ID,
    id_carrier: WIT_CARRIER_ID,
    current_state: 2,
    conversion_rate: 1,
    valid: 1,
    module: 'ps_cashondelivery',
    payment: 'Pagos contra reembolso',
    // dynamic fields
    id_address_delivery: user.addresses.id,
    id_address_invoice: user.addresses.id,
    id_cart: cart.data.id,
    id_customer: user.id,
    total_paid: cart.calculated.total,
    total_paid_real: cart.calculated.total,
    total_products: cart.calculated.total,
    total_products_wt: cart.calculated.total,
  }

  const key = store.get('_key')

  return Api.post('/orders', json2Xml({order}), {params: {key}})
    .then(({data}) => {
      dispatch(resetCart())

      return dispatch({
        type: ORDERS_CREATE_SUCCESS,
        latest: data.order,
      })
    })
    .catch((error) => dispatch({type: ORDERS_CREATE_ERROR, error}))
}

export const changeDeliveryDateTime = (customer, order, date, time) => (dispatch) => {
  dispatch({
    type: ORDERS_CHANGE_DELIVERY,
  })

  const key = store.get('_key')

  order = {
    ...order,
    hora_entrega: time,
    fecha_entrega: date,
  }

  const data = json2Xml({order})

  return Api.put(`/orders/${order.id}`, data, {params: {key}})
    .then(() => dispatch({
      type: ORDERS_CHANGE_DELIVERY_SUCCESS,
      order,
    }))
    .catch((error) => dispatch({
      type: ORDERS_CHANGE_DELIVERY_ERROR,
      error,
    }))
}


export const cancelOrder = (customer, order) => (dispatch) => {
  dispatch({
    type: ORDERS_CANCEL,
  })

  const key = store.get('_key')

  order = {
    ...order,
    current_state: CANCELED_ORDER_STATE,
    valid: 0,
  }

  const data = json2Xml({order})

  return Api.put(`/orders/${order.id}`, data, {params: {key}})
    .then(() => dispatch({
      type: ORDERS_CANCEL_SUCCESS,
      order,
    }))
    .catch((error) => dispatch({
      type: ORDERS_CANCEL_ERROR,
      error,
    }))
}
