import {
  GROUPS_FETCH_ALL, GROUPS_FETCH_ALL_ERROR, GROUPS_FETCH_ALL_SUCCESS,
} from '../constants/action-types'
import Api from '../helpers/Api'
import store from 'store2'

export const fetchAllAction = () => ({
  type: GROUPS_FETCH_ALL,
})

export const fetchAllErrorAction = (error) => ({
  type: GROUPS_FETCH_ALL_ERROR,
  error,
})

export const fetchAllSuccessAction = (groups) => ({
  type: GROUPS_FETCH_ALL_SUCCESS,
  groups,
})

export const fetchAll = () => (dispatch) => {
  dispatch(fetchAllAction())

  const key = store.get('_key')

  return Api.get('/groups', {
    params: {
      display: 'full',
      key,
    },
  })
  .then(({data}) => dispatch(fetchAllSuccessAction(data.groups)))
  .catch((error) => dispatch(fetchAllErrorAction(error)))
}
