import React, { Component } from 'react'

import { toInteger } from '../../../constants/globals'
import { fetchCourseData } from '../../../actions/customers'
import ModalReader from '../../Common/ModalReader'
import EmptyWitPage from '../../Common/EmptyWitPage'

import Context from './Context'
import Links from './Links'
import CourseForm from './Form'

import './Course.css'

export default class Course extends Component {
  state = {
    modalOpen: true,
  }

  handleOnReadClick() {
    this.closeModal()
  }

  closeModal() {
    this.setState({modalOpen: false})
  }

  openModal() {
    this.setState({modalOpen: true})
  }

  get customer () {
    const urlParams = new URLSearchParams(document.location.search)

    return toInteger(urlParams.get('id_socio'))
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchCourseData(this.customer))
  }

  error(text, description = null) {
    return (
      <EmptyWitPage className='course' title='Curso de alta'>
        <div className='styled-content' style={{marginTop: '20px'}}>
          <h4>{text}</h4>
          <p>{ description && description }</p>
        </div>
      </EmptyWitPage>
    )
  }

  render() {
    const { customer, error } = this.props
    if (!this.customer || error) {
      return this.error('Error: No existe tal socio')
    }

    if (customer && customer.course) {
      return this.error('Muchas gracias por completar el curso.', 'Pronto recibirás una llamada para comprobar tus datos y contarte los próximos pasos para hacerte socio. Una vez tu solicitud haya sido aprobada por la Asamblea General recibirás un correo electrónico con los próximos pasos para poder acceder a la web y hacer tu primera retirada.')
    }

    return (
      <EmptyWitPage className='course' title='Curso de alta'>
        <div className='styled-content'>
          <ModalReader onClick={this.handleOnReadClick.bind(this)} open={this.state.modalOpen}>
            <Context />
          </ModalReader>
          <Links modalOpen={this.openModal.bind(this)} />
          <CourseForm {...this.props} />
        </div>
      </EmptyWitPage>
    )
  }
}
