import React, { Component } from 'react'

import {
  categoriesFetch,
  setActive as setActiveCategory,
  setActiveByRef as setActiveCategoryByRef,
} from '../../actions/categories'
import { fetchAll, setActive, setActiveByRef } from '../../actions/products'
import { getNextProduct } from '../../constants/globals'

import WitPage from '../Common/WitPage'
import CategoriesSlider from '../Categories/Slider'
import ProductsMenu from './Menu'
import ProductsSlider from './Slider'

import './index.css'

export default class Product extends Component {
  get productParam() {
    const urlParams = new URLSearchParams(document.location.search)
    let product = parseInt(urlParams.get('variedad'), 10)

    if (isNaN(product)) {
      return this.props.match.params.product
    }

    return product
  }

  get product() {
    const { products } = this.props
    const id = this.productParam

    if (isNaN(id)) {
      return products.byRef[id]
    }

    return products.data.all[id]
  }

  setActiveCategory(category) {
    const { dispatch } = this.props

    if (isNaN(category)) {
      return dispatch(setActiveCategoryByRef(category))
    }
    dispatch(setActiveCategory(category))
  }

  setActive() {
    const {
      dispatch,
      match: { params },
      products,
      categories: { active },
    } = this.props
    let product = this.productParam

    if (isNaN(product) && typeof product === 'string') {
      return dispatch(setActiveByRef(params.product))
    }
    // fallback to first in list in case there's no product specified
    if (!product) {
      product = active.associations.products[0].id

      if (!products.data.all[product]) {
        product = getNextProduct(active.associations.products, products.data.all, 0).id
      }
    }

    return dispatch(setActive(product))
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { params } = match

    if (!this.props.products.loaded) {
      dispatch(fetchAll())
    }

    if (!this.props.categories.loaded) {
      dispatch(categoriesFetch()).then(() => {
        this.setActiveCategory(params.category)
      })
    } else {
      this.setActiveCategory(params.category)
    }
  }

  componentDidUpdate() {
    const { products } = this.props

    if (products.loaded && !products.active.id) {
      this.setActive()
    }
  }

  render() {
    const { categories, products } = this.props

    return (
      <WitPage {...this.props}>
        <div className="main-contents variety-file">
          <CategoriesSlider
            {...this.props}
            category={categories.active}
          />
          <ProductsMenu
            {...this.props}
            category={categories.active}
            active={products.active.id}
          />
          <ProductsSlider
            {...this.props}
            product={products.active}
          />
        </div>
      </WitPage>
    )
  }
}
