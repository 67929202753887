import React, {Component} from 'react'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'

import 'react-datepicker/dist/react-datepicker.css'

export default class DateSelect extends Component {
  state = {
    active: false,
  }

  active() {
    this.setState({active: true})
  }

  inactive() {
    const { name, values } = this.props

    if (!values[name]) {
      this.setState({active: false})
    }
  }

  componentDidMount() {
    const { name, values } = this.props

    if (values[name] && values[name].length) {
      this.active();
    }
  }

  render() {
    const { name, label, values, errors, setFieldValue } = this.props

    const classes = {
      'form-group': true,
      'datepicker-group': true,
      active: this.state.active,
    }

    return (
      <div className={classNames(classes)}>
        <div className='input-group'>
          <label htmlFor={`field-${name}`}>{label}</label>
          <DatePicker
            id={`field-${name}`}
            className='form-control'
            selected={values[name] || ''}
            dateFormat='dd/MM/yyyy'
            onFocus={this.active.bind(this)}
            onBlur={this.inactive.bind(this)}
            onChange={(value) => {
              setFieldValue(name, value)
            }}
          />
        </div>
        <span className='invalid-feedback'>
          {errors && errors[name] && errors[name]}
        </span>
      </div>
    )
  }
}
