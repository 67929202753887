import {
  COMBINATIONS_FETCH_SUCCESS,
  COMBINATIONS_FETCH,
  COMBINATIONS_FETCH_ERROR,
  COMBINATIONS_FETCH_ALL,
  COMBINATIONS_FETCH_ALL_SUCCESS,
} from '../constants/action-types'
import { ids } from '../constants/mappers'
import { toInteger } from '../constants/globals'

const initialState = {
  data: {
    all: {},
    ids: [],
  },
  active: [],
  byProductId: {},
  loading: true,
  loaded: false,
  error: false,
}

const loading = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  error: false,
})

const onFetchSuccess = (state, { combinations, productId }) => {
  const newState = {...state}

  // Sort by price
  combinations.sort((a, b) => a.price > b.price)

  if (newState.data.ids.indexOf(productId) === -1) {
    newState.byProductId[productId] = combinations
    combinations.forEach((combination) => {
      newState.data.ids.push(combination.id)
      newState.data.all[combination.id] = combination
    })
  } else {
    newState.byProductId[productId] = combinations
  }

  return {
    ...newState,
    loaded: true,
    loading: false,
  }
}

const onFetchAllSuccess = (state, { combinations }) => {
  const data = ids(combinations)
  const byProductId = {}
  combinations.forEach((combination) => {
    const id = toInteger(combination.id_product)
    if (typeof byProductId[id] === 'undefined') {
      byProductId[id] = []
    }
    byProductId[id].push(combination)
  })

  return {
    ...state,
    loaded: true,
    loading: false,
    data,
    byProductId,
  }
}

const onFetchError = (state, { error }) => ({
  ...state,
  loading: false,
  loaded: false,
  error,
})

const CombinationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case COMBINATIONS_FETCH_ALL:
    case COMBINATIONS_FETCH:
      return loading(state)
    case COMBINATIONS_FETCH_SUCCESS:
      return onFetchSuccess(state, action)
    case COMBINATIONS_FETCH_ERROR:
      return onFetchError(state, action)

    case COMBINATIONS_FETCH_ALL_SUCCESS:
      return onFetchAllSuccess(state, action)

    default:
      return state
  }
}

export default CombinationsReducer
