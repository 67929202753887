import {
  STOCKS_FETCH_SUCCESS,
  STOCKS_FETCH_ERROR,
  STOCKS_FETCH,
  CART_PRODUCT_ADD_SUCCESS,
  CART_UPDATE_SUCCESS,
} from '../constants/action-types'
import { loading } from '../constants/mappers'

const initialState = {
  data: {
    all: {},
    ids: [],
    byProductAndAttribute: {},
  },
  loading: false,
  loaded: false,
  error: false,
}

const onFetchSuccess = (state, {stocks}) => {
  const data = {...state.data}

  stocks.forEach((stock) => {
    // Only store id if it doesn't exist already
    if (data.ids.indexOf(stock.id) === -1) {
      data.ids.push(stock.id)
    }
    // ... but ensure all the other data is updated

    data.all[stock.id] = stock

    if (typeof data.byProductAndAttribute[stock.id_product] === 'undefined') {
      data.byProductAndAttribute[stock.id_product] = {}
    }

    data.byProductAndAttribute[stock.id_product][stock.id_product_attribute] = stock
  })

  return {
    ...state,
    loaded: true,
    loading: false,
    data,
  }
}

const onError = (state, {error}) => ({
  ...state,
  loading: false,
  error,
})

const decreaseStockAmount = (state, {stock}) => {
  const all = {...state.data.all}
  const byProductAndAttribute = {...state.data.byProductAndAttribute}

  stock.quantity--

  all[stock.id] = {...stock}
  byProductAndAttribute[stock.id_product][stock.id_product_attribute] = {...stock}

  return {
    ...state,
    data: {
      ...state.data,
      all,
      byProductAndAttribute,
    },
  }
}

const setProperStockAfterCartUpdate = (state, {cart}) => {
  const rows = cart.associations.cart_rows.cart_row || cart.associations.cart_rows
  if (!rows || (rows && !rows.length)) {
    return state
  }
  const byProductAndAttribute = {...state.data.byProductAndAttribute}
  rows.forEach((row) => {
    const stock = byProductAndAttribute[row.id_product][row.id_product_attribute]
    stock.quantity = stock.quantity - row.quantity
  })

  return {
    ...state,
    data: {
      ...state.data,
      byProductAndAttribute,
    },
  }
}

const StocksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CART_PRODUCT_ADD_SUCCESS:
      return decreaseStockAmount(state, action)
    case CART_UPDATE_SUCCESS:
      return setProperStockAfterCartUpdate(state, action)
    case STOCKS_FETCH:
      return loading(state)
    case STOCKS_FETCH_SUCCESS:
      return onFetchSuccess(state, action)
    case STOCKS_FETCH_ERROR:
      return onError(state, action)
    default:
      return state
  }
}

export default StocksReducer
