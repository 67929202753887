import React, { Component } from 'react'

import { categoriesFetch } from '../../actions/categories'
import { resetActive } from '../../actions/products'
import WitPage from '../Common/WitPage'
import Category from './Category'

import './index.css'

export default class Categories extends Component {
  componentDidMount() {
    if (!this.props.categories.length) {
      this.props.dispatch(categoriesFetch())
    } else {
      // Ensure we reset any active product or category
      this.props.dispatch(resetActive())
    }
  }

  render() {
    return (
      <WitPage {...this.props}>
        <div className="varieties-list main-contents">
          {
            this.props.categories.map((category, index) =>
              <Category
                key={`category-${index}`}
                category={category}
              />
            )
          }
        </div>
      </WitPage>
    )
  }
}
