import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'


class If extends Component {
  static propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { condition, children } = this.props

    if (!condition) {
      return null
    }

    return (
      <Fragment>
        { children }
      </Fragment>
    )
  }
}

export default If
