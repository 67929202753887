import { connect } from 'react-redux'

import Cart from '../components/Cart'

const mapStateToProps = (state) => ({
  cms: state.cms,
  combinations: state.combinations,
  cart: state.cart,
  customer: state.customers.customer,
  errors: state.products.error || state.combinations.error || state.groups.error,
  groups: state.groups,
  loading: state.groups.loading
    || state.combinations.loading
    || state.products.loading
    || state.orders.loading
    || state.cart.loading,
  products: state.products,
  productFeatures: state.productFeatures,
  user: state.UserDetailsReducer.userDetails || {},
})

const CartContainer = connect(mapStateToProps)(Cart)


export default CartContainer
