import React, { Component } from 'react'
import { RadioGroup, Radio } from 'react-radio-group'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'

import {
  isInactiveAccount,
  isDateBeforeRightNow,
  convertToValidDateTime,
  getFirstTimeInSchedule,
} from '../../constants/globals'
import { MINIMUM_FEE, MINIMUM_FEE_COLLAB } from '../../constants/constants'
import { update as updateCart, resetDeliveryDate } from '../../actions/cart'
import { update as updateCustomer } from '../../actions/customers'
import { create as createOrder } from '../../actions/orders'
import { API_URL_PAYMENT } from '../../helpers/api-config'
import Change from './Change'

export default class Payment extends Component {
  state = {
    type: '',
  }

  componentDidMount() {
    // Ensure payment type is reset on mount
    this.setState({type: ''})
  }

  onSelectType(type) {
    this.setState({type})
  }

  submit() {
    const { dispatch, cart, user } = this.props

    // Double check schedule time, to ensure client wasn't asleep
    if (isDateBeforeRightNow(convertToValidDateTime(cart.data.fecha_entrega, getFirstTimeInSchedule(cart.data.hora_entrega)))) {
      toastr.error('La fecha de entrega que habías elegido ha pasado, elige otra por favor.')

      return dispatch(resetDeliveryDate())
    }

    dispatch(updateCart(cart.data))
      .then(() => {
        if (isInactiveAccount(user)) {
          user.soci_data = moment(new Date()).format('YYYY-MM-DD')

          return dispatch(updateCustomer(user))
        }

        return Promise.resolve()
      })
      .then(() => {
        if (this.state.type === 'online') {
          window.location.href = `${API_URL_PAYMENT}?id_cart=${cart.data.id}`

          return Promise.resolve()
        }

        return dispatch(createOrder(user, cart)).then(() => {
          toastr.success('Pedido completado correctamente')
          this.props.history.push('/historial')
        })
      })
      .catch((error) => {
        console.error(error)
        toastr.error('Error al procesar el pedido. Por favor, inténtalo de nuevo más tarde')
      })
  }

  render() {
    const { calculated, data } = this.props.cart

    const rows = data.associations.cart_rows.cart_row || data.associations.cart_rows
    if (!rows.length) {
      return null
    }

    let disabledMessage = ''
    let meetsMinimum =  false

    if ((calculated.total >= MINIMUM_FEE) || (calculated.discount.percent > 0 && calculated.total >= MINIMUM_FEE_COLLAB)) {
      meetsMinimum = true
    } else {
      disabledMessage = 'La reserva mínima es de {euros}€. Prueba a añadir otro producto de la carta.'
      const euros = calculated.discount.percent > 0 ? MINIMUM_FEE_COLLAB : MINIMUM_FEE
      disabledMessage = disabledMessage.replace('{euros}', euros)
    }

    const submitDisabled = !calculated.total
      || !this.state.type.length
      || (!data.fecha_entrega || (data.fecha_entrega && !data.fecha_entrega.length))
      || (!data.hora_entrega || (data.hora_entrega && !data.hora_entrega.length))
      || !meetsMinimum
      || this.props.loading

    return (
      <div className='cart cart-payment'>
        <h4>¿CÓMO REALIZARÁS LA APORTACIÓN?</h4>
        <RadioGroup
          name='payment-method'
          selectedValue={this.state.type}
          onChange={this.onSelectType.bind(this)}
        >
          <label>
            <Radio value='online' />Online
          </label>
          <label>
            <Radio value='cash' />En el domicilio
            <span className='note'>Pre-rolled de regalo</span>
          </label>
        </RadioGroup>
        <Change
          {...this.props}
          visible={this.state.type === 'cash'}
        />
        <button disabled={submitDisabled} onClick={this.submit.bind(this)}>
          APORTAR
        </button>
        <p className='submit-disabled-message'>
          {disabledMessage}
        </p>
      </div>
    )
  }
}
