import React, { Component } from 'react'
import Hammer from 'react-hammerjs'
import { Link } from 'react-router-dom'

import arrowLeftVarietyNav from '../../images/icon-arrow_left-s1.png'
import arrowRightVarietyNav from '../../images/icon-arrow_right-s1.png'

import { toInteger, getNextAndPrevProducts } from '../../constants/globals'
import { setActive } from '../../actions/products'
import Contribute from '../Cart/Contribute'
import ProductImage from './Image'
import Effects from './Effects'

export default class ProductsSlider extends Component {
  handleChange(id) {
    const { dispatch } = this.props

    dispatch(setActive(id))
  }

  render() {
    const {
      product,
      products,
      categories,
    } = this.props
    const category = categories.active

    let nav = {prev: product, next: product}
    if (product.id && category.associations.products.length) {
      const current = category.associations.products.findIndex(({ id }) => (
        toInteger(id) === product.id
      ))

      nav = getNextAndPrevProducts(category.associations.products, products.data.all, current)
    }

    return (
      <div className='variety-details'>
        <div className='variety-info'>
          <Contribute
            {...this.props}
            combinations={this.props.combinations}
            stocks={this.props.stocks.data.byProductAndAttribute[product.id] || {}}
          />

          <div className='variety-nav-infinite'>
            <div className='infinite-left-nav'>
              <Link
                onClick={this.handleChange.bind(this, nav.prev.id)}
                to={`/carta/${category.link_rewrite}/${nav.prev.reference}`}
              >
                <img alt='button swipe right' src={arrowLeftVarietyNav} />
              </Link>
            </div>

            <Hammer
              onSwipeLeft={this.handleChange.bind(this, nav.next.id)}
              onSwipeRight={this.handleChange.bind(this, nav.prev.id)}
            >
              <div className='lazy-container'>
                <ProductImage {...this.props} />
              </div>
            </Hammer>

            <div className='infinite-right-nav'>
              <Link
                onClick={this.handleChange.bind(this, nav.next.id)}
                to={`/carta/${category.link_rewrite}/${nav.next.reference}`}
              >
                <img alt='button swipe left' src={arrowRightVarietyNav} />
              </Link>
            </div>
          </div>

          <div className='variety-attributes'>
            <div className='variety-name'>
              <p className='variety-name-title'>
                {product.name}
              </p>
              <div
                className='variety-name-family'
                dangerouslySetInnerHTML={{__html: product.description_short}}
              />
            </div>

            <Effects {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}
