import { QuestionIcon } from '@chakra-ui/icons'
import { Button, ChakraProvider, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react'
import React, { useRef } from 'react'
import theme from '../../constants/theme'

const Whatsapp = () => {
  const initialFocusRef = useRef()
  return (
    <div style={{position: 'fixed', right: '10px', bottom: '10px'}}>
      <ChakraProvider theme={theme}>
        <Popover initialFocusRef={initialFocusRef}>
          <PopoverTrigger>
            <Button colorScheme='cyan'>
              <QuestionIcon h='20px' alignSelf='center' mr='1' /> Ayuda
            </Button>
          </PopoverTrigger>
          <PopoverContent border='0px solid transparent' zIndex={1}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader borderTopRadius={5}>¿Necesitas ayuda?</PopoverHeader>
            <PopoverBody pt='10' borderTopRadius={5}>
              Escríbenos <a href='whatsapp://send/?phone=34693057904' ref={initialFocusRef}>por whatsapp</a> al <a href='tel://+34693057904'>693 05 79 04</a>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ChakraProvider>
    </div>
  )
}

export default Whatsapp
