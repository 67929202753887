import {
  CART_PRODUCT_ADD,
  CART_FETCH_SUCCESS,
  CART_FETCH,
  CART_FETCH_ERROR,
  CART_PRODUCT_ADD_ERROR,
  CART_PRODUCT_ADD_SUCCESS,
  CART_PRODUCT_REMOVE,
  CART_PRODUCT_REMOVE_SUCCESS,
  CART_CALCULATE_SUCCESS,
  CART_CALCULATE,
  CART_CREATE_SUCCESS,
  CART_CREATE_ERROR,
  SCHEDULE_FETCH_TODAY_SUCCESS,
  SCHEDULE_FETCH_TODAY,
  SCHEDULE_FETCH_TOMORROW,
  SCHEDULE_FETCH_TOMORROW_SUCCESS,
  CART_DELIVERY_DATE_SET,
  CART_DELIVERY_SCHEDULE_SET,
  CART_DELIVERY_DATE_RESET,
  CART_FIELD_SET,
  CART_DELIVERY_SCHEDULE_RESET,
  CART_RESET,
  CART_UPDATE,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_ERROR,
} from '../constants/action-types'

const initialState = {
	data: {
    fecha_entrega: '',
    hora_entrega: '',
    associations: {
      cart_rows: [],
    },
    cambio: '',
  },
  calculated: {
    subtotal: 0,
    total: 0,
    discount: {
      percent: 0,
      amount: 0,
    },
  },
  schedules: {
    today: [],
    tomorrow: [],
  },
  loading: false,
  loaded: false,
  error: false,
}

const onCartAddProduct = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  error: false,
})

const loading = (state) => ({
  ...state,
  loading: true,
  loaded: false,
  error: false,
})

const onSuccess = (state, { cart }) => {
  // Due to the XML conversion, we need to ensure no `cart_row` object is in our state
  if (cart.associations &&
    cart.associations.cart_rows &&
    cart.associations.cart_rows.cart_row
  ) {
    cart.associations.cart_rows = cart.associations.cart_rows.cart_row
  }

  if (!cart.associations || (cart.associations && !cart.associations.cart_rows)) {
    cart.associations = {
      cart_rows: [],
    }
  }

  return {
    ...state,
    loading: false,
    loaded: true,
    data: cart,
  }
}

const onError = (state, { error }) => ({
  ...state,
  loading: false,
  error,
})

const onCalculateSuccess = (state, { calculated }) => ({
  ...state,
  loading: false,
  loaded: true,
  calculated,
})

const onScheduleFetchTodaySuccess = (state, { schedules }) => ({
  ...state,
  schedules: {
    ...state.schedules,
    today: schedules,
  },
})

const onScheduleFetchTomorrowSuccess = (state, { schedules }) => ({
  ...state,
  schedules: {
    ...state.schedules,
    tomorrow: schedules,
  },
})

const onFetchTodaySchedule = (state) => ({
  ...state,
  schedules: {
    ...state.schedules,
    today: [],
  },
})

const onFetchTomorrowSchedule = (state) => ({
  ...state,
  schedules: {
    ...state.schedules,
    tomorrow: [],
  },
})

const onDeliveryDateSet = (state, { date }) => ({
  ...state,
  data: {
    ...state.data,
    fecha_entrega: date,
  },
})

const onDeliveryScheduleSet = (state, { schedule }) => ({
  ...state,
  data: {
    ...state.data,
    hora_entrega: schedule,
  },
})

const onSetField = (state, {field, value}) => ({
  ...state,
  data: {
    ...state.data,
    [field]: value,
  },
})

const CartReducer = (state = initialState, action = {}) => {
	switch (action.type) {
    case CART_PRODUCT_ADD:
      return onCartAddProduct(state)

    case CART_UPDATE:
    case CART_FETCH:
      return loading(state)
    case CART_FETCH_SUCCESS:
    case CART_PRODUCT_ADD_SUCCESS:
    case CART_CREATE_SUCCESS:
    case CART_UPDATE_SUCCESS:
      return onSuccess(state, action)
    case CART_CREATE_ERROR:
    case CART_FETCH_ERROR:
    case CART_PRODUCT_ADD_ERROR:
    case CART_UPDATE_ERROR:
      return onError(state, action)

    case SCHEDULE_FETCH_TOMORROW:
      return onFetchTomorrowSchedule(state)
    case SCHEDULE_FETCH_TOMORROW_SUCCESS:
        return onScheduleFetchTomorrowSuccess(state, action)

    case SCHEDULE_FETCH_TODAY:
      return onFetchTodaySchedule(state)
    case SCHEDULE_FETCH_TODAY_SUCCESS:
      return onScheduleFetchTodaySuccess(state, action)

    case CART_FIELD_SET:
      return onSetField(state, action)

    case CART_PRODUCT_REMOVE:
      return loading(state)
    case CART_PRODUCT_REMOVE_SUCCESS:
      return onSuccess(state, action)

    case CART_RESET:
      return {
        ...initialState,
      }

    case CART_CALCULATE:
      return {
        ...state,
        loaded: false,
        loading: true,
        calculated: {
          ...initialState.calculated
        },
      }
    case CART_CALCULATE_SUCCESS:
      return onCalculateSuccess(state, action)

    case CART_DELIVERY_DATE_RESET:
      return onDeliveryDateSet(state, {date: ''})
    case CART_DELIVERY_DATE_SET:
      return onDeliveryDateSet(state, action)

    case CART_DELIVERY_SCHEDULE_SET:
      return onDeliveryScheduleSet(state, action)
    case CART_DELIVERY_SCHEDULE_RESET:
      return onDeliveryScheduleSet(state, {schedule: ''})

		default:
			return state
	}
}

export default CartReducer
